import { jsPDF } from "jspdf";
import "jspdf-autotable"; 

export const projectPDF = ({ data }) => {
    console.log("data",data)
    const doc = new jsPDF();

    console.log("Data passed to PDF:", data);

    doc.setFontSize(16);
    doc.text("Project Scan File Report", 10, 10);

    if (Array.isArray(data) && data.length > 0) {
        const columns = ["Severity", "Message", "Author", "Effort"];
        
        const rows = data
            .filter(item => item.severity)  // Ensure that we only include items with severity
            .map(item => [
                item.severity || "N/A",
                item.message || "No message",
                item.author || "No author",
                item.effort || "No effort"
            ]);

        // Log the rows to ensure they are prepared correctly
        console.log("Rows for table:", rows);

        // Set margins for the table and adjust the starting position
        const margin = { top: 20, left: 10, right: 10, bottom: 20 };

        // Generate the table
        doc.autoTable({
            head: [columns], // Define the header row
            body: rows, // Data for the table
            startY: 20, // Y position where the table starts
            margin: margin, // Add margin on all sides
            theme: 'grid', // Apply grid style to the table
            didDrawPage: function (data) {
                // Optional: if you need to handle page breaks or custom footer logic
                // doc.text("Page " + doc.internal.getNumberOfPages(), doc.internal.pageSize.width - 10, doc.internal.pageSize.height - 10);
            }
        });
    } else {
        doc.text("No data available to display", 10, 20);
    }

    // Save the PDF
    doc.save("Project_Scan_Report.pdf");
};
