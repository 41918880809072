import React from 'react';
import PropTypes from 'prop-types'; // Make sure to add relevant CSS for tooltip styles

const ShowTooltip = ({epssClassName,  value, placement="top" }) => {
    
  const placementClass = `tooltip-placement-${placement}`;
    return (
      <span placement={placement} className={`Custom_tooltipText tooltip-placement-${placement} ${ epssClassName? epssClassName: null}`}>
        {value}
      </span>
    );
  };

ShowTooltip.propTypes = {
  epss: PropTypes.number.isRequired,
  children: PropTypes.node,
  value: PropTypes.string.isRequired,
  placement: PropTypes.oneOf(["top", "bottom", "left", "right"])
};

export default ShowTooltip;
