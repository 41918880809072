import { configureStore } from "@reduxjs/toolkit";
import { api } from "./services/baseApiSetup";
import observabilitySlice from "../DevopsInsights/Observability/observabilitySlice";
import commonSlice from "./commonSlice";
import efficiencySlice from "../configuration/cmdb/efficienySlice.js";
import configurationSlice from "../configuration/configurationSlice.js";
// import adminActivityslice from "../gnc/adminActivity/adminActivityslice";
import piiUserBarSlice from "../gnc/GNCSlice.js";
import PeopleDataSlice from "../DevopsInsights/Observability/peopleMetrics/PeopleDataSlice";
import HomeSlice from "../Home/HomeSlice";
import AssetSlice from "../configuration/cmdb/AssetConfiguration/AssetSlice.js";
import CMDBSlice from "../configuration/cmdb/CMDBSlice.js";
import CostSlice from "../gnc/CloudCostInsights/CostSlice";
import CBudgetSlice from "../configuration/cmdb/CloudBudget/CBudgetSlice.js";
import VulnerabilitiesSlice from "../gnc/Compliance/Vulnerabilites/VulnerabilitiesSlice";
import SecurityHubSlice from "../gnc/Compliance/securityHub/SecurityHubSlice";
 import UserAccessReviewSlice from "../gnc/Compliance/UserAccessReview/UserAccessReviewSlice.js";
 import PIIConfigurationSlice from "../configuration/admin/PIIConfiguration/PIIConfigurationSlice";
 import appConfigSlice from "../configuration/cmdb/appConfigSlice.js";
 import EmailScheduleConfigSlice from "../configuration/admin/Schedulers/EmailScheduleConfiguration/EmailScheduleSlice";
 import CISBenchmarkSlice from "../gnc/Compliance/CISBenchmarkCompliance/CISBenchmarkSlice";
import HomeApiSlice from "../Home/HomeApiSlice";
import LicenseSlice from "../gnc/LicenseManagement/LicenseSlice";
import reviewslice from "../CloudAccessGovernance/AccessReview/reviewslice.js";
// import iCMSlice from "../gnc/Compliance/ICM/complianceManagement/iCMSlice";
// import iCMCloudSlice from "../gnc/Compliance/ICM/ComplianceManagementCloud/iCMCloudSlice";
import Circular9Slice from "../gnc/RegulatoryCompliance/circular9/Circular9Slice.js";
import AuditLogSlice from "../configuration/auditLog/AuditLogSlice.js";
import ToolsSlice from "../configuration/admin/tools/ToolsSlice.js";
import GroupSlice from "../configuration/admin/groups/GroupSlice.js";
import OrgInfoSlice from "../configuration/cmdb/cmdbMaster/RoleAllocation/OrgInfoSlice.js";
import FlowMetricsSlice from "../DevopsInsights/Observability/flowMetrics/FlowMetricsSlice";
import ProductivityMetricsSlice from "../DevopsInsights/Observability/ProductivityMetricsObs/ProductivityMetricsSlice";
import DataImportConfigSlice from "../configuration/admin/Schedulers/DataImportScheduler/DataImportSlice.js";
import filterSlice from "./common-components/CommonFilter/complainceFilterSlice.js";
import PeopleMetricsSlice from "../DevopsInsights/Observability/PeopleMetricsObs/PeopleMetricsSlice";
import SBOMSlice from "../DevopsInsights/Observability/SBOM/SBOMSlice";
import CISOSBOMSlice from "../DevopsInsights/Observability/CISOSBOM/CISOSBOMSlice";
import CISOSlice from "../gnc/cisoDashboard/CisoSlice";
import RiskassessmentSlice from "../configuration/admin/riskAssessement/RiskassessmentSlice.js";
import ComplianceAssessmentSlice from "../gnc/Compliance/CreateRiskAssessment/ComplianceAssessmentSlice.js";
import PolicyPriviledgeSlice from "../configuration/admin/Policy Categorization/PolicyPriviledgeSlice.js";
import userReviewSlice from "../CloudAccessGovernance/AccessReview/userReviewSlice.js";
import RMiTSlice from "../gnc/RegulatoryCompliance/RMiT/RMiTSlice.js";
import TRMGSlice from "../gnc/RegulatoryCompliance/TRMG/TRMGSlice.js";
import UARGraphSlice from "../CloudAccessGovernance/UserGraphs/UserGraphSlice.js";
import architecureComplianceSlice from "../ArchitectureCompliance/Compliance/ArchitectureComplianceSlice.js";
import InventorySlice from "../Inventory/Server/InventorySlice.js";

export const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    observability: observabilitySlice,
    common: commonSlice,
    efficiency: efficiencySlice,
    configuration: configurationSlice,
    // adminActivity: adminActivityslice,
    Name: piiUserBarSlice,
    Peopledata: PeopleDataSlice,
    Homedata: HomeSlice,
    assetApi: AssetSlice,
    cmdbId: CMDBSlice,
    cloudCostApi: CostSlice,
    userAccessReviweApi: UserAccessReviewSlice,
    CBudgetApi: CBudgetSlice,
    SecurityHubApi: SecurityHubSlice,
    vulnerabilityApi: VulnerabilitiesSlice,
    PIIConfigurationSlice: PIIConfigurationSlice,
    appConfig: appConfigSlice,
    CisBenchmark: CISBenchmarkSlice,
    // ICMApi: iCMSlice,
    TRMGSliceApi: TRMGSlice,
    RMiTSliceApi: RMiTSlice,
    Circular9Api: Circular9Slice,
    // ICMCloudApi: iCMCloudSlice,
    EmailSchduleConfiguration: EmailScheduleConfigSlice,
    DataImportConfig: DataImportConfigSlice,
    HomeApiCall: HomeApiSlice,
    LicenseApiCall: LicenseSlice,
    reviewApi: reviewslice,
    AuditLogApi: AuditLogSlice,
    toolsApi: ToolsSlice,
    GroupApi: GroupSlice,
    OrganizationInformation: OrgInfoSlice,
    FlowMetricsApi: FlowMetricsSlice,
    filterApi: filterSlice,
    ProductivityApi: ProductivityMetricsSlice,
    PeopleMetricsSlice: PeopleMetricsSlice,
    SBOMSliceAPI: SBOMSlice,
    CISOSBOMSliceAPI:CISOSBOMSlice,
    EmailSchduleConfiguration:EmailScheduleConfigSlice,
    CISOSBOMSliceAPI:CISOSBOMSlice,
    CISOSliceAPI:CISOSlice,
    RiskassessmentSliceAPI:RiskassessmentSlice,
    PolicyPriviledgeSliceAPI:PolicyPriviledgeSlice,
    ComplianceAssessmentSliceAPI:ComplianceAssessmentSlice,
    userReviewData:userReviewSlice,
    PolicyPriviledgeSliceAPI:PolicyPriviledgeSlice,
    ComplianceAssessmentSliceAPI:ComplianceAssessmentSlice,
    UARGraphData: UARGraphSlice,
    AwsArchitectureData:architecureComplianceSlice,
    InventorySliceAPI:InventorySlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(api.middleware),
});
