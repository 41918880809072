import React, { useState, useRef, useEffect } from 'react';
import { DropdownButton, OverlayTrigger, Tooltip } from 'react-bootstrap';
import ShowTooltip from '../Tooltip/showToolTip';
import './style.scss';
import _ from 'lodash';

const MoreFilter = ({ name, optionList, handleOptionClick, selectedOptionlist, disabled, tooltip, tooltipText }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedOption, setSelectedOption] = useState(selectedOptionlist);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isHover, setIsHover] = useState(false);
  const dropdownRef = useRef(null);
  const rawOption =  _.sortBy(optionList, 'text');

  useEffect(() =>{
    setSelectedOption(selectedOptionlist)
  },[selectedOptionlist])

  const filteredOptions = rawOption?.filter(item => item.text.toLowerCase().includes(searchTerm.toLowerCase()));
  const isAllSelected = filteredOptions?.length > 0 && selectedOption?.length === filteredOptions?.length;

  const optionClick = (optionValue) => {
    const updatedOptions = selectedOption.includes(optionValue)
      ? selectedOption.filter(value => value !== optionValue)
      : [...selectedOption, optionValue];

    setSelectedOption(updatedOptions);
    handleOptionClick(updatedOptions);
  };

  const handleDropdownToggle = (isOpen) => {
    setIsDropdownOpen(isOpen);
    if (!isOpen) {
      setSearchTerm('');
    }
  };

  const tooltipHoverTrack = (ishover) => {
    setIsHover(ishover);
  } 

  const handleSelectAll = () => {
    const visibleValues = filteredOptions.map(item => item.value);
    const updatedOptions = selectedOption.length === visibleValues.length && visibleValues.every(value => selectedOption.includes(value)) ? [] : visibleValues;

    setSelectedOption(updatedOptions);
    handleOptionClick(updatedOptions);
  };

  const renderOverlay = () => {
    return (
      <span className="d-inline-block more-field" tabIndex="0">
        <DropdownButton
          title={name}
          show={isDropdownOpen}
          onToggle={handleDropdownToggle}
          ref={dropdownRef}
          disabled={disabled}
          className='selectbutton'
          align={'end'}
          flip={true}
          onMouseEnter={() => tooltipHoverTrack(true)}
          onMouseLeave={() => tooltipHoverTrack(false)}
        >
          <div className="checkboxpad">
            <div className="maincontainer">
              <div className="form-group has-search">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
            </div>
            {optionList ? (
              filteredOptions.length === 0 ? (
                <div className="no-results mx-1 my-2">No Result</div>
              ) : (
                <>
                  <div className="selectAllMore mx-1 mt-2"><span className= 'selectA' onClick={handleSelectAll}>{isAllSelected ? "Unselect all" : "Select all"}</span></div>
                  <div className="maxscroll">
                    {filteredOptions.map((item, index) => (
                      <div key={index} className="my-2">
                        <label className="checkbox-label">
                          <input
                            className="checkbox-input mx-1"
                            type="checkbox"
                            checked={selectedOption?.includes(item.value)}
                            onChange={(e) => {
                              optionClick(item.value)
                              e.stopPropagation();
                            }}
                          /> {item.text}
                        </label>
                      </div>
                    ))}
                  </div>
                </>
              )
            ) : (
              <div className="no-results mx-1 my-2">No Options</div>
            )}
          </div>
        </DropdownButton>
      </span>
    );
  };

  return (
    <div className="checkbox-group Custom_tooltip">
      {tooltip ? (
        <span
          className="d-inline-block"
          tabIndex="0"
          onMouseEnter={() => setIsHover(true)}  // Set hover state when mouse enters
          onMouseLeave={() => setIsHover(false)} // Reset hover state when mouse leaves
          ref={dropdownRef}  // Attach the ref to this element
        >
          {/* Use ShowTooltip with custom class and dynamic visibility */}
          <ShowTooltip
            epssClassName="Custom_tooltip" 
            value={tooltipText || ''}  // Display the tooltip text
            placement="topRed"  // Tooltip placement (adjust as needed)
            show={isHover && !isDropdownOpen}  // Only show the tooltip when hovered and dropdown is not open
          />
          {/* Render the overlay or content as usual */}
          {renderOverlay()}
        </span>
      ) : (
        renderOverlay() 
      )}
    </div>
  );
};

export default MoreFilter;
