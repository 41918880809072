import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Cookies from 'universal-cookie';
import { handleResponseCode } from '../../app/utilities/helpers';
import GetProxy from '../../app/GetProxy';

const proxy = GetProxy()
const cookies = new Cookies();

export const   awsLiveDataAPI= createAsyncThunk(
  "post/awsLiveDataAPI",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { Payload } = props;
      const response = await fetch(
        `${proxy}/api/v1/architectureCompliance/getArchitectureComplianceTreeMap`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(Payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
export const   awsDataLastScannedAPI= createAsyncThunk(
  "post/awsDataLastScannedAPI",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { Payload } = props;
      const response = await fetch(
        `${proxy}/api/v1/architectureCompliance/getLastScanDate`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(Payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
export const   Timelinechart = createAsyncThunk(
  "post/Timelinechart",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { Payload } = props;
      const response = await fetch(
        `${proxy}/api/v1/architectureCompliance/getRecentActivities`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(Payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
export const   Metricschart = createAsyncThunk(
  "post/Metricschart",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { Payload } = props;
      const response = await fetch(
        `${proxy}/api/v1/architectureCompliance/getMetricsData`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(Payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

const initialState = {
    awsLiveData:[],
    awsLiveDataLoading:false,
    awsDataLastScannedDetails:[],
    awsDataLastScannedLoading:false,
    TimelinechartDetails:[],
    TimelinechartLoading:false,
    MetricschartDetails:[],
    MetricschartLoading:false,
}

export const architectureSlice = createSlice({
    name: 'architectureSlice',
    initialState,
    reducers: {
            resetATCData: () => {
                return initialState;
            }
        },
    extraReducers: (builder) => {
      builder

        .addCase(awsLiveDataAPI.pending, (state) => {
          state.awsLiveDataLoading = true;
        })
        .addCase(awsLiveDataAPI.fulfilled, (state, action) => {
          state.awsLiveData = action.payload;
          state.awsLiveDataLoading = false;
        })
        .addCase(awsLiveDataAPI.rejected, (state) => {
          state.awsLiveData = [];
          state.awsLiveDataLoading = false;
        })

        .addCase(awsDataLastScannedAPI.pending, (state) => {
          state.awsDataLastScannedLoading = true;
        })
        .addCase(awsDataLastScannedAPI.fulfilled, (state, action) => {
          state.awsDataLastScannedDetails = action.payload;
          state.awsDataLastScannedLoading = false;
        })
        .addCase(awsDataLastScannedAPI.rejected, (state) => {
          state.awsDataLastScannedDetails = [];
          state.awsDataLastScannedLoading = false;
        })
        .addCase(Timelinechart.pending, (state) => {
          state.TimelinechartLoading = true;
        })
        .addCase(Timelinechart.fulfilled, (state, action) => {
          state.TimelinechartDetails = action.payload;
          state.TimelinechartLoading = false;
        })
        .addCase(Timelinechart.rejected, (state) => {
          state.TimelinechartDetails = [];
          state.TimelinechartLoading = false;
        })
        .addCase(Metricschart.pending, (state) => {
          state.MetricschartLoading = true;
        })
        .addCase(Metricschart.fulfilled, (state, action) => {
          state.MetricschartDetails = action.payload;
          state.MetricschartLoading = false;
        })
        .addCase(Metricschart.rejected, (state) => {
          state.MetricschartDetails = [];
          state.MetricschartLoading = false;
        })

    }
});  
export const { resetATCData } = architectureSlice.actions;

export default architectureSlice.reducer;