import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "universal-cookie";
import { handleResponseCode } from "../../../app/utilities/helpers";
import GetProxy from "../../../app/GetProxy";

const cookies = new Cookies();
const proxy = GetProxy()

export const saveAsset = createAsyncThunk(
  "post/save",
  async (props, { dispatch }) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { userName, payload, listPayload } = props;

      const response = await fetch(
        `${proxy}/api/v1/assetConfiguration/saveOrUpdateAssetConfiguration/${userName}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.text();
      dispatch(
        getCountAsset({ payload: listPayload.payload })
      );
      dispatch(getList(listPayload));

      return data;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
export const getCountAsset = createAsyncThunk("post/getCountAsset", async (props) => {
  const apiToken = cookies.get("apitoken");
  const username = cookies.get("username");
  const { payload } = props;
  const res = await fetch(
    `${proxy}/api/v1/assetConfiguration/getAssetConfigurationTotalCount`,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "DTOP-API-TOKEN": apiToken,
        USERNAME: username,
      },
      body: JSON.stringify(payload),
    }
  ).then((resp) => resp.json());
  handleResponseCode(res);
  return res;
});
export const getList = createAsyncThunk("post/getList", async (props) => {
  const { pageNumber, itemsPerPage, payload } = props;
  const apiToken = cookies.get("apitoken");
  const username = cookies.get("username");
  const res = await fetch(
    `${proxy}/api/v1/assetConfiguration/getAssetConfigurationListBySearchSortAndPagination/${pageNumber}/${itemsPerPage}
    `,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "DTOP-API-TOKEN": apiToken,
        USERNAME: username,
      },
      body: JSON.stringify(payload),
    }
  ).then((resp) => resp.json());
  handleResponseCode(res);
  return res;
});
export const getServerList = createAsyncThunk(
  "get/serverList",
  async (props) => {
    const cmdbId = props;
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    const res = await fetch(
      `${proxy}/api/v1/assetConfiguration/getServerNameList/${cmdbId}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "DTOP-API-TOKEN": apiToken,
          USERNAME: username,
        },
      }
    ).then((resp) => resp.json());
    handleResponseCode(res);
    return res;
  }
);
export const getById = createAsyncThunk("get/getById", async (props) => {
  const id = props;
  const apiToken = cookies.get("apitoken");
  const username = cookies.get("username");
  const res = await fetch(`${proxy}/api/v1/assetConfiguration/getById/${id}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "DTOP-API-TOKEN": apiToken,
      USERNAME: username,
    },
  }).then((resp) => resp.json());
  handleResponseCode(res);
  return res;
});
export const deleteAsset = createAsyncThunk(
  "post/delete",
  async (props, { dispatch }) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { id, cmdbId, listPayload } = props;

      const response = await fetch(
        `${proxy}/api/v1/assetConfiguration/deleteAssetConfiguration/${id}`,
        {
          method: "Delete",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": apiToken,
            USERNAME: username,
          },
          // body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.text();

      dispatch(
        getCountAsset({ payload: listPayload.payload })
      );
      dispatch(getList(listPayload));

      return data;
    } catch (error) {
      console.error("Error:", error);
      throw error; // Rethrow the error so the calling code can handle it
    }
  }
);


export const getTypeList = createAsyncThunk(
  "post/getTypeList",
  async (props, { dispatch }) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const response = await fetch(
        `${proxy}/api/v1/assetConfiguration/getTypeList`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(props),
        }
      );

      handleResponseCode(response);

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.text();


      return data;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
const initialState = {
  dataAsset: "",
  serverList: [],
  tableList: [],
  getData: [],
  saveLoading: false,
  tableLoading: false,
  getLoading: false,
  deleteData: "",
  deleteLoading: false,
  countAssect: '',
  CALoading: false,
  typeList: '',
  typeLoading: false,
};
export const AssetSlice = createSlice({
  name: "ApiCallAsset",
  initialState,
  reducers: {
    resetData: (state) => {
      const resetDatas = {
        dataAsset: "",
        serverList: [],
        tableList: [],
        getData: [],
        saveLoading: false,
        tableLoading: false,
        getLoading: false,
        deleteData: "",
        deleteLoading: false,
        countAssect: '',
        CALoading: false,
        typeList: '',
        typeLoading: false,
      };
      return resetDatas;
      // state.dataCB = ''; // Reset dataCB to its initial state
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(saveAsset.pending, (state, action) => {
        state.saveLoading = true;
        state.dataAsset = "";
      })
      .addCase(saveAsset.fulfilled, (state, action) => {
        state.saveLoading = false;
        state.dataAsset = action.payload;
      })
      .addCase(saveAsset.rejected, (state, action) => {
        state.saveLoading = false;
      })
      .addCase(getServerList.fulfilled, (state, action) => {
        state.serverList = action.payload;
      })
      .addCase(getList.pending, (state, action) => {
        state.tableLoading = true;
      })
      .addCase(getList.fulfilled, (state, action) => {
        if (action.payload.status == 500) {
          state.tableLoading = false;
          state.tableList = [];
        } else {
          state.tableLoading = false;
          state.tableList = action.payload;
        };
      })
      .addCase(getList.rejected, (state, action) => {
        state.tableLoading = false;
        state.tableList = [];
      })
      .addCase(getById.pending, (state, action) => {
        state.getLoading = true;
      })
      .addCase(getById.fulfilled, (state, action) => {
        state.getLoading = false;
        state.getData = action.payload;
      })
      .addCase(getById.rejected, (state, action) => {
        state.getLoading = false;
      })
      .addCase(deleteAsset.pending, (state, action) => {
        state.deleteLoading = true;
        state.deleteData = "";
      })
      .addCase(deleteAsset.fulfilled, (state, action) => {
        state.deleteLoading = false;
        state.deleteData = action.payload;
      })
      .addCase(deleteAsset.rejected, (state, action) => {
        state.deleteLoading = false;
      })
      .addCase(getCountAsset.pending, (state, action) => {
        state.CALoading = true;
      })
      .addCase(getCountAsset.fulfilled, (state, action) => {

        if (action.payload.status == 500) {
          state.countAssect = [];
          state.CALoading = false;
        } else {
          state.countAssect = action.payload;
          state.CALoading = false;
        };
      })
      .addCase(getTypeList.pending, (state, action) => {
        state.typeLoading = true;
        state.typeList = "";
      })
      .addCase(getTypeList.fulfilled, (state, action) => {
        state.typeList = action.payload;
        state.typeLoading = false;
      })
  },
});
export const { resetData } = AssetSlice.actions;
export default AssetSlice.reducer;
