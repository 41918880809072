import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Cookies from "universal-cookie";
import { handleResponseCode } from "../../app/utilities/helpers";
import GetProxy from "../../app/GetProxy";


const proxy = GetProxy()
const cookies = new Cookies();

export const cloudAccountList = createAsyncThunk(
  "post/cloudAccountList",
  async (props) => {
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    try {
      const { appliaction, pageNumber, itemsPerPage, payload } = props;
      const response = await fetch(
        `${proxy}/api/v1/CSPForAws/CspAccountListWithPaginationAndSearching/${appliaction}/${pageNumber}/${itemsPerPage}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": Apitoken,
            USERNAME: User,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

export const cloudAccountCount = createAsyncThunk(
  "post/cloudAccountCount",
  async (props) => {
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    try {
      const { application, payload } = props;
      const response = await fetch(
        `${proxy}/api/v1/CSPForAws/CspAccountListWithPaginationAndSearching/${application}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": Apitoken,
            USERNAME: User,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
export const userAccountList = createAsyncThunk(
  "post/userAccountList",
  async (props) => {
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    try {
      const { cmdb, pageNumber, itemsPerPage, payload } = props;
      const response = await fetch(
        `${proxy}/api/v1/UserAccessRest/usersListByIdSearchAndPagination/${cmdb}/${pageNumber}/${itemsPerPage}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": Apitoken,
            USERNAME: User,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

export const userAccountCount = createAsyncThunk(
  "post/userAccountCount",
  async (props) => {
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    try {
      const { cmdb, payload } = props;
      const response = await fetch(
        `${proxy}/api/v1/UserAccessRest/usersListByIdSearchAndCount/${cmdb}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": Apitoken,
            USERNAME: User,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
export const deviceList = createAsyncThunk("post/DeviceList", async (props) => {
  const Apitoken = cookies.get("apitoken");
  const User = cookies.get("username");
  try {
    const { cmdbId, pageNumber, itemsPerPage, payload } = props;
    const response = await fetch(
      `${proxy}/api/v1/server/list/searchAndPagination/${cmdbId}/${pageNumber}/${itemsPerPage}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "DTOP-API-TOKEN": Apitoken,
          USERNAME: User,
        },
        body: JSON.stringify(payload),
      }
    );
    handleResponseCode(response);
    const data = await response.json();

    return response.ok ? data : Promise.reject(data);
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
});

export const deviceCount = createAsyncThunk(
  "post/deviceCount",
  async (props) => {
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    try {
      const { cmdbId, payload } = props;
      const response = await fetch(
        `${proxy}/api/v1/server/list/searchAndCount/${cmdbId}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": Apitoken,
            USERNAME: User,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

export const projectList = createAsyncThunk(
  "post/projectList",
  async (props) => {
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    try {
      const { id, pageNumber, itemsPerPage, payload } = props;
      const response = await fetch(
        `${proxy}/api/v1/cmdb/${id}/projectsSearchAndPagination/${pageNumber}/${itemsPerPage}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": Apitoken,
            USERNAME: User,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

export const projectCount = createAsyncThunk(
  "post/projectCount",
  async (props) => {
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    try {
      const { id, payload } = props;
      const response = await fetch(`${proxy}/api/v1/cmdb/${id}/projectsSearchCount`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "DTOP-API-TOKEN": Apitoken,
          USERNAME: User,
        },
        body: JSON.stringify(payload),
      });
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
export const userdrilldownList = createAsyncThunk(
  "post/userdrilldownList",
  async (props) => {
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    try {
      const { accountId, pageNumber, itemsPerPage, payload } = props;
      const response = await fetch(
        `${proxy}/api/v1/awsIam/getUsersForAccountListWithPaginationAndSearch/${accountId}/${pageNumber}/${itemsPerPage}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": Apitoken,
            USERNAME: User,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

export const userdrilldownCount = createAsyncThunk(
  "post/userdrilldownCount",
  async (props) => {
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    try {
      const { accountId, payload } = props;
      const response = await fetch(`${proxy}/api/v1/awsIam/getUsersForAccountListWithPaginationAndSearchCount/${accountId}`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "DTOP-API-TOKEN": Apitoken,
          USERNAME: User,
        },
        body: JSON.stringify(payload),
      });
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
export const getCSPList = createAsyncThunk(
  "get/getCSPList",
  async (props) => {
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    const res = await fetch(
      `${proxy}/api/v1/awsIam/getCSPList`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "DTOP-API-TOKEN": Apitoken,
          USERNAME: User,
        },
      }
    ).then((resp) => resp.json());
    handleResponseCode(res);
    return res;
  }
);
export const getAccountIdList = createAsyncThunk(
  "get/getAccountIdList",
  async (props) => {
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    const CSP = props;
    const res = await fetch(
      `${proxy}/api/v1/awsIam/getAccountIdList/${CSP}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "DTOP-API-TOKEN": Apitoken,
          USERNAME: User,
        },
      }
    ).then((resp) => resp.json());
    handleResponseCode(res);
    return res;
  }
);
export const CmdbList = createAsyncThunk(
  "post/CmdbList",
  async (props) => {
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    try {
      const { page, limit, payload } = props;
      const response = await fetch(`${proxy}/api/v1/cmdb/cmdbListSearchAndPagination/${page}/${limit}`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "DTOP-API-TOKEN": Apitoken,
          USERNAME: User,
        },
        body: JSON.stringify(payload),
      });
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
export const CmdbCount = createAsyncThunk(
  "post/CmdbCount",
  async (props) => {
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    try {
      const { payload } = props;
      const response = await fetch(`${proxy}/api/v1/cmdb/cmdbListSearchCount`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "DTOP-API-TOKEN": Apitoken,
          USERNAME: User,
        },
        body: JSON.stringify(payload),
      });
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
const appConfigSlice = createSlice({
  name: "appConfig",
  initialState: {
    cloudAccountListLoading: false,
    cloudAccountListData: [],
    cloudAccountCountLoading: false,
    cloudAccountCountData: [],
    userAccountCountData: [],
    userAccountCountLoading: false,
    userAccountListData: [],
    userAccountListLoading: false,
    deviceListData: [],
    CmdbListData: [],
    CmdbListLoading: false,
    CmdbCountLoading: false,
    CmdbCountData: [],
    deviceCountData: [],
    deviceCountLoading: false,
    deviceListLoading: false,
    projectListData: [],
    projectCountData: [],
    projectCountLoading: false,
    projectListLoading: false,
    userdrilldownData: [],
    userdrilldownLoading: false,
    userdrilldownCountData: [],
    userdrilldownCountLoading: false,
    getCSPListData: [],
    getCSPListLoading: false,
    getAccountIdListData: [],
    getAccountIdListLoading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(cloudAccountList.pending, (state, action) => {
        state.cloudAccountListLoading = true;
      })
      .addCase(cloudAccountList.fulfilled, (state, action) => {
        state.cloudAccountListData = action.payload;
        state.cloudAccountListLoading = false;
      })
      .addCase(cloudAccountList.rejected, (state, action) => {
        state.cloudAccountListLoading = false;
        state.cloudAccountListData = [];
      })

      .addCase(cloudAccountCount.pending, (state, action) => {
        state.cloudAccountCountLoading = true;
      })
      .addCase(cloudAccountCount.fulfilled, (state, action) => {
        state.cloudAccountCountData = action.payload;
        state.cloudAccountCountLoading = false;
      })
      .addCase(cloudAccountCount.rejected, (state, action) => {
        state.cloudAccountCountLoading = false;
        state.cloudAccountCountData = [];
      })
      .addCase(userAccountList.pending, (state, action) => {
        state.userAccountListLoading = true;
      })
      .addCase(userAccountList.fulfilled, (state, action) => {
        state.userAccountListData = action.payload;
        state.userAccountListLoading = false;
      })
      .addCase(userAccountList.rejected, (state, action) => {
        state.userAccountListLoading = false;
        state.userAccountListData = [];
      })

      .addCase(userAccountCount.pending, (state, action) => {
        state.userAccountCountLoading = true;
      })
      .addCase(userAccountCount.fulfilled, (state, action) => {
        state.userAccountCountData = action.payload;
        state.userAccountCountLoading = false;
      })
      .addCase(userAccountCount.rejected, (state, action) => {
        state.userAccountCountLoading = false;
        state.userAccountCountData = [];
      })
      .addCase(deviceList.pending, (state, action) => {
        state.deviceListLoading = true;
      })
      .addCase(deviceList.fulfilled, (state, action) => {
        state.deviceListData = action.payload;
        state.deviceListLoading = false;
      })
      .addCase(deviceList.rejected, (state, action) => {
        state.deviceListLoading = false;
        state.deviceListData = [];
      })

      .addCase(deviceCount.pending, (state, action) => {
        state.deviceCountLoading = true;
      })
      .addCase(deviceCount.fulfilled, (state, action) => {
        state.deviceCountData = action.payload;
        state.deviceCountLoading = false;
      })
      .addCase(deviceCount.rejected, (state, action) => {
        state.deviceCountLoading = false;
        state.deviceCountData = [];
      })
      .addCase(projectList.pending, (state, action) => {
        state.projectListLoading = true;
      })
      .addCase(projectList.fulfilled, (state, action) => {
        state.projectListData = action.payload;
        state.projectListLoading = false;
      })
      .addCase(projectList.rejected, (state, action) => {
        state.projectListLoading = false;
        state.projectListData = [];
      })

      .addCase(projectCount.pending, (state, action) => {
        state.projectCountLoading = true;
      })
      .addCase(projectCount.fulfilled, (state, action) => {
        state.projectCountData = action.payload;
        state.projectCountLoading = false;
      })
      .addCase(projectCount.rejected, (state, action) => {
        state.projectCountLoading = false;
        state.projectCountData = [];
      })
      .addCase(userdrilldownList.pending, (state, action) => {
        state.userdrilldownLoading = true;
      })
      .addCase(userdrilldownList.fulfilled, (state, action) => {
        state.userdrilldownData = action.payload;
        state.userdrilldownLoading = false;
      })
      .addCase(userdrilldownList.rejected, (state, action) => {
        state.userdrilldownLoading = false;
        state.userdrilldownData = [];
      })

      .addCase(userdrilldownCount.pending, (state, action) => {
        state.userdrilldownCountLoading = true;
      })
      .addCase(userdrilldownCount.fulfilled, (state, action) => {
        state.userdrilldownCountData = action.payload;
        state.userdrilldownCountLoading = false;
      })
      .addCase(userdrilldownCount.rejected, (state, action) => {
        state.userdrilldownCountLoading = false;
        state.userdrilldownCountData = [];
      })

      .addCase(getCSPList.pending, (state, action) => {
        state.getCSPListLoading = true;
      })
      .addCase(getCSPList.fulfilled, (state, action) => {
        state.getCSPListData = action.payload;
        state.getCSPListLoading = false;
      })
      .addCase(getCSPList.rejected, (state, action) => {
        state.getCSPListLoading = false;
        state.getCSPListData = [];
      })
      .addCase(getAccountIdList.pending, (state, action) => {
        state.getAccountIdListLoading = true;
      })
      .addCase(getAccountIdList.fulfilled, (state, action) => {
        state.getAccountIdListData = action.payload;
        state.getAccountIdListLoading = false;
      })
      .addCase(getAccountIdList.rejected, (state, action) => {
        state.getAccountIdListLoading = false;
        state.getAccountIdListData = [];
      })
      .addCase(CmdbList.pending, (state, action) => {
        state.CmdbListLoading = true;
      })
      .addCase(CmdbList.fulfilled, (state, action) => {
        state.CmdbListData = action.payload;
        state.CmdbListLoading = false;
      })
      .addCase(CmdbList.rejected, (state, action) => {
        state.CmdbListLoading = false;
        state.CmdbListData = [];
      })
      .addCase(CmdbCount.pending, (state, action) => {
        state.CmdbCountLoading = true;
      })
      .addCase(CmdbCount.fulfilled, (state, action) => {
        state.CmdbCountData = action.payload;
        state.CmdbCountLoading = false;
      })
      .addCase(CmdbCount.rejected, (state, action) => {
        state.CmdbCountLoading = false;
        state.CmdbCountData = [];
      })
  },
});
export const { } = appConfigSlice.actions;
export default appConfigSlice.reducer;
