import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { BsFillInfoCircleFill } from "react-icons/bs";
import Select, {components} from "react-select";
import _ from "lodash";
import CustomSelect from "./Dropdown/CustomSelect";
import PropTypes from "prop-types";
const CustomSelectDropDown = (props) => {
  const {
    labelName,
    containerClassName,
    labelClassName,
    customlabelClassName,
    errorClassName,
    errorMessage,
    error,
    tooltipText,
    customFunction,
    onChange,
    options,
    containerColor,
    value, name,
    isDisabled,
    isMulti,
    searchable,
    selectedOption
  } = props;
  const handleOnChange =(selected)=>{
    onChange(selected[0]);
  }
  const shouldSortOptions = !('noOptionSorting' in props) || !props.noOptionSorting;
  const sortedOptions = shouldSortOptions ? _.sortBy(options, 'label') : options;
  const normalizeValue = (val) => {
    if (!val) return [];
    // If the value is already an array of objects with { value, label }
    if (Array.isArray(val) && val.every(item => item.value && item.label)) {
      return val;
    }
    // If the value is a single string like 'Option 1'
    if (typeof val === "string") {
      const foundOption = options.find(option => option.label === val || option.value === val);
      return foundOption ? [foundOption] : [];
    }
    // If the value is a single object { value: 'option1', label: 'Option 1' }
    if (val.value && val.label) {
      return [val];
    }
    return [];
  };

  const colourStyles = {
    control: (styles, state) => ({
      ...styles,
      // boxShadow: "none",
      fontSize: "var(--font13)",
      fontWeight: "var(--regular)",
      fontStyle: "normal",
      cursor: "pointer",
      minHeight: "30px",
      maxHeight: "38px",
      border: "white",
      color: selectedOption ? "white" : "",
      backgroundColor: selectedOption
        ? (props.selectedOption?.value === "Groups" ||
          props.selectedOption?.value === "Users" ||
          props.selectedOption?.value === "Inline Policy" ||
          props.selectedOption?.value === "Attached Policy")
          ? "#8A84E2"
          : (props.selectedOption === "Groups" ||
            props.selectedOption === "Users" ||
            props.selectedOption === "Inline Policy" ||
            props.selectedOption === "Attached Policy")
            ? "#8A84E2"
            : props.selectedOption?.value === "roles"
              ? "#FF7E80"
              : props.selectedOption?.value === "serviceroles"
                ? "#447E68"
                : props.selectedOption?.value === "users"
                  ? "#79BEEE"
                    :selectedOption === "inProgress"
                  ? "#102250"
                  : undefined
        : undefined,
       
        boxShadow:selectedOption?"": state.isFocused
        ? "0 0 0 0.17rem rgba(49, 132, 253, 0.28) !important"
        : "1px solid black",
        "&:hover": {
          border: state.isFocused && "1px solid var(--primary-active)",
        },
    }),
    menuList: (styles) => ({
      ...styles,
      zIndex: 2000,
      maxHeight: "250px",
    }),
    option: (styles) => ({
      ...styles,
      cursor: "pointer",
      fontSize: "var(--font13)",
      fontWeight: "var(--regular)",
      fontStyle: "normal",
      color: "var(--content-color)",
    }),
    singleValue: (styles) => ({
      ...styles,
      color:selectedOption?"white":"",
    }),
    placeholder: (styles) => ({
      ...styles,
      color:selectedOption? "white":"",
    }),
    dropdownIndicator: (styles) => ({
      ...styles,
      color: selectedOption?"white":"",
      "&:hover": {
        color: selectedOption?"white":"",
      },
    }),
    menu: (styles) => ({
      ...styles,
      zIndex: 1000,
    }),
  };
  return (
    <div
      className={`${
        containerClassName
          ? containerClassName
          : "frmgroup default-input col-lg-6"
      }`}
    >
      {/* {labelName && <label>{labelName}</label>} */}
      <div className={`frmhead ${labelClassName}`}>
        {labelName && (
          <label className={customlabelClassName}>
            {labelName}{" "}
            {tooltipText && (
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip className="tooltipHeader">{tooltipText}</Tooltip>
                }
              >
                <span>
                  <BsFillInfoCircleFill />
                </span>
              </OverlayTrigger>
            )}
          </label>
        )}
        {error && (
          <div className={`error ${errorClassName}`}>{errorMessage}</div>
        )}
      </div>
      <div
        className={`dropdown bootstrap-select ${props.containerwidth?props.containerwidth:""} ${error ? "TypeHeadBorder" : ""}`}
      >
         <CustomSelect
          options={sortedOptions}
          onChange={handleOnChange}
          defaultSelected={normalizeValue(value)}
          placeholder={name}
          selectContainerClassName={"w-100"}
          isDisabled={isDisabled}
          multiSelect={isMulti? isMulti : false}
          isError={props?.error}
          searchable={searchable}
          styles={colourStyles}
          selectedOption={selectedOption}
        />
      </div>
    </div>
  );
};
CustomSelectDropDown.propTypes = {
  labelName: PropTypes.arrayOf(PropTypes.object).isRequired,
  containerClassName: PropTypes.arrayOf(PropTypes.object).isRequired,  
  labelClassName: PropTypes.arrayOf(PropTypes.object).isRequired,  
  customlabelClassName: PropTypes.arrayOf(PropTypes.object).isRequired,  
  errorClassName: PropTypes.arrayOf(PropTypes.object).isRequired,  
  errorMessage: PropTypes.arrayOf(PropTypes.object).isRequired,  
  error: PropTypes.arrayOf(PropTypes.object).isRequired,  
  tooltipText: PropTypes.arrayOf(PropTypes.object).isRequired,  
  customFunction: PropTypes.arrayOf(PropTypes.object).isRequired,  
  onChange: PropTypes.arrayOf(PropTypes.object).isRequired,  
  options: PropTypes.arrayOf(PropTypes.object).isRequired,  
  containerColor: PropTypes.arrayOf(PropTypes.object).isRequired,  
  value: PropTypes.arrayOf(PropTypes.object).isRequired,  
  name: PropTypes.arrayOf(PropTypes.object).isRequired,  
  isDisabled: PropTypes.arrayOf(PropTypes.object).isRequired,  
  isMulti: PropTypes.arrayOf(PropTypes.object).isRequired,  
  searchable: PropTypes.arrayOf(PropTypes.object).isRequired,  
  selectedOption: PropTypes.arrayOf(PropTypes.object).isRequired,  
  noOptionSorting: PropTypes.arrayOf(PropTypes.object).isRequired,  
  containerwidth: PropTypes.arrayOf(PropTypes.object).isRequired,  
};

export default CustomSelectDropDown;