import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Cookies from "universal-cookie";
import { handleResponseCode } from "../../../app/utilities/helpers";
import GetProxy from "../../../app/GetProxy";


const proxy = GetProxy()
const cookies = new Cookies();

export const getGrouppList = createAsyncThunk(
    "post/getgruopCount",
    async (props) => {
        const apiToken = cookies.get("apitoken");
        const username = cookies.get("username");
        try {
            const { pageNumber, itemsPerPage, payload } = props;
            const response = await fetch(
                `${proxy}/api/v1/groups/groupsListWithSearchAndPagination/${pageNumber}/${itemsPerPage}`,
                {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        "DTOP-API-TOKEN": apiToken,
                        USERNAME: username,
                    },
                    body: JSON.stringify(payload),
                }
            )
            handleResponseCode(response);
            const data = await response.json();

            return response.ok ? data : Promise.reject(data);
        } catch (error) {
            console.error("Error:", error);
            throw error;
        }
    }
);

export const getGroupCount = createAsyncThunk(
    "post/getgruopData",
    async (props) => {
        const apiToken = cookies.get("apitoken");
        const username = cookies.get("username");
        try {
            const { payload } = props;
            const response = await fetch(
                `${proxy}/api/v1/groups/groupsListCountWithSearchAndPagination`,
                {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        "DTOP-API-TOKEN": apiToken,
                        USERNAME: username,
                    },
                    body: JSON.stringify(payload),
                }
            );
            handleResponseCode(response);
            const data = await response.json();

            return response.ok ? data : Promise.reject(data);
        } catch (error) {
            console.error("Error:", error);
            throw error;
        }
    }
);

const GroupSlice = createSlice({
    name: "GroupSlice",
    initialState: {
        resetData: [],
        groupData: [],
        groupCount: [],
        countLoading: [],
        groupLoading: false,
        error: [],
    },
    reducers: {
        setGroup: {
            reducer(state, action) {
                state.resetData = action.payload;
            },
        },
    },
    extraReducers: (builder) => {
        builder

            .addCase(getGrouppList.pending, (state, action) => {
                state.groupLoading = true;
            })
            .addCase(getGrouppList.fulfilled, (state, action) => {
                state.groupData = action.payload;
                state.groupLoading = false;
            })
            .addCase(getGrouppList.rejected, (state, action) => {
                state.groupLoading = false;
                state.groupData = [];
                state.error = "Something went wrong";
            })

            .addCase(getGroupCount.pending, (state, action) => {
                state.countLoading = true;
            })
            .addCase(getGroupCount.fulfilled, (state, action) => {
                state.groupCount = action.payload;
                state.countLoading = false;
            })
            .addCase(getGroupCount.rejected, (state, action) => {
                state.countLoading = false;
                state.groupCount = [];
                state.error = "Something went wrong";
            })
    },
});

export const { setGroup } = GroupSlice.actions;

export default GroupSlice.reducer;
