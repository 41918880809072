export const actionPlanFilterInt = [
    {
      label: "All",
      value: ""
    },
    {
      label: 'Deviation',
      value: "Deviation"
    },
    {
      label: "Pending Approval",
      value: "Pending Approval"
    },
    {
      label: 'Planned',
      value: "Planned"
    },
    {
      label: 'Rejected',
      value: "Rejected"
    },
    {
      label: 'Open',
      value: "Open"
    },
    {
      label: 'Whitelisted',
      value: "Whitelisted"
    }
  ]