import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Cookies from "universal-cookie";
import { handleResponseCode } from "../../../app/utilities/helpers";
import GetProxy from "../../../app/GetProxy";

const proxy = GetProxy()
const cookies = new Cookies();

export const RequestsByApplicationSBOMAPI = createAsyncThunk(
  "post/RequestsByApplicationSBOMAPI",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { payload } = props;
      const response = await fetch(
        `${proxy}/api/v1/sbomController/ciscoGraph/application`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
export const RequestStatusCISOSBOMAPI = createAsyncThunk(
  "post/RequestStatusCISOSBOMAPI",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { payload } = props;
      const response = await fetch(
        `${proxy}/api/v1/sbomController/CISO/getCountByStatusForVul`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
export const VulActionPlanCISOSBOMAPI = createAsyncThunk(
  "post/VulActionPlanCISOSBOMAPI",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { payload } = props;
      const response = await fetch(
        `${proxy}/api/v1/sbomController/ActionPlanCountsForVM`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
export const CISOsbomTableListAPI = createAsyncThunk(
  "post/CISOsbomTableListAPI",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { payload, pageNumber, itemsPerPage } = props;
      const response = await fetch(
        `${proxy}/api/v1/sbomController/getSBOMCISOVulnerabilityList/${pageNumber}/${itemsPerPage}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
export const CISOsbomTableCountAPI = createAsyncThunk(
  "post/CISOsbomTableCountAPI",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { payload } = props;
      const response = await fetch(
        `${proxy}/api/v1/sbomController/getSBOMCISOVulnerabilityListCount`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

export const ApproveAPI = createAsyncThunk(
  "post/ApproveAPI",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { payload } = props;
      const response = await fetch(
        `${proxy}/api/v1/sbomController/list/approveOrRejectStatusUpdate`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

const initialState = {
  RequestsByApplicationSBOMData: [],
  RequestsByApplicationSBOMDataLoading: false,
  RequestStatusCISOSBOMData: [],
  RequestStatusCISOSBOMDataLoading: false,
  VulActionPlanCISOSBOMData: [],
  VulActionPlanCISOSBOMDataLoading: false,
  CISOSBOMTableListData: [],
  CISOSBOMTableListDataLoading: false,
  CISOSBOMTablecountData: [],
  CISOSBOMTablecountDataLoading: false,
  CISOSBOMapproveData: [],
  CISOSBOMapproveDataLoading: false,

}
export const CISOSBOMSlice = createSlice({
  name: "CISOSBOMSlice",
  initialState,

  reducers: {
    resetCISOSBOMData: () => {
      return initialState;
    }
  },
  extraReducers: (builder) => {
    builder



      //Requests By Application
      .addCase(RequestsByApplicationSBOMAPI.pending, (state, action) => {
        state.RequestsByApplicationSBOMDataLoading = true;
      })
      .addCase(RequestsByApplicationSBOMAPI.fulfilled, (state, action) => {
        state.RequestsByApplicationSBOMData = action.payload;
        state.RequestsByApplicationSBOMDataLoading = false;
      })
      .addCase(RequestsByApplicationSBOMAPI.rejected, (state, action) => {
        state.RequestsByApplicationSBOMData = [];
        state.RequestsByApplicationSBOMDataLoading = false;
      })

      //Request Status
      .addCase(RequestStatusCISOSBOMAPI.pending, (state, action) => {
        state.RequestStatusCISOSBOMDataLoading = true;
      })
      .addCase(RequestStatusCISOSBOMAPI.fulfilled, (state, action) => {
        state.RequestStatusCISOSBOMData = action.payload;
        state.RequestStatusCISOSBOMDataLoading = false;
      })
      .addCase(RequestStatusCISOSBOMAPI.rejected, (state, action) => {
        state.RequestStatusCISOSBOMData = [];
        state.RequestStatusCISOSBOMDataLoading = false;
      })

      //vul Action By Plan
      .addCase(VulActionPlanCISOSBOMAPI.pending, (state, action) => {
        state.VulActionPlanCISOSBOMDataLoading = true;
      })
      .addCase(VulActionPlanCISOSBOMAPI.fulfilled, (state, action) => {
        state.VulActionPlanCISOSBOMData = action.payload;
        state.VulActionPlanCISOSBOMDataLoading = false;
      })
      .addCase(VulActionPlanCISOSBOMAPI.rejected, (state, action) => {
        state.VulActionPlanCISOSBOMData = [];
        state.VulActionPlanCISOSBOMDataLoading = false;
      })

      //TableList 

      .addCase(CISOsbomTableListAPI.pending, (state, action) => {
        state.CISOSBOMTableListDataLoading = true;
      })
      .addCase(CISOsbomTableListAPI.fulfilled, (state, action) => {
        state.CISOSBOMTableListData = action.payload;
        state.CISOSBOMTableListDataLoading = false;
      })
      .addCase(CISOsbomTableListAPI.rejected, (state, action) => {
        state.CISOSBOMTableListData = [];
        state.CISOSBOMTableListDataLoading = false;
      })


      //Tablecount 

      .addCase(CISOsbomTableCountAPI.pending, (state, action) => {
        state.CISOSBOMTablecountDataLoading = true;
      })
      .addCase(CISOsbomTableCountAPI.fulfilled, (state, action) => {
        state.CISOSBOMTablecountData = action.payload;
        state.CISOSBOMTablecountDataLoading = false;
      })
      .addCase(CISOsbomTableCountAPI.rejected, (state, action) => {
        state.CISOSBOMTablecountData = [];
        state.CISOSBOMTablecountDataLoading = false;
      })
      // Approveapi

      .addCase(ApproveAPI.pending, (state, action) => {
        state.CISOSBOMapproveDataLoading = true;
      })
      .addCase(ApproveAPI.fulfilled, (state, action) => {
        state.CISOSBOMapproveData = action.payload;
        state.CISOSBOMapproveDataLoading = false;
      })
      .addCase(ApproveAPI.rejected, (state, action) => {
        state.CISOSBOMapproveData = [];
        state.CISOSBOMapproveDataLoading = false;
      });


  },
});
export const { resetCISOSBOMData } = CISOSBOMSlice.actions;
export default CISOSBOMSlice.reducer;