export const DownloadPDF = ({ base64Data, fileName }) => {
    // Decode Base64 string
    const binaryString = atob(base64Data);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);

    // Create binary data
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }

    // Create a Blob from the binary data
    const blob = new Blob([bytes], { type: "application/pdf" });

    // Create a URL for the Blob and trigger download
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `${fileName ? fileName : "Server"}_ScanReport.pdf`;
    document.body.appendChild(link);
    link.click();

    // Cleanup
    URL.revokeObjectURL(url);
    document.body.removeChild(link);
};

