import React from "react";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { propTypes } from "react-bootstrap/esm/Image";
import Cookies from "universal-cookie";
import { post } from "jquery";
import { handleResponseCode } from "../../../app/utilities/helpers";
import GetProxy from "../../../app/GetProxy";
const cookies = new Cookies();
const proxy = GetProxy()

export const vmCurrentList = createAsyncThunk(
  "post/vmCurrentList",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { payload, pageNumber, itemsPerPage } = props;
      const response = await fetch(
        `${proxy}/api/v1/tenableNessusRest/getVulnerabilityManagementListBySearchSortingPagination/list/${pageNumber}/${itemsPerPage}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
export const vmCurrentListCount = createAsyncThunk(
  "post/vmCurrentListCount",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { payload } = props;
      const response = await fetch(
        `${proxy}/api/v1/tenableNessusRest/getVulnerabilityListCount`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
export const vmTargetDueDate = createAsyncThunk(
  "post/vmTargetDueDate",
  async (props) => {
    try {
      const { payload, pageNumber, itemsPerPage, year, month } = props;
      const Apitoken = cookies.get("apitoken");
      const User = cookies.get("username");
      const response = await fetch(
        `${proxy}/api/v1/tenableNessusRest/getTargetDateDueDrillDownList/${year}/${month}/${pageNumber}/${itemsPerPage}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": Apitoken,
            USERNAME: User,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
export const vmTargetDueDateCount = createAsyncThunk(
  "post/vmTargetDueDateCount",
  async (props) => {
    try {
      const { payload, year, month } = props;
      const Apitoken = cookies.get("apitoken");
      const User = cookies.get("username");
      const response = await fetch(
        `${proxy}/api/v1/tenableNessusRest/getTargetDateDueDrillDownListCount/${year}/${month}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": Apitoken,
            USERNAME: User,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
export const vmCurrentListDrilldown = createAsyncThunk(
  "post/vmCurrentListDrilldown",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { payload, pageNumber, itemsPerPage } = props;
      const response = await fetch(
        `${proxy}/api/v1/tenableNessusRest/getVulnerabilityManagementListBySearchSortingPagination/list/${pageNumber}/${itemsPerPage}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
export const vmCurrentListCountDrilldown = createAsyncThunk(
  "post/vmCurrentListCountDrilldown",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { payload } = props;
      const response = await fetch(
        `${proxy}/api/v1/tenableNessusRest/getVulnerabilityListCount`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
export const vmCurrentListMissed = createAsyncThunk(
  "post/vmCurrentListMissed",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { payload, pageNumber, itemsPerPage } = props;
      const response = await fetch(
        `${proxy}/api/v1/tenableNessusRest/getVulnerabilityListForTargetDateMissedBySearchAndPaginationAndCount/${pageNumber}/${itemsPerPage}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
export const vmCurrentListCountMissed = createAsyncThunk(
  "post/vmCurrentListCountMissed",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { payload } = props;
      const response = await fetch(
        `${proxy}/api/v1/tenableNessusRest/getTargetDateListCount`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

export const vmFixedList = createAsyncThunk(
  "post/vmFixedList",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { pageNumber, itemsPerPage, payload } = props;
      const response = await fetch(
        `${proxy}/api/v1/tenableNessusRest/fixedVulnerabilityManagementListBySearchWithStatus/list/${pageNumber}/${itemsPerPage}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
export const vmFixedListCount = createAsyncThunk(
  "post/vmFixedListCount",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { payload } = props;
      const response = await fetch(
        `${proxy}/api/v1/tenableNessusRest/getFixedVulnerabilityListCount`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

export const cveIdApi = createAsyncThunk("post/cveIdApi", async (props) => {
  const apiToken = cookies.get("apitoken");
  const username = cookies.get("username");
  try {
    const response = await fetch(`${proxy}/api/v1/trs/getEpssScore/${props}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "DTOP-API-TOKEN": apiToken,
        USERNAME: username,
      },
      // body: JSON.stringify(payload),
    });
    handleResponseCode(response);
    const data = await response.json();

    return response.ok ? data : Promise.reject(data);
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
});

export const vmTRSServerity = createAsyncThunk(
  "post/vmTRSServerity",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { payload } = props;
      const response = await fetch(
        `${proxy}/api/v1/tenableNessusRest/vulnerabilitiesByTRSSeverity`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payload),
          // body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
export const vmRiskCount = createAsyncThunk(
  "post/vmRiskCount",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { payload } = props;
      const response = await fetch(
        `${proxy}/api/v1/tenableNessusRest/getTopFiveMostAtRiskAssetsCount`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
export const vmTop5RiskAssets = createAsyncThunk(
  "post/vmTop5RiskAssets",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { payloadTop5Risk } = props;
      const response = await fetch(
        `${proxy}/api/v1/tenableNessusRest/getTopFiveMostAtRiskAssets/0/10`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payloadTop5Risk),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
export const vmRiskAssets = createAsyncThunk(
  "post/vmRiskAssets",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { payload, pageNo, pageLimit } = props;
      const response = await fetch(
        `${proxy}/api/v1/tenableNessusRest/getTopFiveMostAtRiskAssets/${pageNo}/${pageLimit}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
export const ApplicationCountAPI = createAsyncThunk(
  "post/ApplicationCountAPI",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { payload } = props;
      const response = await fetch(`${proxy}/api/v1/tenableNessusRest/countAppCodes`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "DTOP-API-TOKEN": apiToken,
          USERNAME: username,
        },
        body: JSON.stringify(payload),
      });
      handleResponseCode(response);
      const data = await response.json();

      return data;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
export const OpenVmApplicationApi = createAsyncThunk(
  "post/OpenVmApplicationApi",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { payload } = props;
      // const response = await fetch(`${proxy}/api/v1/tenableNessusRest/getNewFindingsCountsAndPercentage`,
      const response = await fetch(
        `${proxy}/api/v1/tenableNessusRest/getTotalFindingsCount`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return data;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
export const allRiskAppApi = createAsyncThunk(
  "post/allRiskAppApi",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { pageLimit, pageNo, payload } = props;
      const response = await fetch(
        `${proxy}/api/v1/tenableNessusRest/getTopFiveMostAtRiskApplications/${pageNo}/${pageLimit}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
export const getAPPTrsSeverityApi = createAsyncThunk(
  "post/getAPPTrsSeverityApi",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    const { payload } = props;
    try {
      const response = await fetch(
        `${proxy}/api/v1/tenableNessusRest/getApplicationByTRSSeverity`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
export const assetsOverview = createAsyncThunk(
  "post/assetsOverview",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { payload } = props;
      const response = await fetch(`${proxy}/api/v1/tenableNessusRest/getAssetCounts`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "DTOP-API-TOKEN": apiToken,
          USERNAME: username,
        },
        body: JSON.stringify(payload),
      });
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
export const allRiskAppCountApi = createAsyncThunk(
  "post/allRiskAppCountApi",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { pageLimit, pageNo, payload } = props;
      const response = await fetch(
        `${proxy}/api/v1/tenableNessusRest/getTopFiveMostAtRiskApplicationCount`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
export const ClosedVmApplicationApi = createAsyncThunk(
  "post/ClosedmVApplicationApi",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { payload } = props;
      const response = await fetch(
        `${proxy}/api/v1/tenableNessusRest/getClosedFindingsCount`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return data;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
export const getTop5VulnerabilityCVEIDDetails = createAsyncThunk(
  "post/getTop5VulnerabilityCVEIDDetails",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { payload } = props;
      const response = await fetch(
        `${proxy}/api/v1/tenableNessusRest/getTop5VulnerabilityCVEIDDetails`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return data;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
export const getTop5VulnerabilityFamilyDetails = createAsyncThunk(
  "post/getTop5VulnerabilityFamilyDetails",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { payload } = props;
      const response = await fetch(
        `${proxy}/api/v1/tenableNessusRest/getTop5VulnerabilityFamilyDetails`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return data;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

export const ActionPlanbyAppAPI = createAsyncThunk(
  "post/ActionPlanbyAppAPI",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { payload } = props;
      const response = await fetch(
        `${proxy}/api/v1/tenableNessusRest/getStatusBySeverity`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return data;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

export const vulDistribution = createAsyncThunk(
  "post/Distribution",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { payload } = props;
      const response = await fetch(
        `${proxy}/api/v1/tenableNessusRest/findingsDistribution`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

export const vulActionPlan = createAsyncThunk(
  "post/ActionPlan",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { payload } = props;
      const response = await fetch(
        `${proxy}/api/v1/tenableNessusRest/getStatusBySeverity`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
export const openVulnerabilityAPI = createAsyncThunk(
  "post/openVulnerabilityAPI",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { payload } = props;
      const response = await fetch(
        `${proxy}/api/v1/tenableNessusRest/getOpenFindingsCount`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
export const top5RiskAppApi = createAsyncThunk(
  "post/top5RiskAppApi",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { payloadTop5App } = props;
      const response = await fetch(
        `${proxy}/api/v1/tenableNessusRest/getTopFiveMostAtRiskApplications/0/10`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payloadTop5App),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
export const treeChartApiCall = createAsyncThunk(
  "post/treeChartApiCall",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const response = await fetch(`${proxy}/api/v1/tenableNessusRest/details/cveId`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "DTOP-API-TOKEN": apiToken,
          USERNAME: username,
        },
        body: JSON.stringify(props),
      });
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
export const getBusinessDepartmentAPI = createAsyncThunk(
  "post/getBusinessDepartmentAPI",
  async (props) => {
    // const {divisionPayload} = props;
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const response = await fetch(
        `${proxy}/api/v1/tenableNessusRest/getBusinessDivision`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": apiToken,
            USERNAME: username,
          },
          // body: JSON.stringify(divisionPayload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
export const getBusinessDivisionAPI = createAsyncThunk(
  "post/getBusinessDivisionAPI",
  async (props) => {
    const { payload } = props;
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const response = await fetch(
        `${proxy}/api/v1/tenableNessusRest/getBusinessDepartmentByDivision`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

export const getTopVulnerability = createAsyncThunk(
  "get/getTopVulnerability",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    const { payload } = props;
    try {
      const response = await fetch(
        `/api/v1/tenableNessusRest/list/topVulnerability`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
)

export const getCisoDetails = createAsyncThunk(
  "get/getCisoDetails",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const response = await fetch(
        `/api/v1/tenableNessusRest/listByCiso/`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": apiToken,
            USERNAME: username,
          },
          // body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
)
export const getVMdetailsfilterAPI = createAsyncThunk(
  "get/getVMdetailsfilterAPI",
  async (props) => {
    // const { payload } = props;
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const response = await fetch(
        `${proxy}/api/v1/dropDownFields/VulnerabilityManagement/getListByType`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": apiToken,
            USERNAME: username,
          },
          // body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);


export const getVMAppAPI = createAsyncThunk(
  "post/getAppbyBusinessDepartmentAPI",
  async (props) => {
    const { payload } = props;
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const response = await fetch(
        `${proxy}/api/v1/tenableNessusRest/getApplicationByDivision`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
export const getCISOaccount = createAsyncThunk(
  "post/getCISOaccount",
  async (props) => {
    const { payload } = props;
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const response = await fetch(`${proxy}/api/v1/CSPForAws/accountIds`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "DTOP-API-TOKEN": apiToken,
          USERNAME: username,
        },
        body: JSON.stringify(payload),
      });
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
export const getTargetDateListCount = createAsyncThunk(
  "post/getTargetDateListCount",
  async (props) => {
    const { payload } = props;
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const response = await fetch(
        `${proxy}/api/v1/tenableNessusRest/getTargetDateListCount`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
export const getListForTargetDate = createAsyncThunk(
  "post/getListForTargetDate",
  async (props) => {
    const { payload, pageNumber, itemsPerPage } = props;
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const response = await fetch(
        `${proxy}/api/v1/tenableNessusRest/getListForTargetDate/${pageNumber}/${itemsPerPage}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
export const getTargetDateDueDrillDownList = createAsyncThunk(
  "post/getTargetDateDueDrillDownList",
  async (props) => {
    const { payload, pageNumber, itemsPerPage, year, month } = props;
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const response = await fetch(
        `${proxy}/api/v1/tenableNessusRest/getVulnerabilityManagementForTargetDateDueListBySearchSortingPagination/${year}/${month}/${pageNumber}/${itemsPerPage}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
export const getTargetDateDueDrillDownListCount = createAsyncThunk(
  "post/getTargetDateDueDrillDownListCount",
  async (props) => {
    const { payload, year, month } = props;
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const response = await fetch(
        `${proxy}/api/v1/tenableNessusRest/getTargetDateDueDrillDownListCount/${year}/${month}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
export const getTargetMissedItems = createAsyncThunk(
  "post/getTargetMissedItems",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { payload } = props;
      const response = await fetch(`${proxy}/api/v1/tenableNessusRest/getTargetDates`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "DTOP-API-TOKEN": apiToken,
          USERNAME: username,
        },
        body: JSON.stringify(payload),
      });
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
export const getVulnmanageTrendAPI = createAsyncThunk(
  "post/getVulnmanageTrendAPI",
  async (props) => {
    const { payload } = props;
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const response = await fetch(`${proxy}/api/v1/tenableNessusRest/open/count`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "DTOP-API-TOKEN": apiToken,
          USERNAME: username,
        },
        body: JSON.stringify(payload),
      });
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
export const getCloseVulnDistributionAPI = createAsyncThunk(
  "post/getCloseVulnDistributionAPI",
  async (props) => {
    const { payload } = props;
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const response = await fetch(
        `${proxy}/api/v1/tenableNessusRest/getClosedFindingsCount`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
export const getCvidCheck = createAsyncThunk(
  "post/getCvidCheck",
  async (props) => {
    const { payload } = props;
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const response = await fetch(`${proxy}/api/v1/tenableNessusRest/checkingCveIds`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "DTOP-API-TOKEN": apiToken,
          USERNAME: username,
        },
        body: JSON.stringify(payload),
      });
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
export const assetDistribution = createAsyncThunk(
  "post/assetDistribution",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { payload } = props;
      const response = await fetch(
        `${proxy}/api/v1/tenableNessusRest/AssetsByTRSSeverity`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

export const ExportCSV = createAsyncThunk(
  "post/ExportCSV",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { payload } = props;
      const response = await fetch(
        `${proxy}/api/v1/tenableNessusRest/exportVulnerabilityManagementListAsCSV`,
        {
          method: "POST",
          headers: {
            Accept: "application/octet-stream",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.blob();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
export const VMCVSSSeverity = createAsyncThunk(
  "post/VMCVSSSeverity",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { payload } = props;
      const response = await fetch(
        `${proxy}/api/v1/tenableNessusRest/list/categorizeVulnerability`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

export const getSecurityDetails = createAsyncThunk("get/SecurityDetails", async (props) => {
  const apiToken = cookies.get("apitoken");
  const username = cookies.get("username");
  try {
    const response = await fetch(`${proxy}/api/v1/aws/get/members/details`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "DTOP-API-TOKEN": apiToken,
        USERNAME: username,
      },
      // body: JSON.stringify(payload),
    });
    handleResponseCode(response);
    const data = await response.json();

    return response.ok ? data : Promise.reject(data);
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
});
export const VulnerabilityByAppAPI = createAsyncThunk(
  "post/VulnerabilityByAppAPI",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { payload } = props;
      const response = await fetch(
        `${proxy}/api/v1/tenableNessusRest/getOpenCountByCriticalityFiltered`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
export const VulTargetDateDuAPI = createAsyncThunk(
  "post/VulTargetDateDuAPI",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { payload } = props;
      const response = await fetch(
        `${proxy}/api/v1/tenableNessusRest/getSixMonthTargetDate`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

const initialState = {
  vmCurrentListData: null,
  vmCurrentListLoading: false,
  vmCurrentListCounts: [],
  vmCurrentListCountLoading: [],
  vmCurrentListDataDrilldown: null,
  vmCurrentListLoadingDrilldown: false,
  vmCurrentListCountsDrilldown: [],
  vmCurrentListCountLoadingDrilldown: [],
  vmCurrentListMissedData: [],
  vmCurrentListLoadingMissed: false,
  vmCurrentListCountsMissed: [],
  vmCurrentListCountLoadingMvmCurrentListDrilldownissed: [],
  vmFixedListData: null,
  vmFixedListLoading: false,
  vmFixedListCounts: [],
  vmFixedListCountLoading: [],
  error: "",
  epssScore: [],
  cveIdApiLoading: false,
  vmTRSServerityLoading: false,
  vmTRSServerityData: [],
  vmRiskCountLoading: false,
  vmRiskCountData: [],
  vmRiskAssetsData: [],
  vmRiskAssetsLoading: false,
  vmTop5RiskAssetsLoading: false,
  vmTop5RiskAssetsData: [],
  DistributionChart: [],
  vulDistributionloading: false,
  actionPlanChart: [],
  vulActionPlanloading: false,
  openVulnerabiltyChartData: [],
  openVulnerabiltyChartloading: false,
  vmTop5RiskAppLoading: false,
  vmTop5RiskAppData: [],
  treeChartData: [],
  treeChartDataLoading: [],
  businessDivisionOption: [],
  businessDivisionOptionLoading: false,
  businessDepartmentOption: [],
  businessDepartmentOptionLoading: false,
  businessDepartmentOption: [],
  businessDepartmentOptionLoading: false,
  vmAppData: [],
  vmAppDataLoading: false,
  vmAccountData: [],
  vmAccountDataLoading: false,
  getListForTargetDateData: [],
  getListForTargetDateLoading: false,
  getTargetDateListCountData: [],
  getTargetDateListCountLoading: false,
  getTargetDateDueDrillDownListLoading: false,
  getTargetDateDueDrillDownListData: [],
  getTargetDateDueDrillDownListCountLoading: false,
  getTargetDateDueDrillDownListCountData: [],
  VulnTrendData: [],
  VulnTrendDataLoading: false,
  closevulnerabilityData: [],
  ClosedistributeDataLoading: false,
  cvidCheck: [],
  cveIdCheckLoading: false,
  getTargetMissedItemsData: [],
  getTargetMissedItemsLoading: false,
  actionByAppData: [],
  actionByAppDataLoading: false,
  openVmAppData: [],
  openVmAppDataLoading: false,
  closedVmAppData: [],
  closedVmAppDataLoading: false,
  allRiskAppCount: [],
  allRiskAppCountLoading: false,
  allRiskAppData: [],
  allRiskAppDataLoading: false,
  appTrsSeverityData: [],
  appTrsSeverityLoading: false,
  CVEIDDetails: [],
  CVEIDDetailsLoading: false,
  getTop5Vulnerability: [],
  getTop5VulnerabilityLoading: false,
  assetsOverviewData: [],
  assetsOverviewLoading: false,
  disabledCategories: [],
  secuirtyDetails: [],
  CSVDetails: [],
  CSVloading: false,
  securitydetailsLoading: false,
  detailsvmCriticalityLoading: false,
  detailsvmCriticalityData: [],
  cisoDetails: null,
  topVulnerabilityData: null,
  VMCVSSSeverityLoading: false,
  VMCVSSSeverityData: [],
  VulnerabilityByAppData: [],
  VulnerabilityByAppLoading: false,
  VulTargetDateDuData: [],
  VulTargetDateDuLoading: false,

};
export const vulnerabilitiesSlice = createSlice({
  name: "vmSlice",
  initialState,

  reducers: {
    resetVulnerabilityData: () => {
      return initialState;
    },
    setDisabledCategories: (state, action) => {
      state.disabledCategories = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      //detailstableCriticality
      .addCase(getVMdetailsfilterAPI.pending, (state, action) => {
        state.detailsvmCriticalityLoading = true,
          state.error = "";
      })
      .addCase(getVMdetailsfilterAPI.fulfilled, (state, action) => {
        state.detailsvmCriticalityData = action.payload;
        state.detailsvmCriticalityLoading = false;
      })
      .addCase(getVMdetailsfilterAPI.rejected, (state, action) => {
        state.detailsvmCriticalityLoading = false;
        state.detailsvmCriticalityData = [];
        state.error = "something went wrong";
      })

      //getTopVulnerability
      .addCase(getTopVulnerability.pending, (state, action) => {
        state.error = "";
        state.topVulnerabilityData = null
      })
      .addCase(getTopVulnerability.fulfilled, (state, action) => {
        state.topVulnerabilityData = action.payload
      })
      .addCase(getTopVulnerability.rejected, (state, action) => {
        state.topVulnerabilityData = null
        state.error = "something went wrong";
      })

      //getCisoDetails
      .addCase(getCisoDetails.pending, (state, action) => {
        state.error = "";
        state.cisoDetails = null;
      })
      .addCase(getCisoDetails.fulfilled, (state, action) => {
        state.cisoDetails = action.payload
      })
      .addCase(getCisoDetails.rejected, (state, action) => {
        state.cisoDetails = null;
        state.error = "something went wrong";
      })

      //vmCurrentList
      .addCase(vmCurrentList.pending, (state, action) => {
        state.vmCurrentListLoading = true;
        state.error = "";
      })
      .addCase(vmCurrentList.fulfilled, (state, action) => {
        state.vmCurrentListData = action.payload;
        state.vmCurrentListLoading = false;
      })
      .addCase(vmCurrentList.rejected, (state, action) => {
        state.vmCurrentListLoading = false;
        state.vmCurrentListData = null;
        state.error = "something went wrong";
      })
      //vmCurrentListCount
      .addCase(vmCurrentListCount.pending, (state, action) => {
        state.vmCurrentListCountLoading = true;
      })
      .addCase(vmCurrentListCount.fulfilled, (state, action) => {
        state.vmCurrentListCounts = action.payload;
        state.vmCurrentListCountLoading = false;
      })
      .addCase(vmCurrentListCount.rejected, (state, action) => {
        state.vmCurrentListCountLoading = false;
        state.error = "something went wrong";
      })
      //assetDistribution
      .addCase(assetDistribution.pending, (state, action) => {
        state.assetDistributionLoading = true;
      })
      .addCase(assetDistribution.fulfilled, (state, action) => {
        state.assetDistributionData = action.payload;
        state.assetDistributionLoading = false;
      })
      .addCase(assetDistribution.rejected, (state, action) => {
        state.assetDistributionLoading = false;
      })
      //vmCurrentListDrilldown
      .addCase(vmCurrentListDrilldown.pending, (state, action) => {
        state.vmCurrentListLoadingDrilldown = true;
        state.error = "";
      })
      .addCase(vmCurrentListDrilldown.fulfilled, (state, action) => {
        state.vmCurrentListDataDrilldown = action.payload;
        state.vmCurrentListLoadingDrilldown = false;
      })
      .addCase(vmCurrentListDrilldown.rejected, (state, action) => {
        state.vmCurrentListLoadingDrilldown = false;
        state.vmCurrentListDataDrilldown = null;
        state.error = "something went wrong";
      })
      //vmCurrentListCountDrilldown
      .addCase(vmCurrentListCountDrilldown.pending, (state, action) => {
        state.vmCurrentListCountLoadingDrilldown = true;
      })
      .addCase(vmCurrentListCountDrilldown.fulfilled, (state, action) => {
        state.vmCurrentListCountsDrilldown = action.payload;
        state.vmCurrentListCountLoadingDrilldown = false;
      })
      .addCase(vmCurrentListCountDrilldown.rejected, (state, action) => {
        state.vmCurrentListCountLoadingDrilldown = false;
        state.error = "something went wrong";
      })
      //vmCurrentListMissed
      .addCase(vmCurrentListMissed.pending, (state, action) => {
        state.vmCurrentListLoadingMissed = true;
        state.error = "";
      })
      .addCase(vmCurrentListMissed.fulfilled, (state, action) => {
        state.vmCurrentListMissedData = action.payload;
        state.vmCurrentListLoadingMissed = false;
      })
      .addCase(vmCurrentListMissed.rejected, (state, action) => {
        state.vmCurrentListLoadingMissed = false;
        state.vmCurrentListMissedData = [];
        state.error = "something went wrong";
      })
      //vmCurrentListCountMissed
      .addCase(vmCurrentListCountMissed.pending, (state, action) => {
        state.vmCurrentListCountLoadingMissed = true;
      })
      .addCase(vmCurrentListCountMissed.fulfilled, (state, action) => {
        state.vmCurrentListCountsMissed = action.payload;
        state.vmCurrentListCountLoadingMissed = false;
      })
      .addCase(vmCurrentListCountMissed.rejected, (state, action) => {
        state.vmCurrentListCountLoadingMissed = false;
        state.error = "something went wrong";
      })

      //vmFixedList
      .addCase(vmFixedList.pending, (state, action) => {
        state.vmFixedListLoading = true;
      })
      .addCase(vmFixedList.fulfilled, (state, action) => {
        state.vmFixedListData = action.payload;
        state.vmFixedListLoading = false;
      })
      .addCase(vmFixedList.rejected, (state, action) => {
        state.vmFixedListLoading = false;
        state.vmFixedListData = null;
        state.error = "something went wrong";
      })
      //vmFixedListCount
      .addCase(vmFixedListCount.pending, (state, action) => {
        state.vmFixedListCountLoading = true;
      })
      .addCase(vmFixedListCount.fulfilled, (state, action) => {
        state.vmFixedListCounts = action.payload;
        state.vmFixedListCountLoading = false;
      })
      .addCase(vmFixedListCount.rejected, (state, action) => {
        state.vmFixedListCountLoading = false;
        state.error = "something went wrong";
      })
      //assetsOverview
      .addCase(assetsOverview.pending, (state, action) => {
        state.assetsOverviewLoading = true;
      })
      .addCase(assetsOverview.fulfilled, (state, action) => {
        state.assetsOverviewData = action.payload;
        state.assetsOverviewLoading = false;
      })
      .addCase(assetsOverview.rejected, (state, action) => {
        state.assetsOverviewLoading = false;
      })
      //cveIdApi
      .addCase(cveIdApi.pending, (state, action) => {
        state.cveIdApiLoading = true;
      })
      .addCase(cveIdApi.fulfilled, (state, action) => {
        state.epssScore = action.payload;
        state.cveIdApiLoading = false;
      })
      .addCase(cveIdApi.rejected, (state, action) => {
        state.cveIdApiLoading = false;
      })
      //vmTRSServerity
      .addCase(vmTRSServerity.pending, (state, action) => {
        state.vmTRSServerityLoading = true;
      })
      .addCase(vmTRSServerity.fulfilled, (state, action) => {
        state.vmTRSServerityData = action.payload;
        state.vmTRSServerityLoading = false;
      })
      .addCase(vmTRSServerity.rejected, (state, action) => {
        state.vmTRSServerityLoading = false;
      })
      //vmRiskCount
      .addCase(vmRiskCount.pending, (state, action) => {
        state.vmRiskCountLoading = true;
      })
      .addCase(vmRiskCount.fulfilled, (state, action) => {
        state.vmRiskCountData = action.payload;
        state.vmRiskCountLoading = false;
      })
      .addCase(vmRiskCount.rejected, (state, action) => {
        state.vmRiskCountLoading = false;
      })
      //getAPPTrsSeverityApi
      .addCase(getAPPTrsSeverityApi.pending, (state, action) => {
        state.appTrsSeverityLoading = true;
      })
      .addCase(getAPPTrsSeverityApi.fulfilled, (state, action) => {
        state.appTrsSeverityData = action.payload;
        state.appTrsSeverityLoading = false;
      })
      .addCase(getAPPTrsSeverityApi.rejected, (state, action) => {
        state.appTrsSeverityLoading = false;
      })
      //vmRiskAssets
      .addCase(vmRiskAssets.pending, (state, action) => {
        state.vmRiskAssetsLoading = true;
      })
      .addCase(vmRiskAssets.fulfilled, (state, action) => {
        state.vmRiskAssetsData = action.payload;
        state.vmRiskAssetsLoading = false;
      })
      .addCase(vmRiskAssets.rejected, (state, action) => {
        state.vmRiskAssetsLoading = false;
      })
      //vmTop5RiskAssets
      .addCase(vmTop5RiskAssets.pending, (state, action) => {
        state.vmTop5RiskAssetsLoading = true;
      })
      .addCase(vmTop5RiskAssets.fulfilled, (state, action) => {
        state.vmTop5RiskAssetsData = action.payload;
        state.vmTop5RiskAssetsLoading = false;
      })
      .addCase(vmTop5RiskAssets.rejected, (state, action) => {
        state.vmTop5RiskAssetsLoading = false;
      })
      //Open Vm APP
      .addCase(OpenVmApplicationApi.pending, (state, action) => {
        state.openVmAppDataLoading = true;
      })
      .addCase(OpenVmApplicationApi.fulfilled, (state, action) => {
        state.openVmAppData = action.payload;
        state.openVmAppDataLoading = false;
      })
      .addCase(OpenVmApplicationApi.rejected, (state, action) => {
        state.openVmAppDataLoading = false;
      })
      //Closed Vm APP
      .addCase(ClosedVmApplicationApi.pending, (state, action) => {
        state.closedVmAppDataLoading = true;
      })
      .addCase(ClosedVmApplicationApi.fulfilled, (state, action) => {
        state.closedVmAppData = action.payload;
        state.closedVmAppDataLoading = false;
      })
      .addCase(ClosedVmApplicationApi.rejected, (state, action) => {
        state.closedVmAppDataLoading = false;
      })
      //getTop5VulnerabilityFamilyDetails
      .addCase(getTop5VulnerabilityFamilyDetails.pending, (state, action) => {
        state.getTop5VulnerabilityLoading = true;
      })
      .addCase(getTop5VulnerabilityFamilyDetails.fulfilled, (state, action) => {
        state.getTop5Vulnerability = action.payload;
        state.getTop5VulnerabilityLoading = false;
      })
      .addCase(getTop5VulnerabilityFamilyDetails.rejected, (state, action) => {
        state.getTop5VulnerabilityLoading = false;
      })
      //getTop5VulnerabilityCVEIDDetails
      .addCase(getTop5VulnerabilityCVEIDDetails.pending, (state, action) => {
        state.CVEIDDetailsLoading = true;
      })
      .addCase(getTop5VulnerabilityCVEIDDetails.fulfilled, (state, action) => {
        state.CVEIDDetails = action.payload;
        state.CVEIDDetailsLoading = false;
      })
      .addCase(getTop5VulnerabilityCVEIDDetails.rejected, (state, action) => {
        state.CVEIDDetailsLoading = false;
      })
      //allRiskAppApi
      .addCase(allRiskAppApi.pending, (state, action) => {
        state.allRiskAppDataLoading = true;
      })
      .addCase(allRiskAppApi.fulfilled, (state, action) => {
        state.allRiskAppData = action.payload;
        state.allRiskAppDataLoading = false;
      })
      .addCase(allRiskAppApi.rejected, (state, action) => {
        state.allRiskAppDataLoading = false;
      })
      //APP Count
      .addCase(ApplicationCountAPI.pending, (state, action) => {
        state.ApplicationCountLoading = true;
      })
      .addCase(ApplicationCountAPI.fulfilled, (state, action) => {
        state.ApplicationCount = action.payload;
        state.ApplicationCountLoading = false;
      })
      .addCase(ApplicationCountAPI.rejected, (state, action) => {
        state.ApplicationCountLoading = false;
      })
      //allRiskAppApi
      .addCase(allRiskAppCountApi.pending, (state, action) => {
        state.allRiskAppCountLoading = true;
      })
      .addCase(allRiskAppCountApi.fulfilled, (state, action) => {
        state.allRiskAppCount = action.payload;
        state.allRiskAppCountLoading = false;
      })
      .addCase(allRiskAppCountApi.rejected, (state, action) => {
        state.allRiskAppCountLoading = false;
      })
      //DistributionApi
      .addCase(vulDistribution.pending, (state, action) => {
        state.vulDistributionloading = true;
      })
      .addCase(vulDistribution.fulfilled, (state, action) => {
        state.DistributionChart = action.payload;
        state.vulDistributionloading = false;
      })
      .addCase(vulDistribution.rejected, (state, action) => {
        state.vulDistributionloading = false;
      })
      //ActionPlanApi
      .addCase(vulActionPlan.pending, (state, action) => {
        state.vulActionPlanloading = true;
      })
      .addCase(vulActionPlan.fulfilled, (state, action) => {
        state.actionPlanChart = action.payload;
        state.vulActionPlanloading = false;
      })
      .addCase(vulActionPlan.rejected, (state, action) => {
        state.vulActionPlanloading = false;
      })
      //openVulnerabiltyChartData
      .addCase(openVulnerabilityAPI.pending, (state, action) => {
        state.openVulnerabiltyChartloading = true;
      })
      .addCase(openVulnerabilityAPI.fulfilled, (state, action) => {
        state.openVulnerabiltyChartData = action.payload;
        state.openVulnerabiltyChartloading = false;
      })
      .addCase(openVulnerabilityAPI.rejected, (state, action) => {
        state.openVulnerabiltyChartloading = false;
      })
      //vmTop5RiskApp
      .addCase(top5RiskAppApi.pending, (state, action) => {
        state.vmTop5RiskAppLoading = true;
      })
      .addCase(top5RiskAppApi.fulfilled, (state, action) => {
        state.vmTop5RiskAppData = action.payload;
        state.vmTop5RiskAppLoading = false;
      })
      .addCase(top5RiskAppApi.rejected, (state, action) => {
        state.vmTop5RiskAppLoading = false;
      })

      //TreeChartData
      .addCase(treeChartApiCall.pending, (state, action) => {
        state.treeChartDataLoading = true;
      })
      .addCase(treeChartApiCall.fulfilled, (state, action) => {
        state.treeChartData = action.payload;
        state.treeChartDataLoading = false;
      })
      .addCase(treeChartApiCall.rejected, (state, action) => {
        state.treeChartDataLoading = false;
      })
      //businessDivisionOption
      .addCase(getBusinessDepartmentAPI.pending, (state, action) => {
        state.businessDivisionOptionLoading = true;
      })
      .addCase(getBusinessDepartmentAPI.fulfilled, (state, action) => {
        state.businessDepartmentOption = action.payload;
        state.businessDivisionOptionLoading = false;
      })
      .addCase(getBusinessDepartmentAPI.rejected, (state, action) => {
        state.businessDivisionOptionLoading = false;
      })
      //businessDepartmentOption
      .addCase(getBusinessDivisionAPI.pending, (state, action) => {
        state.businessDepartmentOptionLoading = true;
      })
      .addCase(getBusinessDivisionAPI.fulfilled, (state, action) => {
        state.businessDivisionOption = action.payload;
        state.businessDepartmentOptionLoading = false;
      })
      .addCase(getBusinessDivisionAPI.rejected, (state, action) => {
        state.businessDepartmentOptionLoading = false;
      })
      //vmAppOption
      .addCase(getVMAppAPI.pending, (state, action) => {
        state.vmAppDataLoading = true;
      })
      .addCase(getVMAppAPI.fulfilled, (state, action) => {
        state.vmAppData = action.payload;
        state.vmAppDataLoading = false;
      })
      .addCase(getVMAppAPI.rejected, (state, action) => {
        state.vmAppDataLoading = false;
      })
      //getListForTargetDate
      .addCase(getListForTargetDate.pending, (state, action) => {
        state.getListForTargetDateLoading = true;
      })
      .addCase(getListForTargetDate.fulfilled, (state, action) => {
        state.getListForTargetDateData = action.payload;
        state.getListForTargetDateLoading = false;
      })
      .addCase(getListForTargetDate.rejected, (state, action) => {
        state.getListForTargetDateLoading = false;
      })
      // getTargetDateDueDrillDownList
      .addCase(getTargetDateDueDrillDownList.pending, (state, action) => {
        state.getTargetDateDueDrillDownListLoading = true;
      })
      .addCase(getTargetDateDueDrillDownList.fulfilled, (state, action) => {
        state.getTargetDateDueDrillDownListData = action.payload;
        state.getTargetDateDueDrillDownListLoading = false;
      })
      .addCase(getTargetDateDueDrillDownList.rejected, (state, action) => {
        state.getTargetDateDueDrillDownListLoading = false;
      })
      // getTargetDateDueDrillDownListCount
      .addCase(getTargetDateDueDrillDownListCount.pending, (state, action) => {
        state.getTargetDateDueDrillDownListCountLoading = true;
      })
      .addCase(getTargetDateDueDrillDownListCount.fulfilled, (state, action) => {
        state.getTargetDateDueDrillDownListCountData = action.payload;
        state.getTargetDateDueDrillDownListCountLoading = action.payload;
        state.vmCurrentListLoadingDrilldown = false;
      })
      .addCase(getTargetDateDueDrillDownListCount.rejected, (state, action) => {
        state.getTargetDateDueDrillDownListCountLoading = false;
      })
      //getCISOaccount
      .addCase(getCISOaccount.pending, (state, action) => {
        state.vmAccountDataLoading = true;
      })
      .addCase(getCISOaccount.fulfilled, (state, action) => {
        state.vmAccountData = action.payload;
        state.vmAccountDataLoading = false;
      })
      .addCase(getCISOaccount.rejected, (state, action) => {
        state.vmAccountDataLoading = false;
      })
      //getTargetDateListCount
      .addCase(getTargetDateListCount.pending, (state, action) => {
        state.getTargetDateListCountLoading = true;
      })
      .addCase(getTargetDateListCount.fulfilled, (state, action) => {
        state.getTargetDateListCountData = action.payload;
        state.getTargetDateListCountLoading = false;
      })
      .addCase(getTargetDateListCount.rejected, (state, action) => {
        state.getTargetDateListCountLoading = false;
      })
      //VulnerabilityManageTrend
      .addCase(getVulnmanageTrendAPI.pending, (state, action) => {
        state.VulnTrendDataLoading = true;
      })
      .addCase(getVulnmanageTrendAPI.fulfilled, (state, action) => {
        state.VulnTrendData = action.payload;
        state.VulnTrendDataLoading = false;
      })
      .addCase(getVulnmanageTrendAPI.rejected, (state, action) => {
        state.VulnTrendDataLoading = false;
      })
      //close vulnerability Distribution
      .addCase(getCloseVulnDistributionAPI.pending, (state, action) => {
        state.ClosedistributeDataLoading = true;
      })
      .addCase(getCloseVulnDistributionAPI.fulfilled, (state, action) => {
        state.closevulnerabilityData = action.payload;
        state.ClosedistributeDataLoading = false;
      })
      .addCase(getCloseVulnDistributionAPI.rejected, (state, action) => {
        state.ClosedistributeDataLoading = false;
      })
      //cvid Check Loading
      .addCase(getCvidCheck.pending, (state, action) => {
        state.cveIdCheckLoading = true;
      })
      .addCase(getCvidCheck.fulfilled, (state, action) => {
        state.cvidCheck = action.payload;
        state.cveIdCheckLoading = false;
      })
      .addCase(getCvidCheck.rejected, (state, action) => {
        state.cveIdCheckLoading = false;
      })
      //getTargetMissedItems
      .addCase(getTargetMissedItems.pending, (state, action) => {
        state.getTargetMissedItemsLoading = true;
      })
      .addCase(getTargetMissedItems.fulfilled, (state, action) => {
        state.getTargetMissedItemsData = action.payload;
        state.getTargetMissedItemsLoading = false;
      })
      .addCase(getTargetMissedItems.rejected, (state, action) => {
        state.getTargetMissedItemsLoading = false;
      })
      //Vulnerabilities By CVSS Severity
      .addCase(VMCVSSSeverity.pending, (state, action) => {
        state.VMCVSSSeverityLoading = true;
      })
      .addCase(VMCVSSSeverity.fulfilled, (state, action) => {
        state.VMCVSSSeverityData = action.payload;
        state.VMCVSSSeverityLoading = false;
      })
      .addCase(VMCVSSSeverity.rejected, (state, action) => {
        state.VMCVSSSeverityLoading = false;
      })

      //SecurityDetails
      .addCase(getSecurityDetails.pending, (state, action) => {
        state.securitydetailsLoading = true;
      })
      .addCase(getSecurityDetails.fulfilled, (state, action) => {
        state.secuirtyDetails = action.payload;
        state.securitydetailsLoading = false;
      })
      .addCase(getSecurityDetails.rejected, (state, action) => {
        state.securitydetailsLoading = false;
      })

      //VulnerabilityByAppAPI
      .addCase(VulnerabilityByAppAPI.pending, (state, action) => {
        state.VulnerabilityByAppLoading = true;
      })
      .addCase(VulnerabilityByAppAPI.fulfilled, (state, action) => {
        state.VulnerabilityByAppData = action.payload;
        state.VulnerabilityByAppLoading = false;
      })
      .addCase(VulnerabilityByAppAPI.rejected, (state, action) => {
        state.VulnerabilityByAppLoading = false;
      })

      //VulTargetDateDuAPI
      .addCase(VulTargetDateDuAPI.pending, (state, action) => {
        state.VulTargetDateDuLoading = true;
      })
      .addCase(VulTargetDateDuAPI.fulfilled, (state, action) => {
        state.VulTargetDateDuData = action.payload;
        state.VulTargetDateDuLoading = false;
      })
      .addCase(VulTargetDateDuAPI.rejected, (state, action) => {
        state.VulTargetDateDuLoading = false;
      })

      .addCase(ExportCSV.pending, (state, action) => {
        state.CSVloading = true;
      })
      .addCase(ExportCSV.fulfilled, (state, action) => {
        state.CSVDetails = action.payload;
        state.CSVloading = false;
      })
      .addCase(ExportCSV.rejected, (state, action) => {
        state.CSVloading = false;
      })
  },
});

export const { resetVulnerabilityData, setDisabledCategories } = vulnerabilitiesSlice.actions;
export default vulnerabilitiesSlice.reducer;
