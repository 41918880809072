import React, { useEffect, useState, useCallback } from "react";
import Option from "./Option";
import "./select.styles.scss";
import Control from "./Control";
import ValueContainer from "./ValueContainer";
import Menu from "./Menu";
import MenuList from "./MenuList";
import DropdownIndicator from "./DropdownIndicator";
import IndicatorsContainer from "./IndicatorsContainer";
import { useDetectClickOutside } from "react-detect-click-outside";
import _ from "lodash";
import BaseCustomSelect  from "../Dropdown/CustomSelect";
const CustomSelect = (props) => {
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [initialLoad, setIntialLoad] = useState(true);
  const [searchText ,setSearchText] = useState("");
  const ref = useDetectClickOutside({
    onTriggered: () => setIsMenuOpen(false)
  });

  useEffect(() => {
    setFilteredOptions(props?.options?.length ? props?.options : []);
  }, [props.options]);
  const getCustomComponents = () => {
    let components = {};
    if (props.isCheckboxSelect) {
      components = {
        Control,
        IndicatorsContainer,
        IndicatorSeparator: () => null,
        ValueContainer,
        Menu,
        MenuList,
        Option,
        DropdownIndicator,
      };
    }

    return components;
  };
  const sortOptions = (options) => {
    return [...options].sort((a, b) => a.label.localeCompare(b.label));
  };

  const filterValues = (searchText) => {
    const options = _.get(props, "options", []);
    const safeSearchText = (searchText || "").toLowerCase();  
    return options.filter(option => 
      typeof option.label === 'string' && option.label.toLowerCase().includes(safeSearchText)
    );
  };
  
  const getFilteredOptions = () => {
    const opts = filterValues(searchText);
     return filterValues(searchText);  
  };

  const onSearch = (searchText) => {
    setSearchText(searchText);
    const filteredOpts = filterValues(searchText);
    setFilteredOptions(filteredOpts);
  }

  const toggleMenu = useCallback(
    (status) => {
      if (status) {
        setIsMenuOpen(status);
      } else {
        setIsMenuOpen((state) => {
          return !state;
        });
      }
      setSearchText("");
    },
    [isMenuOpen]
  );
  const handleBlur = () => {
    setIsMenuOpen(false);
  };
  const handleChange = (selectedOption) => {
    if (props.isRadioOption) {
      props.onChange(selectedOption ? [selectedOption] : []);
    } else {
      props.onChange(selectedOption);
    }
  };
  const normalizeValue = (val) => {
    if (!val) return [];
    // If the value is already an array of objects with { value, label }
    if (Array.isArray(val) && val.every(item => item.value && item.label)) {
      return val;
    }
    // If the value is a single string like 'Option 1'
    if (typeof val === "string") {
      const foundOption = options.find(option => option.label === val || option.value === val);
      return foundOption ? [foundOption] : [];
    }
    // If the value is a single object { value: 'option1', label: 'Option 1' }
    if (val.value && val.label) {
      return [val];
    }
    return [];
  };
  return (
    <div ref={ref}>
      <BaseCustomSelect
        searchable={true}
        options={props?.options}
        onChange={handleChange}
        maxSelection={props.maxSelection ?? Infinity}
        defaultSelected={normalizeValue(props?.value)}
        placeholder={props?.placeholder}
        multiSelect={props?.isMulti}
        withSelectAll={props?.withSelectAll}
        selectContainerClassName={props?.className}
        selectvalueClassName={props?.valueClassName}
      />
    </div>
  );
};

export default CustomSelect;
