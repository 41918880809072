import React, { useState, useEffect } from "react";
import { iosClose } from "../../../assets/images";
import { BsEye, BsEyeSlash, BsFillInfoCircleFill } from "react-icons/bs";
import { IoCloseCircleOutline } from "react-icons/io5";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import eye from '../../auth/image/eye.svg';
import eyeslash from "../../auth/image/eyeslash.svg"
import Warning from '../../auth/image/Warning.svg'
import "./styles.scss";

const Input = (props) => {
  let {
    label,
    error,
    errorMessage,
    value,
    type,
    name,
    placeholder,
    onChange,
    containerClassName,
    labelClassName,
    errorClassName,
    inputClassName,
    autoComplete,
    maxLength,
    disabled,
    onKeyPress,
    customlabelClassName,
    id,
    readOnly,
    showTooltip,
    endIcon,
    tooltipText,
    minDate,
    endIconImage
  } = props;
  const [inputType, setInputType] = useState(type);
  function allowAlphaNumericWithSpace(e) {
    const code = "charCode" in e ? e.charCode : e.keyCode;
    if (e.which === 32) {
      e.preventDefault();
    }
    if (
      !(code > 47 && code < 58) && // numeric (0-9)
      !(code > 64 && code < 91) && // upper alpha (A-Z)
      !(code > 96 && code < 123) && // lower alpha (a-z)
      !(code === 95)
    ) {
      // remove space !(code == 32) &&
      e.preventDefault();
    }
  }

  useEffect(() => {
    setInputType(type);
  }, [type]);
  const parentClass =
    type == "password"||"Warning"
      ? props.parentDivClassName
        ? props.parentDivClassName
        : "input-wrapper"
      : endIcon === true
      ? "input-wrapper"
      : "";
  inputClassName = error ? "errorinput form-control" : inputClassName;
  return (
    <>
      <div
        className={
          containerClassName
            ? containerClassName
            : "frmgroup validate default-input col-6"
        }
        id={id}
      >
        <div className={`frmhead ${labelClassName}`}>
          {label && <label className={customlabelClassName}>{label}</label>}
          {showTooltip && (
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip className="tooltipHeader">{tooltipText}</Tooltip>
              }
            >
              <span>
                <BsFillInfoCircleFill />
              </span>
            </OverlayTrigger>
          )}
          {error && (
            <div className={`error ${errorClassName}`}>{errorMessage}</div>
          )}
        </div>
        {/* {onkeypress ? (
          <input
          
            type={type}
            name={name}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            className={inputClassName}
            disabled={disabled}
            autoComplete={autoComplete}
            maxLength={maxLength}
            onKeyPress={(e) => allowAlphaNumericWithSpace(e)}
            readOnly={readOnly}
          />
        ) : ( */}
        <div className={parentClass}>
          <input
            type={inputType}
            name={name}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            className={inputClassName}
            disabled={disabled}
            onKeyPress={onKeyPress}
            autoComplete={autoComplete}
            maxLength={maxLength}
            readOnly={readOnly}
            min={minDate}
            style={{
              paddingRight:
                props.showRemoveButton || type == "password" ? "40px" : "auto",
            }}
          />
          {props.showRemoveButton && (
            <a
              className="remove-button-business"
              onClick={() => props.handleRemoveField(props.index)}
            >
              <IoCloseCircleOutline />
            </a>
          )}
           {inputType == "Warning" && (
            <span className="input-warning">
               <img src={Warning} />
            </span>
          )}
          {inputType == "password" && (
            <a
              className="remove-button-business eyeIcon"
              onClick={() => {
                console.log("set icon");
                setInputType("text");
              }}
            >
               <img src={eye} />
            </a>
          )}
          {type == "password" && inputType == "text" && (
            <a
              className="remove-button-business eyeIcon"
              onClick={() => setInputType("password")}
            >
              <img src={eyeslash} />
            </a>
          )}
          {endIconImage && (
          endIconImage
        )}
        </div>
        {/* )} */}
      </div>
    </>
  );
};

export default Input;
