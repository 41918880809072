import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Offcanvas from 'react-bootstrap/Offcanvas';
import Button from "./Button";
import { hideOffCanvas, setSelectedLevelOne } from "../commonSlice";
import PropTypes from "prop-types";
import "./features.common.styles.scss";
const CustomOffCanvas = (props) => {
  const dispatch = useDispatch();
  const isOffCanvasOpen = useSelector((state) => state.common?.offcanvasState?.isDrilldownOpen)
  const onCancel = () => {
    if(props.onCancel) {
      props.onCancel();
    }
  }

  return (
    <Offcanvas
      placement="end"
      className={props.className}
      show={isOffCanvasOpen}
    >
      <Button
        className="btn-close text-reset"
        text="×"
        onClick={() => {
          dispatch(setSelectedLevelOne(""))
          dispatch(hideOffCanvas(false))
          onCancel()
          }}
      />
      <div className="custom_scroll offcanvasPadding offcanvas-map-new">
        {props?.children}
      </div>
    </Offcanvas>
  );
};

CustomOffCanvas.propTypes = {
  onCancel: PropTypes.bool,
  className: PropTypes.arrayOf(PropTypes.object).isRequired,
  children: PropTypes.arrayOf(PropTypes.object).isRequired,  
};

export default CustomOffCanvas;
