import React from "react";
import PropTypes from "prop-types";
const Button = ({ onClick, text, className }) => {
    return (
        <button
          className={className ? className : `solid-btn`}
          onClick={(e) => onClick(e)}
        >
          {text}
        </button>
    )
}
Button.propTypes = {
  onClick: PropTypes.bool,
  text: PropTypes.arrayOf(PropTypes.object).isRequired,
  className: PropTypes.arrayOf(PropTypes.object).isRequired,  
};
export default Button;