import React, { useEffect, useRef, useState } from 'react';
import { useTable, useGlobalFilter, useSortBy, usePagination } from 'react-table';
import { FaSortDown, FaSortUp, FaSort, FaSearch } from 'react-icons/fa';
import CustomSelectSearch from '../../../app/common-components/custom-select-with-search';
import { Button, Col, Row } from 'react-bootstrap';
import {actionPlanFilterInt}from "../../../app/common-components/constant"
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { setCompleteTableLimit, setCompleteTablePageNo, setCompleteTableFilterKey, setCompleteTableSearchKey } from '../../../app/commonSlice';
import { get } from 'lodash';
import CustomSelect from '../../../app/common-components/select';
import _ from 'lodash';
 import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import ShowTooltip from '../Tooltip/showToolTip';
import { checkColumn,PaginationInfo } from './Helpers';
import ButtonComponent from '../../../app/common-components/ButtonComponent';
import MoreFilter from '../../../app/common-components/CustomMoreFilter/moreFilter';
import Spinners from '../../../app/common-components/Spinner';
import CustomSelectDropDown from '../CustomSelectDropDown';
import './style.scss';
function CompleteTableData({ customSearchClass, DropData, moveFilterToStart, moveSearchToEnd, onScroll, data, issearch, columns, title, isLoading, isMailRequired, pageNo, pageLimit, onSorting, headerSortingState, onPageLimitChange, listCount, onPageNoChange, curentPageNumber, onSearch, searchKey, btnData, filtersToShow, showFilter, selectedFilterValues, tbodyClass, onFilter, isTableFilterDisabled, additionalBtnData, scrollClass, filterOptionToHide, ismore, optionList, handleOptionClick, selectedOption, isApply, filterclear, requiredOptions, isAllEmpty, highlightRow, selectedRow, isNotShowMoreFilter, isSlNoRequired, manageBtnData, removeHoveringEffect, grid, disableSorting, numberOfColumnsToFix, UARSearch, removePosition,placement }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    page,
    prepareRow,
    state,
    setGlobalFilter,
    canPreviousPage,
    canNextPage,
    previousPage,
    nextPage,
    pageOptions,
    gotoPage,
    setPageSize,

  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 }
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const findingColumns = columns?.filter(item => item.hasOwnProperty('columns'));
  const [pageInput, setPageInput] = useState(curentPageNumber + 1);

  const sizeOfPage = pageLimit !== undefined ? pageLimit : 10;

  const [pageSizeInput, setPageSizeInput] = useState(sizeOfPage);

  const dispatch = useDispatch();
  const { globalFilter, pageIndex, pageSize } = state;
  const [currentPage, setCurrentPage] = useState(0);
  const currentPageNumber = state.pageIndex + 1;
  const commonSliceState = useSelector((state) => state.common);
  const [searchInputValue, setSearchInputValue] = useState(searchKey ? searchKey : '');
  const pageLength = get(commonSliceState, "completeTable.pageCount", []);
  const activeKey = get(commonSliceState, "completeTable.activeKey", []);
  const [stickyLeftOffsets, setStickyLeftOffsets] = useState([]);
  const headerRefs = useRef([]);

  const totalPageCount = listCount
    ? Math.ceil(listCount / pageLimit)
    : 0;
  const isLastPage = curentPageNumber === totalPageCount - 1;


  useEffect(() => {
    const offsets = [];
    let currentOffset = 0;
    headerRefs.current.forEach((ref, index) => {
      if (index < numberOfColumnsToFix) { // Assuming you want the first two columns to be sticky
        offsets.push(currentOffset);
        currentOffset += ref.offsetWidth;
      }
    });
    setStickyLeftOffsets(offsets);
  }, [columns]); // Add dependencies as needed, e.g., if your columns/data can change


  const handleInputChange = (e) => {
    const newValue = e.target.value;
    setSearchInputValue(newValue);
    if (e.target.value === '') {
      if (newValue !== searchKey) {
        onSearch(newValue);
      }
    }
  };

  const handleSearchClick = () => {
    const searchValue = searchInputValue;
    onSearch(searchInputValue)
    setGlobalFilter(searchValue);
    setCurrentPage(0);
    dispatch(setCompleteTablePageNo(0));
    dispatch(setCompleteTableSearchKey(searchInputValue));
    gotoPage(0);
  };
  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && searchInputValue.length > 1) {
      handleSearchClick();
    }
  };

  const handlePreviousClick = () => {
    if (curentPageNumber > 0) {
      const newPageNumber = curentPageNumber - 1;
      // dispatch(setCompleteTablePageNo(newPageNumber));
      setCurrentPage(newPageNumber);
      previousPage();
      onPageNoChange(newPageNumber)
      setPageInput(pageInput)
    }
  };

  const handleNextClick = () => {
    const newPageNumber = curentPageNumber + 1;
    setCurrentPage(newPageNumber);
    nextPage();
    onPageNoChange(newPageNumber);
    setPageInput(pageInput)
  };
  useEffect(() => {
    const checkAllCheckbox = document.querySelector('input[name="checkAll"]');
    if (checkAllCheckbox) {
      checkAllCheckbox.checked = false;
    }
  }, []);
  useEffect(() => {
    if (searchInputValue === "") {
      dispatch(setCompleteTableSearchKey(""));
    }
  }, [searchInputValue]);

  useEffect(() => {
    setSearchInputValue(searchKey)
  }, [searchKey]);

  // useEffect(() => {
  //   setSearchInputValue("")
  //   dispatch(setCompleteTablePageNo(0));
  //   setCurrentPage(0);
  //   dispatch(setCompleteTableSearchKey(""));
  //   dispatch(setCompleteTableLimit(10));
  // }, [activeKey]);


  const getFilterOptions = () => {
    let returnObj = _.map(filtersToShow, obj => {
      if (obj.key == filterOptionToHide) {
        return null;
      }
      return (
        <div className='srvrTableFilters'><CustomSelect
          withSelectAll={true}
          className="tableCustomSelect"
          options={obj.options}
          isMulti
          hideSelectedOptions={false}
          isCheckboxSelect={true}
          placeholder={obj.placeholder}
          isSearchable={true}
          closeMenuOnSelect={false}
          defaultValue={obj.defaultValue}
          onChange={(e) => obj.changeFunc(e, obj.key)}
          value={obj.value}
          searchPlaceHolder={obj.searchPlaceHolder}
        /></div>)
    })
    return returnObj;
  }


  const getPageNumbers = () => {
    const totalPages = totalPageCount;
    const currentPage = curentPageNumber + 1;
    const pageNeighbours = 2;
    const ellipsis = "...";
    const startPage = Math.max(1, currentPage - pageNeighbours);
    const endPage = Math.min(totalPages, currentPage + pageNeighbours);
    const pages = [];
    // Add the first page
    if (startPage > 1) {
      pages.push(
        <button
          key={1}
          className={`btn btn-sm ${currentPage === 1 ? 'page-link btn-primary' : 'page-link btn-light'}`}
          onClick={() => handlePageNumberClick(0)}
        >
          1
        </button>
      );
      if (startPage > 2) {
        pages.push(
          <button
            key="startEllipsis"
            className={`btn btn-sm page-link btn-light`}
            onClick={() => gotoPage(startPage - 2)}
          >
            {ellipsis}
          </button>
        );
      }
    }
    // Add the page numbers
    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <button
          key={i}
          className={`btn btn-sm ${currentPage === i ? 'page-link btn-primary ' : 'page-link btn-light  '}`}
          onClick={() => handlePageNumberClick(i - 1)}
        >
          {i}
        </button>
      );
    }
    // Add the last page
    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        pages.push(
          <button
            key="endEllipsis"
            className={`btn btn-sm page-link btn-light`}
            onClick={() => gotoPage(endPage)}
          >
            {ellipsis}
          </button>
        );
      }
      pages.push(
        <button
          key={totalPages}
          className={`btn btn-sm ${currentPage === totalPages ? 'btn-primary h-100 px-3 page-link' : 'btn-light h-100 page-link'}`}
          onClick={() => handlePageNumberClick(totalPages - 1)}
        >
          {totalPages}
        </button>
      );
    }
    return pages;
  };
  const calculateRowSpan = (headerGroups, columnIndex) => {
    let rowSpan = 1;
    for (let i = 1; i < headerGroups.length; i++) {
      const currentHeaderGroup = headerGroups[i];
      const currentHeader = currentHeaderGroup.headers[columnIndex];
      if (currentHeader.rowSpan === 0) {
        rowSpan++;
      } else {
        break;
      }
    }
    return rowSpan;
  };

  // Render the table headers with rowspan
  const renderTableHeaders = (headerGroups) => {
    const renderedHeaders = [];
    for (let i = 0; i < headerGroups[0].headers.length; i++) {
      const column = headerGroups[0].headers[i];
      const rowSpan = calculateRowSpan(headerGroups, i);

      if (rowSpan > 0) {
        renderedHeaders.push(
          <th
            {...column.getHeaderProps()}
            colSpan={column.columns ? column.columns.length : 1}
            rowSpan={rowSpan}
          >
            {column.render('Header')}
          </th>
        );
      }
    }
    return renderedHeaders;
  };
  const handlePageSizeChange = (newPageSize) => {
    // onPageNoChange(0)
    gotoPage(0);
    onPageLimitChange(newPageSize);

  };
  const handlePageNumberClick = (pageNumber) => {
    if (pageNumber !== curentPageNumber) {
      onPageNoChange(pageNumber);
      gotoPage(pageNumber);
    }
  }

  const handleInputChangePage = (e) => {
    if (e.target.value <= totalPageCount)
      setPageInput(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      const newPage = parseInt(e.target.value, 10);
      if (!isNaN(newPage) && newPage > 0 && newPage <= totalPageCount) {
        gotoPage(newPage - 1);
        onPageNoChange(newPage - 1);
      } else {
        setPageInput(curentPageNumber + 1);
      }
    }
  };

  const handleBlurPageInput = () => {
    if (pageInput < curentPageNumber + 1) {
      setPageInput(curentPageNumber + 1);
    }
  };

  useEffect(() => {
    setPageInput(curentPageNumber + 1);
  }, [curentPageNumber]);

  const handleInputChangePageSize = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value) && Number(value) <= 100) {
      setPageSizeInput(value);
    }
  };


  const handleKeyPressPagesize = (e) => {
    if (e.key === 'Enter') {
      const newSize = Number(pageSizeInput);
      if (newSize >= 1 && newSize <= 100) {
        setPageSize(newSize);
        handlePageSizeChange(newSize);
        setCurrentPage(0);
        gotoPage(0);
      }
    }
  };

  useEffect(() => {
    setPageSizeInput(sizeOfPage);
  }, [pageLimit, sizeOfPage]);

  const handleBlurPageSize = () => {
    const newSize = Number(pageSizeInput);
    if (!newSize || newSize < 1 || newSize > 100) {
      setPageSizeInput(sizeOfPage);
    } else {
      setPageSize(newSize);
      handlePageSizeChange(newSize);
    }
  };
  const handleFirstPageClick = () => {
    setPageInput(1);
    setCurrentPage(0);
    gotoPage(0);
    onPageNoChange(0);
  };
  const handleLastPageClick = () => {
    setPageInput(totalPageCount);
    setCurrentPage(totalPageCount - 1);
    gotoPage(totalPageCount - 1);
    onPageNoChange(totalPageCount - 1);
  };

  const removeSortButton = () => {
    const tableRemoveSort = document.querySelector(".table-hover");

    if (tableRemoveSort) {
      const tableHeaders = tableRemoveSort.querySelectorAll("th");

      tableHeaders.forEach((header) => {


        const spanElement = header.querySelector("span");

        if (spanElement && !header.hasAttribute("title")) {
          spanElement.style.display = "none";
        }

        // if (header && !header.hasAttribute("title")) {
        //   header.removeChild(spanElement);
        // }
      });
    }
  }

  const handleOnSorting = (column, headerSortingState) => {
    // Check if column.parent is defined
    if (column.parent && column.parent.columns) {
      // Find the object in column.parent.columns that matches column.header
      const matchingColumn = column.parent.columns.find(col => col.Header === column.Header);
      // Check if a matching column object was found
      if (matchingColumn) {
        onSorting(matchingColumn, headerSortingState);
      } else {
        console.log('No matching column found');
      }
    } else {
      onSorting(column, headerSortingState);
    }
  }

  const renderSearch = () => {
    if (!issearch) {
      return (
        <li className={`input-group serverInput searchStyle manageVulStyle searchmargin ${customSearchClass}`}>
          <input
            id="searchInput"
            type="text"
            className="form-control search-input rounded-start"
            placeholder="Search"
            value={searchInputValue}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
          />
          <button
            className="btn search-btn"
            onClick={() => {
              if (searchInputValue) {
                handleSearchClick();
              }
            }}
          >
            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10.0168 1.71622C7.7285 -0.572073 4.00414 -0.572073 1.71585 1.71622C-0.571949 4.00501 -0.571949 7.72888 1.71585 10.0177C3.75363 12.055 6.92738 12.2732 9.21418 10.6824C9.2623 10.9101 9.37242 11.1273 9.54951 11.3044L12.882 14.6369C12.9971 14.7521 13.1338 14.8436 13.2842 14.9059C13.4346 14.9683 13.5959 15.0004 13.7588 15.0004C13.9216 15.0004 14.0829 14.9683 14.2333 14.9059C14.3838 14.8436 14.5205 14.7521 14.6355 14.6369C14.7509 14.5219 14.8424 14.3852 14.9049 14.2348C14.9673 14.0843 14.9995 13.923 14.9995 13.7601C14.9995 13.5972 14.9673 13.4359 14.9049 13.2855C14.8424 13.135 14.7509 12.9984 14.6355 12.8834L11.3031 9.54989C11.1326 9.38059 10.9167 9.26444 10.6815 9.21555C12.2733 6.92825 12.0551 3.755 10.0168 1.71622ZM8.96467 8.96554C7.25626 10.6739 4.47588 10.6739 2.76797 8.96554C1.06056 7.25713 1.06056 4.47725 2.76797 2.76884C4.47588 1.06093 7.25626 1.06093 8.96467 2.76884C10.6731 4.47725 10.6731 7.25713 8.96467 8.96554Z" fill="#595959" />
            </svg>
          </button>
        </li>
      );
    }
    return null;
  };


  const renderButtons = () => {
    if (btnData) {
      return btnData.map((btn, index) => (
        <li className="manageVulStyle" key={index}>
          <a className={`nav-link p-0 statusFilter ${btn?.ContainerClassName || ""}`}>
            {btn.btnComponent ? (
              btn.btnComponent
            ) : (
              <Button
                className={btn.className}
                onClick={btn.function}
                disabled={btn.disabled || false}
              >
                {btn.text}
              </Button>
            )}
          </a>
        </li>
      ));
    }
    return null;
  };
  const renderDropdown = () => {
    if (DropData) {
      return DropData.map((btn, index) => (
       <ul>
         <li className="" key={index}>
          {btn.btnComponent && (
            btn.btnComponent
          )}
        </li>
       </ul>
      ));
    }
    return null;
  };

  return (
    <>
      <div className='justify-content-between tableStyle'>
        
        <div className="Table-Header">

          <div className="">
            <div>{renderDropdown()}</div>
          </div>

          <div className="">
            <ul className={`checkStyle tableBtn ${UARSearch ? UARSearch : ""}`}>
              {moveSearchToEnd ? renderSearch() : renderButtons()}
              {moveSearchToEnd ? renderButtons() : renderSearch()}
              {additionalBtnData
                ? additionalBtnData.map((btn) => (
                  <li className="manageVulStyle">{btn.btnComponent}</li>
                ))
                : null}
              {ismore && (
                <li className="manageVulStyle">
                  <MoreFilter
                    name={"More Fields"}
                    optionList={optionList}
                    handleOptionClick={handleOptionClick}
                    selectedOptionlist={selectedOption}
                    tooltip={true}
                    tooltipText={"Click here for more fields in the table below."}
                    disabled={isTableFilterDisabled}
                  />
                  {isMailRequired && (
                    <>
                      <li className="nav-item mx-2">
                        <a
                          className="nav-link invisible"
                          style={{ padding: "5px 0px 0px 11px" }}
                        >
                          <CustomSelectSearch
                            options={actionPlanFilterInt}
                            defaultValue={actionPlanFilterInt[0]}
                            placeholder="Select Action Plan"
                            className="text-nowrap"
                            name="Action Plan"
                          />
                        </a>
                      </li>
                      <li className="nav-item mx-2">
                        <a
                          className="nav-link invisible"
                          style={{ padding: "5px 0px 0px 11px" }}
                        >
                          <Button className={`text-nowrap manageEnabled`}>
                            Manage Vulnerability
                          </Button>
                        </a>
                      </li>
                    </>
                  )}
                </li>
              )}
              {manageBtnData
                ? manageBtnData.map((btn, index) => (
                  <li className="manageVulStyle">
                    <a className={`nav-link p-0 statusFilter ${btn.ContainerClassName}`}>
                      {btn.btnComponent ? (
                        btn.btnComponent
                      ) : (
                        <Button
                          className={btn.className}
                          onClick={btn.function}
                          disabled={btn.disabled ? btn.disabled : false}
                        >
                          {btn.text}
                        </Button>
                      )}
                    </a>
                  </li>
                ))
                : null}
            </ul>
          </div>
        </div>


        {showFilter && (
          <div className={`tableFilters d-flex justify-content-end mb-2 ${moveFilterToStart && "filter_to_left"}`}>
            <Row className='mx-1'>
              <Col>
                <div className="d-flex float-right">
                  {getFilterOptions()}
                </div>
              </Col>
              <Col>
                <div className="d-flex justify-content-end">
                  <ButtonComponent
                    text="Filter"
                    className="detailsFilterStyle"
                    disabled={isTableFilterDisabled}
                    onClick={onFilter}
                  />
                </div>
              </Col>
            </Row>
          </div>
        )}
        {ismore ? (
          <div className="tableFilters d-flex justify-content-end mb-2">
            <Row className='mx-1'>
              <Col className='padForFilter'>
                <div className="d-flex float-right">
                  {getFilterOptions()}
                </div>
              </Col>
              <Col className='padForFilter marForFilter'>
                <div className="d-flex justify-content-end">
                  <>
                    {isNotShowMoreFilter &&
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip >
                            {"Click here for more filters"}
                          </Tooltip>
                        }
                      >
                        <span className="d-inline-block" tabIndex="0">
                          <ButtonComponent
                            text="More Filters"
                            className="detailsFilterStyle mx-1"
                            onClick={isApply && isApply}
                            disabled={requiredOptions}
                          />
                        </span>
                      </OverlayTrigger>
                    }
                    {/* <div>
                      <ButtonComponent
                        text="Clear"
                        className="clearfilterStyle"
                        onClick={filterclear}
                        disabled={isTableFilterDisabled || isAllEmpty}
                      />
                      <ButtonComponent
                        text="Filter"
                        className="defaultfilterStyle"
                        onClick={onFilter}
                        disabled={isTableFilterDisabled}
                      />
                    </div> */}
                    {/* <MoreFilter
                      name={"More Fields"}
                      optionList={optionList}
                      handleOptionClick={handleOptionClick}
                      selectedOptionlist={selectedOption}
                      tooltip={true}
                      tooltipText={"Click here for more fields in the table below."}
                      disabled={isTableFilterDisabled}
                    /> */}
                  </>
                </div>
              </Col>
            </Row>
          </div>
        ) : (null)}
        <div onScroll={onScroll} className={`table-responsive custom_scroll w-100 bg-white ${scrollClass ? scrollClass : ''}`}>
          <table {...getTableProps()} className={`custom_table_vul ${removeHoveringEffect ? "" : "table-hover"} table serverTable mb-0`}>
            <thead style={ removePosition ? null : {position: 'sticky', top: 0,textAlign:"center" }}>
            {headerGroups.map((headerGroup) => (
  <tr {...headerGroup.getHeaderGroupProps()}>
    {isSlNoRequired && <th className="th-serverTable-border Class_Center" style={{
    backgroundColor: "rgb(220, 223, 221)",
    margin: "0px",
    paddingTop: "12px",
    paddingBottom: "12px",
    cursor: "default",
    borderRight: "1px solid #c0c0c0"
    }}>Sl.No</th>}
    {headerGroup.headers.map((column, i) => {
      return (
        <OverlayTrigger
          key={i}
          placement={placement? placement : "top"}
          overlay={
            column.isTooltip ? (
              <Tooltip className="tooltipHeader">{column.isTooltip}</Tooltip>
            ) : (
              <span />
            )
          }
        >
          <th
            {...column.getHeaderProps()}
            ref={(el) => (headerRefs.current[i] = el)}
            colSpan={column.columns ? column.columns.length : 1}
            title=""
            style={{
              position: i < numberOfColumnsToFix ? "sticky" : undefined,
              left: i < numberOfColumnsToFix ? `${stickyLeftOffsets[i]}px` : undefined,
              zIndex: i < numberOfColumnsToFix ? 1000 : undefined,
              backgroundColor: "#dcdfdd",
              margin: "0px",
              paddingTop: "12px",
              paddingBottom: "12px",
              ...column.thHeaderStyle,
              cursor: !column.disableSortBy && headerSortingState ? "pointer" : "default",
            }}
            className={`${column.thClassName} th-serverTable-border align-middle ${
              typeof column.render("Header") === "object" ? column.className || "noSort" : ""
            } ${i < numberOfColumnsToFix ? "stickyColumnHeader" : "nonStickyColumnHeader"} ${
              i <= numberOfColumnsToFix ? "sticky-left-border" : "non-sticky-left-border"
            }`}
            {...(column.columns ? removeSortButton() : "")}
          >
            <div
              onClick={() =>
                !column.disableSortBy &&
                !disableSorting &&
                onSorting &&
                handleOnSorting(column, headerSortingState)
              }
            >
              {column.render("Header")}
              {!column.disableSortBy && !disableSorting && (
                <span>
                  {column.isSorted || column.id === headerSortingState?.accessor ? (
                    column.isSortedDesc || headerSortingState?.isDataSorted === "asc" ? (
                      <FaSortUp className="sorted-icon" />
                    ) : (
                      <FaSortDown className="sorted-icon" />
                    )
                  ) : (
                    <FaSort className="sort-icon sort-desc" />
                  )}
                </span>
              )}
            </div>
          </th>
        </OverlayTrigger>
      );
    })}
  </tr>
))}

            </thead>


            <tbody {...getTableBodyProps()} style={{ verticalAlign: 'middle' }} className={tbodyClass}>
              {
                isLoading ? (
                  <tr>
                    <td colSpan={headerGroups.reduce((count, headerGroup) => count + headerGroup.headers.length, 0) + (isSlNoRequired ? 1 : 0)} className="projectemty text-center p-0">
                      <Spinners />
                    </td>
                  </tr>
                ) :
                  rows.length !== 0 ? (
                    rows.map((row, index) => {
                      prepareRow(row);
                      const serialNumber = curentPageNumber * pageLimit + index + 1;
                      return (
                        <tr {...row.getRowProps()} key={index}>
                          {isSlNoRequired && <td className='text-center th-serverTable-border'>{serialNumber}</td>}
                          {row.cells.map((cell, i) => {
                            return (
                              <td {...cell.getCellProps()} className={(highlightRow && _.isEqual(selectedRow, row.original)) ? `${cell.column.tdClassName} th-serverTable-border highlightRow` : `${cell.column.tdClassName} th-serverTable-border ${i < numberOfColumnsToFix ? "stickyColumnCell" : "nonStickyColumnCell"} ${i <= numberOfColumnsToFix ? "sticky-left-border-cell" : "non-sticky-left-border-cell"}`}
                                style={{
                                  position: i < numberOfColumnsToFix ? 'sticky' : undefined,
                                  left: i < numberOfColumnsToFix ? `${stickyLeftOffsets[i]}px` : undefined,
                                  backgroundColor: "#fff",
                                  ...cell.column.tdHeaderStyle
                                }}>
                                <div>{cell.render('Cell')}</div>
                              </td>
                            );
                          })}

                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={headerGroups.reduce((count, headerGroup) => count + headerGroup.headers.length, 0) + (isSlNoRequired ? 1 : 0)} className="projectemty text-center">
                        No Data Available
                      </td>
                    </tr>
                  )}
            </tbody>

          </table>
        </div>
        {listCount > 0 && (
          <div className="d-flex justify-content-between  mt-3  mb-0" style={{ alignItems: 'center' }}>
            {!isLoading && <div>
              <PaginationInfo
                currentPage={curentPageNumber}
                pageSize={sizeOfPage}
                listCount={listCount}
              />
            </div>}

            {!isLoading && (
              <div className="d-flex justify-content-center">
                <div className="d-flex align-items-center">

                  <div class="pe-3">
                    <button
                      onClick={handleFirstPageClick}
                      className={`far-back-icon ${pageInput === 1 ? 'disabled-icon' : ''}`}
                      disabled={curentPageNumber === 0}
                      style={{ cursor: pageInput === totalPageCount ? 'default' : 'pointer' }}
                    >
                      <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.400819 0.00162569H2.44586C2.55434 0.00162569 2.65837 0.0447184 2.73507 0.121424C2.81178 0.19813 2.85487 0.302165 2.85487 0.410643V3.73595L8.35603 0.0547979C8.4182 0.0188992 8.48874 0 8.56053 0C8.63233 0 8.70286 0.0188992 8.76504 0.0547979C8.83127 0.0881475 8.88718 0.138876 8.92681 0.201555C8.96643 0.264235 8.98827 0.336508 8.98999 0.410643V8.59098C8.98979 8.66498 8.96953 8.73753 8.93135 8.80091C8.89318 8.8643 8.83853 8.91614 8.77322 8.95092C8.70926 8.98376 8.63777 8.99916 8.56597 8.99557C8.49416 8.99198 8.42457 8.96952 8.36421 8.93047L2.86305 5.24931V8.59098C2.86305 8.69946 2.81996 8.8035 2.74325 8.8802C2.66655 8.95691 2.56252 9 2.45404 9H0.408998C0.300523 9 0.19649 8.95691 0.119786 8.8802C0.0430822 8.8035 -9.53674e-06 8.69946 -9.53674e-06 8.59098V0.410643C-3.05176e-05 0.303567 0.0419369 0.200753 0.11688 0.124278C0.191824 0.0478035 0.293767 0.00376719 0.400819 0.00162569Z" fill="#939393" />
                      </svg>
                    </button>
                  </div>

                  <div class="pe-3">
                    <button
                      className={`far-back-icon ${pageInput === 1 ? 'disabled-icon' : ''}`}
                      onClick={handlePreviousClick}
                      disabled={curentPageNumber === 0}
                      style={{ cursor: pageInput === totalPageCount ? 'default' : 'pointer' }}
                    >
                      <svg width="13" height="9" viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.28873 3.68394L6.1982 0.0838961C6.28792 0.0298853 6.39012 0.000947745 6.49442 2.28866e-05C6.59872 -0.000901972 6.70141 0.0262187 6.79204 0.0786304C6.88268 0.131042 6.95803 0.206877 7.01046 0.298431C7.06289 0.389986 7.09051 0.493998 7.09053 0.599902V3.13793L12.1077 0.0838961C12.1974 0.0298853 12.2996 0.000947745 12.4039 2.28866e-05C12.5082 -0.000901972 12.6109 0.0262187 12.7015 0.0786304C12.7921 0.131042 12.8675 0.206877 12.9199 0.298431C12.9724 0.389986 13 0.493998 13 0.599902V8.39999C13.0002 8.51017 12.9706 8.61828 12.9143 8.71248C12.858 8.80667 12.7772 8.88331 12.6809 8.93399C12.5968 8.97775 12.5036 9.00038 12.4091 9C12.2886 8.99971 12.171 8.96202 12.0722 8.89199L7.09053 5.35195V8.39999C7.09075 8.51017 7.0611 8.61828 7.00481 8.71248C6.94852 8.80667 6.86777 8.88331 6.77142 8.93399C6.68731 8.97775 6.59409 9.00038 6.49958 9C6.37908 8.99971 6.26155 8.96202 6.16274 8.89199L0.253273 4.69195C0.172163 4.63461 0.106513 4.55753 0.0623312 4.46776C0.0181503 4.378 -0.00315189 4.27841 0.000375748 4.17813C0.00390339 4.07784 0.0321474 3.98006 0.0825233 3.89372C0.132899 3.80739 0.203797 3.73526 0.28873 3.68394Z" fill="#939393" />
                      </svg>
                    </button>
                  </div>

                  <div class="page">Page</div>

                  <div className="rectangle">
                    <input
                      className="rectangle"
                      type="number"
                      value={pageInput}
                      min={1}  // Ensure the minimum value is 1
                      max={totalPageCount}
                      onChange={handleInputChangePage}
                      onKeyPress={handleKeyPress}
                      onBlur={handleBlurPageInput}
                    />
                  </div>

                  <div class="of-1">of {totalPageCount}</div>

                  <div class="ps-3">
                    <button
                      className={`far-back-icon ${pageInput === totalPageCount ? 'disabled-icon' : ''}`}
                      onClick={handleNextClick}
                      disabled={isLastPage}
                      style={{ cursor: pageInput === totalPageCount ? 'default' : 'pointer' }}
                    >
                      <svg width="13" height="9" viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.7113 3.68394L6.8018 0.0838961C6.71208 0.0298853 6.60988 0.000947745 6.50558 2.28866e-05C6.40128 -0.000901972 6.29859 0.0262187 6.20796 0.0786304C6.11732 0.131042 6.04197 0.206877 5.98954 0.298431C5.93711 0.389986 5.90949 0.493998 5.90947 0.599902V3.13793L0.892331 0.0838961C0.802611 0.0298853 0.70041 0.000947745 0.596109 2.28866e-05C0.491808 -0.000901972 0.389125 0.0262187 0.298489 0.0786304C0.207854 0.131042 0.132496 0.206877 0.08007 0.298431C0.0276445 0.389986 1.94686e-05 0.493998 1.27873e-06 0.599902V8.39999C-0.000224428 8.51017 0.0294334 8.61828 0.085723 8.71248C0.142013 8.80667 0.22276 8.88331 0.319113 8.93399C0.403223 8.97775 0.496442 9.00038 0.590948 9C0.711447 8.99971 0.828979 8.96202 0.927788 8.89199L5.90947 5.35195V8.39999C5.90925 8.51017 5.9389 8.61828 5.99519 8.71248C6.05148 8.80667 6.13223 8.88331 6.22858 8.93399C6.31269 8.97775 6.40591 9.00038 6.50042 9C6.62092 8.99971 6.73845 8.96202 6.83726 8.89199L12.7467 4.69195C12.8278 4.63461 12.8935 4.55753 12.9377 4.46776C12.9818 4.378 13.0032 4.27841 12.9996 4.17813C12.9961 4.07784 12.9679 3.98006 12.9175 3.89372C12.8671 3.80739 12.7962 3.73526 12.7113 3.68394Z" fill="#939393" />
                      </svg>
                    </button>
                  </div>

                  <div class="ps-3">
                    <button
                      onClick={handleLastPageClick}
                      className={`far-back-icon ${pageInput === totalPageCount ? 'disabled-icon' : ''}`}
                      disabled={isLastPage}
                      style={{ cursor: pageInput === totalPageCount ? 'default' : 'pointer' }}
                    >
                      <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.58917 0.00162569H6.54413C6.43566 0.00162569 6.33162 0.0447184 6.25492 0.121424C6.17821 0.19813 6.13512 0.302165 6.13512 0.410643V3.73595L0.633963 0.0547979C0.571786 0.0188992 0.501254 0 0.429459 0C0.357663 0 0.287132 0.0188992 0.224954 0.0547979C0.158724 0.0881475 0.102805 0.138876 0.063181 0.201555C0.0235568 0.264235 0.00171766 0.336508 0 0.410643V8.59098C0.000197432 8.66498 0.0204627 8.73753 0.0586369 8.80091C0.0968111 8.8643 0.151464 8.91614 0.216774 8.95092C0.280732 8.98376 0.352216 8.99916 0.424022 8.99557C0.495828 8.99198 0.56542 8.96952 0.625782 8.93047L6.12694 5.24931V8.59098C6.12694 8.69946 6.17003 8.8035 6.24674 8.8802C6.32344 8.95691 6.42747 9 6.53595 9H8.58099C8.68947 9 8.7935 8.95691 8.8702 8.8802C8.94691 8.8035 8.99 8.69946 8.99 8.59098V0.410643C8.99002 0.303567 8.94805 0.200753 8.87311 0.124278C8.79817 0.0478035 8.69622 0.00376719 8.58917 0.00162569Z" fill="#939393" />
                      </svg>
                    </button>
                  </div>

                </div>
              </div>
            )}

            {!isLoading && <div className=''>
              <ul className='checkStyle'>
                <li className='rowsPerPage'>
                  <label className="page" htmlFor="page-size-select" style={{ whiteSpace: 'pre-wrap' }}>
                    Rows per page   </label>
                  <div className="form-group" style={{ display: 'inline-block', marginRight: '10px' }}>
                    <input
                      className="rectangle-Rows"
                      type="number"
                      value={pageSizeInput}
                      min="1"
                      max="100"
                      onChange={handleInputChangePageSize}
                      onKeyPress={handleKeyPressPagesize}
                      onBlur={handleBlurPageSize}
                      style={{ width: '60px', height: '38px' }}
                    />
                  </div>

                </li>
              </ul>
            </div>}


            {/* {!isLoading && <div>
              <ul className="pageNumberButtonVul pagination">
                <li className="page-item">
                  <button
                    className="page-link"
                    onClick={handlePreviousClick}
                    disabled={curentPageNumber === 0}
                  >
                    Previous
                  </button>
                </li>
                <ul className="pageNumberButtonVul pagination justify-content-end">
                  {getPageNumbers().map((pageNumber) => (
                    <li key={pageNumber} className='page-item'>{pageNumber}</li>
                  ))}
                </ul>
                <li className="page-item">
                  <button
                    className="page-link"
                    onClick={handleNextClick}
                    disabled={isLastPage}
                  >
                    Next
                  </button>
                </li>
              </ul>
            </div>} */}
          </div>
        )}
      </div>
    </>
  );
}
export default CompleteTableData;
CompleteTableData.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      Header: PropTypes.node.isRequired,
      accessor: PropTypes.string.isRequired,
    })
  ).isRequired,
  isMailRequired: PropTypes.bool,
  rowCondt: PropTypes.bool,
  grid: PropTypes.bool,
  isCisoButton: PropTypes.bool,
  isVM: PropTypes.bool,
  isSHUB: PropTypes.bool,
  isCompliance: PropTypes.bool,
  isAccessReview: PropTypes.bool,
  reviewCondt: PropTypes.bool,
  pageNo: PropTypes.number.isRequired,
};