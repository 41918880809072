import React, { useEffect } from 'react'
import { useRef } from "react";
import { useIdleTimer } from "react-idle-timer";
import { useDispatch, useSelector } from "react-redux";
import { setSessionExtend, setTimeoutId, setsessionToken } from './app/commonSlice';
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { resetLoggedUser, setPageLoading, setLoggedUser } from "./app/commonSlice";
import { resetHomeData } from './Home/HomeSlice';
import Cookies from 'universal-cookie';
import GetProxy from './app/GetProxy';

const IdleTimercontainer = () => {
  const idleTimeRef = useRef(null);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const cookies = new Cookies();
  const proxy = GetProxy()

  const onIdle = () => {

    if (location.pathname !== "/auth") {
      dispatch(setSessionExtend(true));

      const id = setTimeout(() => {

        setTimeout(() => {
          dispatch(setSessionExtend(false));
          sessionStorage.setItem("isLoggedin",false);
          const Apitoken = cookies.get("apitoken");
          const User = cookies.get("username");
          fetch(`${proxy}/auth?logout`, {
            method: "GET",
            headers: {
              "DTOP-API-TOKEN": Apitoken,
              "USERNAME": User,
            },
            redirect: "manual",
          })
            .then(() => {
              handleLogout();
              dispatch(resetLoggedUser());
              dispatch(resetHomeData());
              dispatch(setsessionToken(false))
            })
            .catch((err) => {
              console.error(err);
            });
        }, 1000);

      }, 60 * 1000);

      dispatch(setTimeoutId(id));
    }
  };
  const handleLogout = () => {
    cookies.remove("jwttoken",{ path: "/"});
    cookies.remove("Refersh",{ path: "/"});
    cookies.remove("apitoken",{ path: "/"});
    cookies.remove("username",{ path: "/"});
    cookies.remove("referstoken",{ path: "/"});
    dispatch(setLoggedUser({ userName: "", userRole: "", token: "" }));
    dispatch(setPageLoading(true));
    navigate("/auth");
  };

  useIdleTimer({
    crossTab: true,
    ref: idleTimeRef,
    timeout: 29 * 60 * 1000,
    onIdle: onIdle,
  });

  // useEffect(() => {
  //   let apiToken = cookies.get("apitoken");
  //   if (!apiToken) {
  //     navigate("/auth");
  //   }
  // }, [cookies.get("apitoken")])

  return (
    <div>

    </div>
  )
}

export default IdleTimercontainer

