import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Cookies from "universal-cookie";
import { handleResponseCode } from "../../app/utilities/helpers";
import { FaRubleSign } from "react-icons/fa";
import GetProxy from "../../app/GetProxy";
const cookies = new Cookies();
const proxy = GetProxy()


export const saveUpdateProjectsAPI = createAsyncThunk(
    "post/saveUpdateProjectsAPI",
    async (props) => {
        const apiToken = cookies.get("apitoken");
        const username = cookies.get("username");
        try {
            const { payload } = props;
            const response = await fetch(
                `${proxy}/api/v1/projectScan/saveOrUpdate`,
                {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        "DTOP-API-TOKEN": apiToken,
                        USERNAME: username,
                    },
                    body: JSON.stringify(payload),
                }
            );
            handleResponseCode(response);
            const data = await response.json();

            return response.ok ? data : Promise.reject(data);
        } catch (error) {
            console.error("Error:", error);
            throw error;
        }
    }
);

export const projectScanListAPI = createAsyncThunk(
    "post/projectScanListAPI",
    async (props) => {
        const apiToken = cookies.get("apitoken");
        const username = cookies.get("username");
        try {
            const { payload, pageNumber, itemsPerPage } = props;
            const response = await fetch(
                `${proxy}/api/v1/projectScan/list/${pageNumber}/${itemsPerPage}`,
                {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        "DTOP-API-TOKEN": apiToken,
                        USERNAME: username,
                    },
                    body: JSON.stringify(payload),
                }
            );
            handleResponseCode(response);
            const data = await response.json();

            return response.ok ? data : Promise.reject(data);
        } catch (error) {
            console.error("Error:", error);
            throw error;
        }
    }
);

export const projectScanDelete = createAsyncThunk("DELETE/projectScanDelete", async (props) => {
    try {
        const { id } = props;
        const Apitoken = cookies.get("apitoken");
        const User = cookies.get("username");
        const response = await fetch(
            `${proxy}/api/v1/projectScan/delete/${id}`,
            {
                method: "DELETE",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "DTOP-API-TOKEN": Apitoken,
                    USERNAME: User,
                },
            }
        );
        handleResponseCode(response);

        const data = await response.json();
        return data;
    } catch (error) {
        console.error("Error:", error);
        throw error;
    }
});

export const getApplicationProjects = createAsyncThunk(
    "get/getApplicationProjects",
    async ({ userName, userRole }) => {
        const Apitoken = cookies.get("apitoken");
        const User = cookies.get("username");
        try {

            const response = await fetch(`${proxy}/api/v1/cmdb/${userName}/${userRole}`,
                {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        "DTOP-API-TOKEN": Apitoken,
                        USERNAME: User,
                    }
                }
            );
            handleResponseCode(response);
            const data = await response.json();
            return response.ok ? data : Promise.reject(data);
        } catch (error) {
            console.error("Error:", error);
            throw error;
        }
    }
);
export const projecttriggerScanAPI = createAsyncThunk(
    "post/projecttriggerScanAPI",
    async (props) => {
        const apiToken = cookies.get("apitoken");
        const username = cookies.get("username");
        try {
            const { payload } = props;
            const response = await fetch(
                `${proxy}/api/v1/gitlabRepositoryScan/triggerScan`,
                {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        "DTOP-API-TOKEN": apiToken,
                        USERNAME: username,
                    },
                    body: JSON.stringify(payload),
                }
            );
            handleResponseCode(response);
            const data = await response.json();

            return response.ok ? data : Promise.reject(data);
        } catch (error) {
            console.error("Error:", error);
            throw error;
        }
    }
);


export const ProjectInfoListApi = createAsyncThunk(
    "post/ProjectInfoListApi",
    async (props) => {
        const apiToken = cookies.get("apitoken");
        const username = cookies.get("username");
        try {
            const { id , pageNumber, itemsPerPage, payload } = props;
            const response = await fetch(`${proxy}/api/v1/projectScan/list/${id}/${pageNumber}/${itemsPerPage}`, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "DTOP-API-TOKEN": apiToken,
                    USERNAME: username,
                },
                body: JSON.stringify(payload),
            }
            );
            handleResponseCode(response);
            const data = await response.json();

            return response.ok ? data : Promise.reject(data);
        } catch (error) {
            console.error("Error:", error);
            throw error;
        }
    }
);

export const projScanFileDownloadAPI = createAsyncThunk(
    "get/projScanFileDownloadAPI",
    async ({ id }) => {
        const Apitoken = cookies.get("apitoken");
        const User = cookies.get("username");
        try {

            const response = await fetch(`${proxy}/api/v1/projectScan/downloadFile/${id}`,
                {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        "DTOP-API-TOKEN": Apitoken,
                        USERNAME: User,
                    }
                }
            );
            console.log("response",typeof(response))
            handleResponseCode(response);
            const data = await response.blob();
            return response.ok ? data : Promise.reject(data);
        } catch (error) {
            console.error("Error:", error);
            throw error;
        }
    }
);

export const serverInfoListApi = createAsyncThunk(
    "post/serverInfoListApi",
    async (props) => {
        const apiToken = cookies.get("apitoken");
        const username = cookies.get("username");
        try {
            const { pageNumber, itemsPerPage, payload } = props;
            const response = await fetch(`${proxy}/api/v1/tenableScanLaunchController/getServersListWithSearchAndPagination/${pageNumber}/${itemsPerPage}`, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "DTOP-API-TOKEN": apiToken,
                    USERNAME: username,
                },
                body: JSON.stringify(payload),
            }
            );
            handleResponseCode(response);
            const data = await response.json();

            return response.ok ? data : Promise.reject(data);
        } catch (error) {
            console.error("Error:", error);
            throw error;
        }
    }
)

export const serverInfoCountApi = createAsyncThunk(
    "post/serverInfoCountApi",
    async (props) => {
        const apiToken = cookies.get("apitoken");
        const username = cookies.get("username");
        try {
            const { payload } = props;
            const response = await fetch(`${proxy}/api/v1/tenableScanLaunchController/getServerListCount`, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "DTOP-API-TOKEN": apiToken,
                    USERNAME: username,
                },
                body: JSON.stringify(payload),
            }
            );
            handleResponseCode(response);
            const data = await response.json();

            return response.ok ? data : Promise.reject(data);
        } catch (error) {
            console.error("Error:", error);
            throw error;
        }
    }
)

export const getServerScan = createAsyncThunk(
    "post/getServerScan",
    async (props) => {
        const apiToken = cookies.get("apitoken");
        const username = cookies.get("username");
        try {
            const { payload } = props;
            const response = await fetch(`${proxy}/api/v1/tenableScanLaunchController/createAndLaunchAdvancedScan`, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "DTOP-API-TOKEN": apiToken,
                    USERNAME: username,
                },
                body: JSON.stringify(payload),
            }
            );
            handleResponseCode(response);
            const data = await response.json();

            return response.ok ? data : Promise.reject(data);
        } catch (error) {
            console.error("Error:", error);
            throw error;
        }
    }
)
export const getReportServer = createAsyncThunk(
    "post/getReportServer",
    async (props) => {
        const Apitoken = cookies.get("apitoken");
        const User = cookies.get("username");
        const { ipAddress } = props;
        try {

            const response = await fetch(`${proxy}/api/v1/tenableScanLaunchController/getTenableScanReport/${ipAddress}`,
                {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        // "Content-Type": "application/json",
                        "DTOP-API-TOKEN": Apitoken,
                        USERNAME: User,
                    }
                }
            );
            handleResponseCode(response);
            const data = await response.json();
            return response.ok ? data : Promise.reject(data);
        } catch (error) {
            console.error("Error:", error);
            throw error;
        }
    }
);

export const getServerInfoData = createAsyncThunk(
    "post/getServerInfoData",
    async (props) => {
        const Apitoken = cookies.get("apitoken");
        const User = cookies.get("username");
        const { pageNumber, itemsPerPage, payload } = props;
        try {

            const response = await fetch(`${proxy}/api/v1/tenableScanLaunchController/getScanInfoDetails/${pageNumber}/${itemsPerPage}`,
                {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        "DTOP-API-TOKEN": Apitoken,
                        USERNAME: User,
                    },
                    body: JSON.stringify(payload),
                }
            );
            handleResponseCode(response);
            const data = await response.json();
            return response.ok ? data : Promise.reject(data);
        } catch (error) {
            console.error("Error:", error);
            throw error;
        }
    }
);

export const getServerScanReportData = createAsyncThunk(
    "post/getServerScanReportData",
    async (props) => {
        const Apitoken = cookies.get("apitoken");
        const User = cookies.get("username");
        const { ipAddress, scanCreatedOn } = props;
        try {

            const response = await fetch(`${proxy}/api/v1/tenableScanLaunchController/getScanReport/${ipAddress}/${scanCreatedOn}`,
                {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        // "Content-Type": "application/json",
                        "DTOP-API-TOKEN": Apitoken,
                        USERNAME: User,
                    }
                }
            );
            handleResponseCode(response);
            const data = await response.json();
            return response.ok ? data : Promise.reject(data);
        } catch (error) {
            console.error("Error:", error);
            throw error;
        }
    }
);



const initialState = {
    //Projects 
    saveUpdateProjectsData: [],
    saveUpdateProjectsDataLoading: false,
    projectScanListData: [],
    projectScanListAPILoading: false,
    projectScanDeleteData: [],
    projectScanDeleteLoading: false,
    getApplicationProjectData: [],
    getApplicationProjectLoading: false,
    projScanFileDownloadData: [],
    projScanFileDownloadLoading: false,
    projecttriggerScanData: [],
    projecttriggerScanLoading: false,
    ProjectInfoListData:[],
    ProjectInfoListLoading:false,

    //Server
    serverInfoListLoading: false,
    serverInfoListData: [],
    serverInfoCountLoading: false,
    serverInfoCountData: [],
    serverScanData: [],
    serverScanDataLoading: false,
    reportServerData : [],
    reportServerDataLoading : false,
    serverInfoData : [],
    serverInfoDataLoading : false,
    serverInfoReportData : [],
    serverInfoReportDataLoading : false,
    error: "",
    apiError: false,
};

export const InventorySlice = createSlice({
    name: 'InventorySlice',
    initialState,

    reducers: {
        setErrorAlert: {
            reducer(state, action) {
                state.apiError = false;
            },
        },

    },
    extraReducers: (builder) => {
        builder

            //saveUpdateProjectsAPI
            .addCase(saveUpdateProjectsAPI.pending, (state, action) => {
                state.saveUpdateProjectsData = [],
                    state.saveUpdateProjectsDataLoading = true
                state.error = false;
            })
            .addCase(saveUpdateProjectsAPI.fulfilled, (state, action) => {
                state.saveUpdateProjectsData = action.payload,
                    state.saveUpdateProjectsDataLoading = false
                state.error = false;
            })
            .addCase(saveUpdateProjectsAPI.rejected, (state, action) => {
                state.saveUpdateProjectsDataLoading = false
                state.saveUpdateProjectsData = []
                state.error = true;
            })

            //projectScanListAPI
            .addCase(projectScanListAPI.pending, (state, action) => {
                state.projectScanListData = [],
                    state.projectScanListAPILoading = true
                state.error = false;
            })
            .addCase(projectScanListAPI.fulfilled, (state, action) => {
                state.projectScanListData = action.payload,
                    state.projectScanListAPILoading = false
                state.error = false;
            })
            .addCase(projectScanListAPI.rejected, (state, action) => {
                state.projectScanListAPILoading = false
                state.projectScanListData = []
                state.error = true;
            })

            //projectScanDelete
            .addCase(projectScanDelete.pending, (state, action) => {
                state.projectScanDeleteData = [],
                    state.projectScanDeleteLoading = true
                state.error = false;
            })
            .addCase(projectScanDelete.fulfilled, (state, action) => {
                state.projectScanDeleteData = action.payload,
                    state.projectScanDeleteLoading = false
                state.error = false;
            })
            .addCase(projectScanDelete.rejected, (state, action) => {
                state.projectScanDeleteLoading = false
                state.projectScanDeleteData = []
                state.error = true;
            })

            //projecttriggerScanAPI
            .addCase(projecttriggerScanAPI.pending, (state, action) => {
                state.projecttriggerScanData = [],
                    state.projecttriggerScanLoading = true
                state.error = false;
            })
            .addCase(projecttriggerScanAPI.fulfilled, (state, action) => {
                state.projecttriggerScanData = action.payload,
                    state.projecttriggerScanLoading = false
                state.error = false;
            })
            .addCase(projecttriggerScanAPI.rejected, (state, action) => {
                state.projecttriggerScanLoading = false
                state.projecttriggerScanData = []
                state.error = true;
            })

            //getApplicationProjects
            .addCase(getApplicationProjects.pending, (state, action) => {
                state.getApplicationProjectLoading = true;
            })
            .addCase(getApplicationProjects.fulfilled, (state, action) => {
                state.getApplicationProjectLoading = false;
                state.getApplicationProjectData = action.payload;
            })
            .addCase(getApplicationProjects.rejected, (state, action) => {
                state.getApplicationProjectLoading = false;
                state.getApplicationProjectData = []
            })

            //projScanFileDownloadAPI
            .addCase(projScanFileDownloadAPI.pending, (state, action) => {
                state.projScanFileDownloadLoading = true;
            })
            .addCase(projScanFileDownloadAPI.fulfilled, (state, action) => {
                state.projScanFileDownloadLoading = false;
                state.projScanFileDownloadData = action.payload;
            })
            .addCase(projScanFileDownloadAPI.rejected, (state, action) => {
                state.projScanFileDownloadLoading = false;
                state.projScanFileDownloadData = []
            })

            // serverInfo List
            .addCase(ProjectInfoListApi.pending, (state, action) => {
                state.ProjectInfoListLoading = true;
                state.error = false;
            })
            .addCase(ProjectInfoListApi.fulfilled, (state, action) => {
                state.ProjectInfoListData = action.payload;
                state.ProjectInfoListLoading = false;
                state.error = false;
            })
            .addCase(ProjectInfoListApi.rejected, (state, action) => {
                state.ProjectInfoListLoading = false;
                state.ProjectInfoListData = [];
                state.error = true;
            })

            // serverInfo List
            .addCase(serverInfoListApi.pending, (state, action) => {
                state.serverInfoListLoading = true;
                state.error = false;
            })
            .addCase(serverInfoListApi.fulfilled, (state, action) => {
                state.serverInfoListData = action.payload;
                state.serverInfoListLoading = false;
                state.error = false;
            })
            .addCase(serverInfoListApi.rejected, (state, action) => {
                state.serverInfoListLoading = false;
                state.serverInfoListData = [];
                state.error = true;
            })

            .addCase(serverInfoCountApi.pending, (state, action) => {
                state.serverInfoCountLoading = true;
                state.error = false;
            })
            .addCase(serverInfoCountApi.fulfilled, (state, action) => {
                state.serverInfoCountData = action.payload;
                state.serverInfoCountLoading = false;
                state.error = false;
            })
            .addCase(serverInfoCountApi.rejected, (state, action) => {
                state.serverInfoCountLoading = false;
                state.serverInfoCountData = [];
                state.error = true;
            })

            .addCase(getServerScan.pending, (state, action) => {
                state.serverScanDataLoading = true;
                state.error = false;
            })
            .addCase(getServerScan.fulfilled, (state, action) => {
                state.serverScanData = action.payload;
                state.serverScanDataLoading = false;
                state.error = false;
            })
            .addCase(getServerScan.rejected, (state, action) => {
                state.serverScanDataLoading = false;
                state.serverScanData = [];
                state.error = true;
            })

            .addCase(getReportServer.pending, (state, action) => {
                state.reportServerDataLoading = true;
                state.error = false;
            })
            .addCase(getReportServer.fulfilled, (state, action) => {
                state.reportServerData = action.payload;
                state.reportServerDataLoading = false;
                state.error = false;
            })
            .addCase(getReportServer.rejected, (state, action) => {
                state.reportServerDataLoading = false;
                state.reportServerData = [];
                state.error = true;
            })

            .addCase(getServerInfoData.pending, (state, action) => {
                state.serverInfoDataLoading = true;
                state.error = false;
            })
            .addCase(getServerInfoData.fulfilled, (state, action) => {
                state.serverInfoData = action.payload;
                state.serverInfoDataLoading = false;
                state.error = false;
            })
            .addCase(getServerInfoData.rejected, (state, action) => {
                state.serverInfoDataLoading = false;
                state.serverInfoData = [];
                state.error = true;
            })

            .addCase(getServerScanReportData.pending, (state, action) => {
                state.serverInfoReportDataLoading = true;
                state.error = false;
            })
            .addCase(getServerScanReportData.fulfilled, (state, action) => {
                state.serverInfoReportData = action.payload;
                state.serverInfoReportDataLoading = false;
                state.error = false;
            })
            .addCase(getServerScanReportData.rejected, (state, action) => {
                state.serverInfoReportDataLoading = false;
                state.serverInfoReportData = [];
                state.error = true;
            })


    },

});

export const { setErrorAlert } = InventorySlice.actions;
export default InventorySlice.reducer;
