import React, { useState, useEffect } from 'react';
import './style.scss';
import { FaCheckCircle } from "react-icons/fa";
import { Col, Row } from 'react-bootstrap';
import CustomModal from '../../app/common-components/CustomModal';
import ButtonComponent from '../../app/common-components/ButtonComponent';
import Spinners from '../../app/common-components/Spinner';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import DiscardChanges from '../../app/common-components/DiscardChanges';


function ProjectScanModel(props) {

    const {
        projecttriggerScanLoading,
    } = useSelector((state) => state.InventorySliceAPI);
    const [state, setState] = useState({
        scan: props?.Scan,
        DeletemodalOpen: false,
        Confirmmsg: false,
        conformationPop:false,
        DiscardModelOpen:false,
    });

    const options = [
        { label: 'SAST (SonarQube)', value: 'option1' },
        { label: 'DAST (Veracode)', value: 'option2' },
        { label: 'Mobile', value: 'option3' },
    ];

    const [selectedOptions, setSelectedOptions] = useState([]);

    const handleCheckboxChange = (event) => {
        const { value } = event.target;
        if (selectedOptions.includes(value)) {
            setSelectedOptions(selectedOptions.filter((option) => option !== value));
        } else {
            setSelectedOptions([...selectedOptions, value]);
        }
        setState({
            ...state,
            conformationPop: true,
        });
    };


    const DiscardModel = () => {
        setState({
            ...state,
            DiscardModelOpen: true,
        });
    };
    const handleClose = () => {
        setState({
            ...state,
            DeletemodalOpen: false,
        });
    };
    const ScanClick = () => {
        setState({
            ...state,
            DeletemodalOpen: true,
        });

    };
    const DiscardModelClose = () => {
        props.ScanModleCLose()
        setState({...state,conformationPop: false,DiscardModelOpen: false,});
    };
    const addModelConfirmClose = () => {
        setState({...state,DiscardModelOpen: false,});
    };

    const DiscardModelConfirm = () => {
        return (
            <>
                <DiscardChanges title="sacn" discardClick={DiscardModelClose} cancelClick={addModelConfirmClose} />
            </>
        )
    };

    return (
        <div>
            {projecttriggerScanLoading ? (
                <Spinners />
            ) : (
                <>
                    {!state?.DeletemodalOpen && <div>
                        <h4 className="delete-h ">Select Scan Type</h4>
                        <ul>
                            {options.map((option) => (
                                <li key={option.value}>
                                    <label className="checkbox-container">
                                        <input
                                            type="checkbox"
                                            value={option.value}
                                            checked={selectedOptions.includes(option.value)}
                                            onChange={handleCheckboxChange}
                                        />
                                        {option.label}
                                    </label>


                                </li>
                            ))}
                        </ul>
                    </div>}
                    {state?.DeletemodalOpen && <>
                        <h4 className="delete-h">Scan Project</h4>
                        <div>
                            <p>
                                Please confirm to initiate the scanning process ?
                            </p>
                            <div className="btnswrap btn-dlt">
                                <ButtonComponent
                                    text="No"
                                    className={"cancelBtnStyle btn btn-primary"}
                                    onClick={handleClose}
                                />
                                <ButtonComponent
                                    text="Yes "
                                    className={"btnStyle btn btn-primary"}
                                    onClick={() => props.ScanClick()}
                                />
                            </div>
                        </div>
                    </>}

                    {!state?.DeletemodalOpen && <div className="btnswrap btn-dlt projectscanButton">
                        <ButtonComponent
                            text="Cancel"
                            className={"cancelBtnStyle mx-0 btn btn-primary"}
                            onClick={() => {
                                state.conformationPop ? DiscardModel() :props.ScanModleCLose()
                            }}
                        />
                        <ButtonComponent
                            text="Confirm"
                            className={"btnStyle btn btn-primary"}
                            onClick={() => ScanClick()}
                            disabled={isEmpty(selectedOptions)}
                        />
                    </div>}
            <CustomModal
                modalOpen={state.DiscardModelOpen}
                modalBody={DiscardModelConfirm}
                modalHide={DiscardModelClose}
                closeBtn={true}
                closeBtnFuc={DiscardModelClose}
            />
                </>
            )}    </div>
            
    );
}

export default ProjectScanModel;