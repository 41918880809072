import React, { useState, useEffect, useRef } from "react";
import "./customSelect.scss";
import _ from "lodash";
import PropTypes from "prop-types";
const CustomSelect = ({
    options = [],
    placeholder = "",
    multiSelect = false,
    defaultSelected = [],
    maxSelection = Infinity,
    disabledOptions = [],
    onChange,
    searchable = true,
    withSelectAll = false,
    selectContainerClassName = "",
    selectvalueClassName = "",
    error = "",
    inputType = "checkbox",
    label = "",
    isDisabled = false,
    isError = false,
    styles={},
    selectedOption
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedValues, setSelectedValues] = useState(defaultSelected);
    const [search, setSearch] = useState("");
    const dropdownRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (e) => {
            if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
                setIsOpen(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, []);

    // useEffect(() => {
    //     onChange && onChange(selectedValues);
    // }, [selectedValues]);

    useEffect(() => {
        setSelectedValues(defaultSelected);
    }, [defaultSelected]);

  const toggleDropdown = () => {
        setSearch("") // clear search value when drop down close
        if (!isDisabled) setIsOpen(!isOpen); // Prevent dropdown toggle if disabled
    };

  const handleSelect = (option) => {
    if (disabledOptions.includes(option.value)) return;

    let updatedSelection;
    if (inputType === "radio") {
      updatedSelection = [option];
    } else if (multiSelect) {
      if (selectedValues.some((val) => val.value === option.value)) {
        updatedSelection = selectedValues.filter(
          (val) => val.value !== option.value
        );
      } else if (selectedValues.length < maxSelection) {
        updatedSelection = [...selectedValues, option];
      } else {
        updatedSelection = selectedValues;
      }
    } else {
      updatedSelection = [option];
      setIsOpen(false);
    }
    setSelectedValues(updatedSelection);
    onChange && onChange(updatedSelection); // Notify parent about selection change
  };

  // Filter options based on search input
  const filteredOptions = Array.isArray(options)
  ? options.filter(
      (option) =>
        typeof option.label === "string" &&
        option.label.toLowerCase().includes(search.toLowerCase())
    )
  : [];

  const handleSelectAll = () => {
    let newSelections;
    if (selectedValues.length === filteredOptions.length) {
      newSelections = []; // Deselect all if already selected
    } else {
      newSelections = filteredOptions.filter(
        (opt) => !disabledOptions.includes(opt.value)
      );
    }
    setSelectedValues(newSelections);
    onChange && onChange(newSelections); // Notify parent about selection change
  };

  const handleOptionClick = (option) => {
    if (option.value === "select-all") {
      handleSelectAll();
    } else {
      handleSelect(option);
    }
  };

  // Determine the placeholder based on selected values and provided placeholder
  const displayPlaceholder =
    selectedValues.length === 0
      ? placeholder
        ? `Select ${placeholder}`
        : "Select"
      : selectedValues.length === 1
      ? selectedValues[0].label
      : `${selectedValues.length} ${placeholder} Selected`;

  const isAllSelected =
    _.isArray(filteredOptions) &&
    _.isArray(selectedValues) &&
    _.every(filteredOptions, (opt) =>
      _.some(selectedValues, (val) => val?.value === opt?.value)
    );

  const selectAllLabel = isAllSelected ? "Unselect all" : "Select all";
  // Add "Select All" option conditionally
  const optionsToDisplay =
    withSelectAll &&
    multiSelect &&
    options.length > 1 &&
    inputType !== "radio" &&
    filteredOptions.length > 0 ? [{ label: selectAllLabel, value: "select-all" }, ...filteredOptions] : filteredOptions;

  const handleOptionClickWrapper = (option) => () => {
    handleOptionClick(option);
  };

  const handleKeyDownWrapper = (option) => (e) => {
    if (e.key === "Enter" || e.key === " ") handleOptionClick(option);
  };
  const getStyle = (key, defaultStyles) => {
    return styles[key] ? styles[key](defaultStyles, { isDisabled, isOpen, selectedOption }) : defaultStyles;
  };

  return (
    <div
      className={`custom-select ${selectContainerClassName} ${
        isDisabled ? "disabled" : ""
      }`}
      ref={dropdownRef}
    >
      <div className="select-head">
        {label && <label className="select-label">{label}</label>}
        {error && <div className="select-error">{error}</div>}
      </div>
      <div
        className={`select-input ${isOpen ? "openDrop" : ""} ${
          isDisabled ? "disabled-input" : ""
        } ${isError ? "errorinput" : ""}`}
        onClick={toggleDropdown}
        style={selectedOption ? getStyle('control') : undefined}
        role="button"
        aria-haspopup="listbox"
        aria-expanded={isOpen}
        tabIndex={isDisabled ? -1 : 0}
      >
        <div className={`select-value ${selectvalueClassName} ${isDisabled ? "disabled" : ""}`} style={selectedOption ? getStyle('control') : undefined}>
          {displayPlaceholder}
        </div>
        <div className="dropdown-border">
  {!isDisabled && (
    <div aria-hidden="true">
      {selectedOption ? (
        <div className={`alternatearrow ${isOpen ? "open" : ""}`}></div>
      ) : (
        <div className={`arrow ${isOpen ? "open" : ""}`}></div>
      )}
    </div>
  )}
</div>
      </div>

      {isOpen && !isDisabled && (
        <div className="dropdown menu-container">
        <div className="dropdown-controls">
        </div>
          {searchable && (
            <input
              autoFocus
              placeholder={`Search ${placeholder}`}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              className="dropdownSearch-input"
            />
          )}
          <ul className="options-list">
            {Array.isArray(optionsToDisplay) && optionsToDisplay.length > 0 ? (
              optionsToDisplay.map((option, index) => {
                const isSelected =
                  Array.isArray(selectedValues) &&
                  selectedValues.some((val) => val.value === option.value);

                const isOptionDisabled =
                  option.isDisabled || disabledOptions.includes(option.value);

                return (
                  <li
                    key={index}
                    role="option"
                    style={getStyle('option')}
                    aria-selected={isSelected}
                    tabIndex={0}
                    className={`option 
                    ${isSelected ? "selected" : ""} 
                    ${isOptionDisabled ? "disabled" : ""} 
                    ${Array.isArray(selectedValues) && selectedValues.some(val => val.value === option.value) ? "selected" : ""} 
                    ${Array.isArray(disabledOptions) && disabledOptions.includes(option.value) ? "disabled" : ""} 
                    ${option.value === 'select-all' ? "selectAllOptionClass" : ""}`}
                    onClick={
                      !isOptionDisabled
                        ? handleOptionClickWrapper(option)
                        : undefined
                    }
                    onKeyDown={
                      !isOptionDisabled
                        ? handleKeyDownWrapper(option)
                        : undefined
                    }
                  >
                    {multiSelect && (
                      <input
                        type={inputType}
                        readOnly
                        checked={isSelected}
                        disabled={isOptionDisabled} // Adds disabled attribute for accessibility
                        className={`${
                          option.value === "select-all" ? "d-none" : ""
                        } ${isOptionDisabled ? "checkbox-disabled" : ""}`}
                      />
                    )}
                    <span
                      className={
                        option.value === "select-all"
                          ? "option-text"
                          : "select-all-text"
                      }
                    >
                      {option.label}
                    </span>
                  </li>
                );
              })
            ) : (
              <li className="option disabled selected">No Results</li>
            )}
          </ul>
        </div>
      )}
      {error && <div className="error-message">{error}</div>}
    </div>
  );
};
CustomSelect.propTypes = {
  options: PropTypes.bool,
  placeholder: PropTypes.arrayOf(PropTypes.object).isRequired,
  multiSelect: PropTypes.arrayOf(PropTypes.object).isRequired,  
  defaultSelected: PropTypes.arrayOf(PropTypes.object).isRequired,  
  onChange: PropTypes.arrayOf(PropTypes.object).isRequired,  
  disabledOptions: PropTypes.arrayOf(PropTypes.object).isRequired,  
  maxSelection: PropTypes.arrayOf(PropTypes.object).isRequired,  
  searchable: PropTypes.arrayOf(PropTypes.object).isRequired,  
  withSelectAll: PropTypes.arrayOf(PropTypes.object).isRequired,  
  selectContainerClassName: PropTypes.arrayOf(PropTypes.object).isRequired,  
  selectvalueClassName: PropTypes.arrayOf(PropTypes.object).isRequired,  
  error: PropTypes.arrayOf(PropTypes.object).isRequired,  
  inputType: PropTypes.arrayOf(PropTypes.object).isRequired,  
  label: PropTypes.arrayOf(PropTypes.object).isRequired,  
  isDisabled: PropTypes.arrayOf(PropTypes.object).isRequired,  
  isError: PropTypes.arrayOf(PropTypes.object).isRequired,  
  styles: PropTypes.arrayOf(PropTypes.object).isRequired,  
  selectedOption: PropTypes.arrayOf(PropTypes.object).isRequired,  
};

export default CustomSelect;
