export const PaginationInfo = ({ currentPage, pageSize, listCount }) => {
    const startIndex = currentPage * pageSize + 1;
    const endIndex = Math.min((currentPage + 1) * pageSize, listCount);
  
    return (
      <div className="pagination-info d-flex align-items-center">
        <span className="page me-2">
          Showing <strong>{startIndex}</strong> to <strong>{endIndex}</strong> of <strong>{listCount}</strong> records
        </span>
      </div>
    );
  };

  export const DataNotFound = ({status}) => {
    return(
      <div className="NodataAvailableVMTDD NodataAvailable">Something Went Wrong</div>
    )
  }
  export const checkColumn = (column, headerLift) => {
    const getData = column.map((item, index) => { return ({ name: item.Header, liftHeader: item.hasOwnProperty('columns') }) });
    return getData
  }

