import React from 'react';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Cookies from 'universal-cookie';
import { handleResponseCode } from '../../app/utilities/helpers';
import GetProxy from '../../app/GetProxy';

const proxy = GetProxy()
const cookies = new Cookies();

export const useIamNetworkGraphAPI = createAsyncThunk(
    "post/useIamNetworkGraphAPI",
    async (props) => {
        const { payload } = props;
        const Apitoken = cookies.get("apitoken");
        const User = cookies.get("username");
        const res = await fetch(`${proxy}/api/v1/awsIam/getNetworkGraphData`, {
            method: 'POST',
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "DTOP-API-TOKEN": Apitoken,
                USERNAME: User,
            },
            body: JSON.stringify(payload)
        }).then(res => {
            const result = res.json();
            return result;
        })
            .catch(err => {
                return err;
            })
        handleResponseCode(res);
        return res;

    });
    export const initialState = {
        iamNetworkData: [],
        iamNetworkDataLoading: false,    
    }

    export const UserReviewSlice = createSlice({
        name: 'UserReviewSlice',
        initialState,
        reducers: {
            resetUAR_InitalData: () => {
                return initialState;
              }
        },
        extraReducers: (builder) => {
            builder
                .addCase(useIamNetworkGraphAPI.pending, (state, action) => {
                    state.iamNetworkDataLoading = true;
                })
                .addCase(useIamNetworkGraphAPI.fulfilled, (state, action) => {
                    state.iamNetworkData = action.payload;
                    state.iamNetworkDataLoading = false;
                })
                .addCase(useIamNetworkGraphAPI.rejected, (state, action) => {
                    state.iamNetworkDataLoading = false;
                })    
        },
    
    })
    export default UserReviewSlice.reducer;
    export const { resetUAR_InitalData } = UserReviewSlice.actions;
