import React, { useEffect, useState, useCallback } from "react";
import Select from "react-select";
import Option from "./Option";
import "./custom_select_search.scss";
import Control from "./Control";
import ValueContainer from "./ValueContainer";
import Menu from "./Menu";
import MenuList from "./MenuList";
import DropdownIndicator from "./DropdownIndicator";
import IndicatorsContainer from "./IndicatorsContainer";
import { useDetectClickOutside } from "react-detect-click-outside";
import CustomSelect from "../Dropdown/CustomSelect";

const CustomSelectSearch = (props) => {
  const {options, onChange, value, name, isDisabled, isMulti,labelName,error, errorMessage} = props;
  const handleOnChange =(selected)=>{
    onChange(selected[0]);
  }
  const normalizeValue = (val) => {
    if (!val) return [];
    // If the value is already an array of objects with { value, label }
    if (Array.isArray(val) && val.every(item => item.value && item.label)) {
      return val;
    }
    // If the value is a single string like 'Option 1'
    if (typeof val === "string") {
      const foundOption = options.find(option => option.label === val || option.value === val);
      return foundOption ? [foundOption] : [];
    }
    // If the value is a single object { value: 'option1', label: 'Option 1' }
    if (val.value && val.label) {
      return [val];
    }
    return [];
  };
  return (
    <div
      className={`${
        props.containerClassName
          ? props.containerClassName
          : "frmgroup default-input col-lg-6"
      }`}
    >
      {/* {labelName && <label>{labelName}</label>} */}
      <div className={`frmhead ${props.labelClassName}`}>
        {props.labelName && (
          <label className={props.customlabelClassName}>
            {props.labelName}{" "}
          </label>
        )}
        {props.error && (
          <div className={`error ${props.errorClassName}`}>
            {props.errorMessage}
          </div>
        )}
      </div>
      <div
        className={`dropdown bootstrap-select ${
          props.error ? "TypeHeadBorder" : ""
        }`}
      >
        <CustomSelect
          options={options}
          onChange={handleOnChange}
          defaultSelected={normalizeValue(value)}
          placeholder={name}
          selectContainerClassName={"w-100"}
          isDisabled={isDisabled}
          multiSelect={isMulti? isMulti : false}
          isError={props?.error}
        />
      </div>
    </div>
  );
};

export default CustomSelectSearch;
