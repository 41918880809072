import Spinner from "react-bootstrap/Spinner";
import React from "react";
import "./custom-components.style.scss";

const MainLoader = () => (
  <div className="loader_overlay">
    <div className="page_loader">
    <Spinner animation="border" variant="primary" />
    </div>
  </div>
);
export default MainLoader;
