import { get } from "lodash";
import React, { useState } from "react";
import {
  logoSmall,
  observability,
  gnc,
  efficiency,
  configuration,
  notification,
  user,
  logout,
  loader,
} from "../../assets/images/index";
import MainLoader from "./MainLoader";
import Spinner from "react-bootstrap/Spinner";
import "./custom-components.style.scss";


const ModuleLoader = () => {
  const [showSettingsChild, setShowSettingsChild] = useState(false);
  const [showAdminChild, setShowAdminChild] = useState(false);
  const [showDevopsMetricsChild, setShowDevopsMetricsChild] = useState(false);
  return (
    <div className="wrapper_jr">
      {/* <header>
        <div className="headerwrap">
          <div className="header-logo">
            <a hlight="index.html">
              <img src={logoSmall} alt="DevOpsLab" />
            </a>
          </div>
          <div className="nav">
            <ul>
              <li className={`dropdown-btn`}>
                {" "}
                <a
                  hlight="/Observability"
                  className="hovertip"
                  data-tip="Code8 Observability"
                >
                  <img src={observability} alt="Code8 Observability" />
                </a>
                <div className="dropdown-container">
                  <a href="#">Flow Metrics</a>
                  <a href="#">People Metrics</a>
                  <a href="#">Productivity Metrics</a>
                  <a href="#">Dev Metrics</a>
                  <a
                    flag="ops"
                    onMouseOver={() => setShowDevopsMetricsChild(true)}
                    onMouseLeave={() => setShowDevopsMetricsChild(false)}
                  >
                    Ops Metrics
                  </a>
                  <div
                    className={`dropdown-container_child ops ${
                      showDevopsMetricsChild && "active"
                    }`}
                    id="inchange"
                    onMouseOver={() => setShowDevopsMetricsChild(true)}
                    onMouseLeave={() => setShowDevopsMetricsChild(false)}
                  >
                    <a href="#">Incident Management</a>
                    <a href="#">Change Management</a>
                  </div>
                </div>
              </li>
              <li className={`dropdown-btn`}>
                <a
                  hlight="/Governance"
                  className="hovertipgnc"
                  data-tip="Governance and Compliance"
                >
                  <img src={gnc} alt="Governance and Compliance" />
                </a>
                <div className="dropdown-container">
                  <a href="#">Jenkins</a>
                  <a href="#">Bitbucket</a>
                </div>
              </li>
              <li className={`dropdown-btn`}>
                <a
                  hlight="/Efficiency"
                  className="hovertip"
                  data-tip="Code8 Efficiency"
                >
                  <img src={efficiency} alt="Code8 Efficiency" />
                </a>
                <div className="dropdown-container">
                  <a href="#">App Config</a>
                  <a href="#">Access Management</a>
                  <a href="#">My Request</a>
                  <a href="#">My Approval</a>
                  <a href="#">Audit Log</a>
                </div>
              </li>
              <li className={`dropdown-btn`}>
                {" "}
                <a
                  hlight="/Configuration"
                  className="hovertip"
                  data-tip="Code8 Configuration"
                >
                  <img src={configuration} alt="Code8 Configuration" />
                </a>
                <div className="dropdown-container">
                  <a
                    flag="settings"
                    onMouseOver={() => setShowSettingsChild(true)}
                    onMouseLeave={() => setShowSettingsChild(false)}
                  >
                    Settings
                  </a>
                  <div
                    className={`dropdown-container_child settings ${
                      showSettingsChild && "active"
                    }`}
                    onMouseOver={() => setShowSettingsChild(true)}
                    onMouseLeave={() => setShowSettingsChild(false)}
                  >
                    <a href="#">Tools</a>
                    <a href="#">Groups</a>
                  </div>
                  <a
                    flag="admin"
                    onMouseOver={() => setShowAdminChild(true)}
                    onMouseLeave={() => setShowAdminChild(false)}
                  >
                    Administration
                  </a>
                  <div
                    className={`dropdown-container_child admin ${
                      showAdminChild && "active"
                    }`}
                    id="adchange"
                    onMouseOver={() => setShowAdminChild(true)}
                    onMouseLeave={() => setShowAdminChild(false)}
                  >
                    <a href="#">LDAP Configuration</a>
                    <a href="#">SMTP Configuration</a>
                    <a href="#">License Configuration</a>
                    <a href="#">User Management</a>
                    <a href="#">Logging Configuration</a>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </header> */}
      <div className="loader_overlay">
        <div className="page_loader">
        <Spinner animation="border" variant="primary" />
        </div>
      </div>
    </div>
  );
};
const Container = () => {
  const mainLoaderUrls = ["/", "/auth", "/logout", "/auth?logout"];
  const location = window.location;
  const pathName = get(location, "pathname", "/");
  let index = mainLoaderUrls.indexOf(pathName);
  return index !== -1 ? <MainLoader /> : <ModuleLoader />;
};

export default Container;
