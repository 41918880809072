import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Cookies from "universal-cookie";
import { handleResponseCode } from "../../app/utilities/helpers";
import { FaRubleSign } from "react-icons/fa";
import GetProxy from "../../app/GetProxy";
const cookies = new Cookies();
const proxy = GetProxy()

//statuschart vm

export const StatusForVulVm = createAsyncThunk(
  "post/StatusForVulVm",
  async (payload) => { // directly use payload here
      const apiToken = cookies.get("apitoken");
      const username = cookies.get("username");
      try {
               
          const response = await fetch(
              `${proxy}/api/v1/tenableNessusRest/CISO/getCountByStatusForVul`,
              {
                  method: "POST",
                  headers: {
                      Accept: "application/json",
                      "Content-Type": "application/json",
                      "DTOP-API-TOKEN": apiToken,
                      USERNAME: username,
                  },
                  body: JSON.stringify(payload),
              }
          );
          handleResponseCode(response);
          const data = await response.json();
          return response.ok ? data : Promise.reject(data);
      } catch (error) {
          console.error("Error:", error);
          throw error;
      }
  }
);

  
  //appliaction chart

   export const AppliactionForVulVm = createAsyncThunk(
    "post/AppliactionForVulVm",
    async (payload) => { // directly use payload here
        const apiToken = cookies.get("apitoken");
        const username = cookies.get("username");
        try {
                const response = await fetch(
                `${proxy}/api/v1/tenableNessusRest/ciscoGraph/application`,
                {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        "DTOP-API-TOKEN": apiToken,
                        USERNAME: username,
                    },
                    body: JSON.stringify(payload),
                }
            );
            handleResponseCode(response);
            const data = await response.json();

            return response.ok ? data : Promise.reject(data);
        } catch (error) {
            console.error("Error:", error);
            throw error;
        }
    }
);



  //action plan Vm

  export const ActionPlanForVulVm = createAsyncThunk(
    "post/ActionPlanForVulVm",
    async (payload) => {
      const apiToken = cookies.get("apitoken");
      const username = cookies.get("username");
      try {
        // const { payload } = props;
        const response = await fetch(
          `${proxy}/api/v1/tenableNessusRest/ActionPlanCountsForVM`,
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "DTOP-API-TOKEN": apiToken,
              USERNAME: username,
            },
            body: JSON.stringify(payload),
          }
        );
        handleResponseCode(response);
        const data = await response.json();
  
        return response.ok ? data : Promise.reject(data);
      } catch (error) {
        console.error("Error:", error);
        throw error;
      }
    }
  );

  //tablelist


  export const TablelistlVm = createAsyncThunk(
    "post/TablelistlVm",
    async (props) => {
      const apiToken = cookies.get("apitoken");
      const username = cookies.get("username");
      try {
        const { payload, isRedirectedFromMail, refId, pageNumber, itemsPerPage } = props;
        
        const baseUrl = isRedirectedFromMail 
        ? `${proxy}/api/v1/tenableNessusRest/clickHereListForSearchWithPagination`
        : `${proxy}/api/v1/tenableNessusRest/getVulnerabilityListForCISOBySearchAndPaginationAndCount`;
      
      const url = isRedirectedFromMail 
        ? `${baseUrl}/${refId}/${pageNumber}/${itemsPerPage}`
        : `${baseUrl}/${pageNumber}/${itemsPerPage}`;
      
        const response = await fetch(url, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payload),
        });
  
        handleResponseCode(response);
        const data = await response.json();
  
        return response.ok ? data : Promise.reject(data);
      } catch (error) {
        console.error("Error:", error);
        throw error;
      }
    }
  );




export const TableCountVm = createAsyncThunk(
  "post/TableCountVm",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { payload, isRedirected, refId } = props;
      console.log("payload", payload);


      const baseUrl = isRedirected
        ? `${proxy}/api/v1/tenableNessusRest/clickHereListForSearchWithPaginationCount/${refId}`
        : `${proxy}/api/v1/tenableNessusRest/getVulnerabilityListCountForCISO`;

      const response = await fetch(baseUrl, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "DTOP-API-TOKEN": apiToken,
          USERNAME: username,
        },
        body: JSON.stringify(payload),
      });

      handleResponseCode(response); 
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

  



  //SHF

    //Request status

  
   export const StatusForVulSHF = createAsyncThunk(
        "post/StatusForVulSHF",
        async (payload) => {
          const apiToken = cookies.get("apitoken");
          const username = cookies.get("username");
          try {
            // const { payload } = props;
            const response = await fetch(
              `${proxy}/api/v1/aws/CISO/getCountByStatus`,
              {
                method: "POST",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                  "DTOP-API-TOKEN": apiToken,
                  USERNAME: username,
                },
                body: JSON.stringify(payload),
              }
            );
            handleResponseCode(response);
            const data = await response.json();
      
            return response.ok ? data : Promise.reject(data);
          } catch (error) {
            console.error("Error:", error);
            throw error;
          }
        }
    );


        //Application

  
        export const AppliactionForVulShf = createAsyncThunk(
          "post/AppliactionForVulShf",
          async (payload) => {
            const apiToken = cookies.get("apitoken");
            const username = cookies.get("username");
            try {
              // const { payload } = props;
              const response = await fetch(
                `${proxy}/api/v1/aws/appCodeCountForCISO`,
                {
                  method: "POST",
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "DTOP-API-TOKEN": apiToken,
                    USERNAME: username,
                  },
                  body: JSON.stringify(payload),
                }
              );
              handleResponseCode(response);
              const data = await response.json();
        
              return response.ok ? data : Promise.reject(data);
            } catch (error) {
              console.error("Error:", error);
              throw error;
            }
          }
        );

//action plan

export const ActionplanForShf = createAsyncThunk(
  "post/ActionplanForShf",
  async (payload) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      // const { payload } = props;
      const response = await fetch(
        `${proxy}/api/v1/aws/actionPlanCountsForCiso`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

export const TablelistlSHF = createAsyncThunk(
  "post/TablelistlSHF",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const {payload, pageNumber, itemsPerPage, isRedirectedFromMail, refId } = props;
      
      const baseUrl = isRedirectedFromMail 
      ? `${proxy}/api/v1/aws/clickHereAWSListForSearchWithPagination/`
      : `${proxy}/api/v1/aws/awsListForCISOWithSearch/`;
    
    const url = isRedirectedFromMail 
      ? `${baseUrl}/${refId}/${pageNumber}/${itemsPerPage}`
      : `${baseUrl}/${pageNumber}/${itemsPerPage}`;
    
      const response = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "DTOP-API-TOKEN": apiToken,
          USERNAME: username,
        },
        body: JSON.stringify(payload),
      });

      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

//count 

export const TableCountSHF = createAsyncThunk(
  "post/TableCountSHF",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { payload, isRedirected, refId } = props;
        const baseUrl = isRedirected
        ? `${proxy}/api/v1/aws/clickHereAWSListCount/${refId}`
        : `${proxy}/api/v1/aws/awsListForCISOWithSearchCount`;

      const response = await fetch(baseUrl, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "DTOP-API-TOKEN": apiToken,
          USERNAME: username,
        },
        body: JSON.stringify(payload),
      });

      handleResponseCode(response); 
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

//TRMG

// Request status

export const StatusForTRMG = createAsyncThunk(
  "post/StatusForTRMG",
  async (payload) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      // const { payload } = props;
      const response = await fetch(
        `${proxy}/api/v1/conformancePack/CISO/getCountByStatusForTRMG`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

// Application

export const ApplicationFoTRMG = createAsyncThunk(
  "post/ApplicationFoTRMG",
  async (payload) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      // const { payload } = props;
      const response = await fetch(
        `${proxy}/api/v1/conformancePack/CISO/AppCodeWiseCountForTRMG`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

//action plan 
export const ActionplanFoTRMG = createAsyncThunk(
  "post/ActionplanFoTRMG",
  async (payload) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      // const { payload } = props;
      const response = await fetch(
        `${proxy}/api/v1/conformancePack/CISO/actionPlanCountForTRMG`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

export const TablelistTRMG_C9 = createAsyncThunk(
  "post/TablelistTRMG_C9",
  async (props) => {
    console.log("calling")
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const {packName,payload, pageNumber, itemsPerPage, isRedirectedFromMail, refId } = props;

           
      const baseUrl = isRedirectedFromMail 
      ? `${proxy}/api/v1/conformancePack/getCISOListByRefId`
      : `${proxy}/api/v1/conformancePack/ListWithSearchAndPagination`;
    
    const url = isRedirectedFromMail 
      ? `${baseUrl}/${packName}/${refId}/${pageNumber}/${itemsPerPage}`
      : `${baseUrl}/${packName}/${pageNumber}/${itemsPerPage}`;
    
      const response = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "DTOP-API-TOKEN": apiToken,
          USERNAME: username,
        },
        body: JSON.stringify(payload),
      });

      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);


//count

export const TableCountTRMG_C9 = createAsyncThunk(
  "post/TableCountTRMG_C9",
  async (props) => {
    console.log("calling2")
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { packName,payload, isRedirected, refId } = props;
        const baseUrl = isRedirected
        ? `${proxy}/api/v1/conformancePack/getCISOListCountByRefId/${packName}/${refId}`
        : `${proxy}/api/v1/conformancePack/conformanceListForCISOWithSearchCount/${packName}`;

      const response = await fetch(baseUrl, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "DTOP-API-TOKEN": apiToken,
          USERNAME: username,
        },
        body: JSON.stringify(payload),
      });

      handleResponseCode(response); 
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);


//C9

//status chart

export const StatusForC9 = createAsyncThunk(
  "post/StatusForC9",
  async (payload) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      // const { payload } = props;
      const response = await fetch(
        `${proxy}/api/v1/conformancePack/CISO/getCountByStatusForCircularR09`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
//application

export const ApplicationforC9 = createAsyncThunk(
  "post/ApplicationforC9",
  async (payload) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      // const { payload } = props;
      const response = await fetch(
        `${proxy}/api/v1/conformancePack/CISO/AppCodeWiseCountForCircularR09`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

//actionplan 

export const ActionplanForC9 = createAsyncThunk(
  "post/ActionplanForC9",
  async (payload) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      // const { payload } = props;
      const response = await fetch(
        `${proxy}/api/v1/conformancePack/CISO/actionPlanCountForCircularR09`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

//RMiT
//Request status
export const StatusForRMiT = createAsyncThunk(
  "post/StatusForRMiT",
  async (payload) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      // const { payload } = props;
      const response = await fetch(
        `${proxy}/api/v1/awsRMITConfigController/CISO/getCountByStatusForRMIT`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

//Application 
export const ApplicationFoRMiT = createAsyncThunk(
  "post/ApplicationFoRMiT",
  async (payload) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      // const { payload } = props;
      const response = await fetch(
        `${proxy}/api/v1/awsRMITConfigController/CISO/getAppCodeWiseCountForRMIT`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

//Action Plan
export const ActionplanFoRMiT = createAsyncThunk(
  "post/ActionplanFoRMiT",
  async (payload) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      // const { payload } = props;
      const response = await fetch(
        `${proxy}/api/v1/awsRMITConfigController/CISO/actionPlanCountForRMIT`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

//Table List API
export const TablelistRMiT = createAsyncThunk(
  "post/TablelistRMiT",
  async (props) => {
    console.log("calling")
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const {packName,payload, pageNumber, itemsPerPage, isRedirectedFromMail, refId } = props;

           
      const baseUrl =`${proxy}/api/v1/awsRMITConfigController/ListWithSearchAndPagination`;
    
    const url =`${baseUrl}/${packName}/${pageNumber}/${itemsPerPage}`;
    
      const response = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "DTOP-API-TOKEN": apiToken,
          USERNAME: username,
        },
        body: JSON.stringify(payload),
      });

      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

//Table Count API
export const TableCountRMiT = createAsyncThunk(
  "post/TableCountRMiT",
  async (props) => {
    console.log("calling2")
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { packName,payload, isRedirected, refId } = props;
        const baseUrl = `${proxy}/api/v1/awsRMITConfigController/conformanceListForCISOWithSearchCount/${packName}`;

      const response = await fetch(baseUrl, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "DTOP-API-TOKEN": apiToken,
          USERNAME: username,
        },
        body: JSON.stringify(payload),
      });

      handleResponseCode(response); 
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

//CIS

//status chart

export const StatusForCIS = createAsyncThunk(
  "post/StatusForCIS",
  async (payload) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      // const { payload } = props;
      const response = await fetch(
        `${proxy}/api/v1/tenableNessusComplianceRest/CISO/getCountByStatusForCisBenchMark`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

//application

export const ApplicationforCIS = createAsyncThunk(
  "post/ApplicationforCIS",
  async (payload) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      // const { payload } = props;
      const response = await fetch(
        `${proxy}/api/v1/tenableNessusComplianceRest/ciscoGraph/application`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

// Action plan 


export const ActionplanForCIS = createAsyncThunk(
  "post/ActionplanForCIS",
  async (payload) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      // const { payload } = props;
      const response = await fetch(
        `${proxy}/api/v1/tenableNessusComplianceRest/CISO/CisComplianceByActionPlan`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
//list

export const TablelistlCIS = createAsyncThunk(
  "post/TablelistlCIS",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const {payload, pageNumber, itemsPerPage, isRedirectedFromMail, refId } = props;
      
      const baseUrl = isRedirectedFromMail 
      ? `/tenableNessusComplianceRest/getCISOListByReqId` 
      : `${proxy}/api/v1/tenableNessusComplianceRest/listForCISBenchMarkSearch`;
    
    const url = isRedirectedFromMail 
      ? `${baseUrl}/${refId}/${pageNumber}/${itemsPerPage}`
      : `${baseUrl}/${pageNumber}/${itemsPerPage}`;
    
      const response = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "DTOP-API-TOKEN": apiToken,
          USERNAME: username,
        },
        body: JSON.stringify(payload),
      });

      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);


//count

export const TableCountCIS = createAsyncThunk(
  "post/TableCountCIS",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { payload, isRedirected, refId } = props;
      console.log("payload", payload);


      const baseUrl = isRedirected
        ? `${proxy}/api/v1/tenableNessusComplianceRest/getCISOListCountByReqId${refId}`
        : `${proxy}/api/v1/tenableNessusComplianceRest/cisBenchMarkListPaginationCountForCISO`;

      const response = await fetch(baseUrl, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "DTOP-API-TOKEN": apiToken,
          USERNAME: username,
        },
        body: JSON.stringify(payload),
      });

      handleResponseCode(response); 
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);


//Application for Onpremise C09

export const AppliactionForOnpremise = createAsyncThunk(
  "post/AppliactionForOnpremise",
  async (payload) => { // directly use payload here
      const apiToken = cookies.get("apitoken");
      const username = cookies.get("username");
      try {
              const response = await fetch(
              `${proxy}/api/v1/CircularNineOnPremisesController/CISO/getAppCodeWiseCountForCircularR09ForOnPremises`,
              {
                  method: "POST",
                  headers: {
                      Accept: "application/json",
                      "Content-Type": "application/json",
                      "DTOP-API-TOKEN": apiToken,
                      USERNAME: username,
                  },
                  body: JSON.stringify(payload),
              }
          );
         // handleResponseCode(response);
          const data = await response.json();

          return response.ok ? data : Promise.reject(data);
      } catch (error) {
          console.error("Error:", error);
          throw error;
      }
  }
);

export const AppliactionForRiskAssessment = createAsyncThunk(
  "post/AppliactionForRiskAssessment",
  async (payload) => { // directly use payload here
      const apiToken = cookies.get("apitoken");
      const username = cookies.get("username");
      try {
              const response = await fetch(
              `${proxy}/api/v1/CircularNineOnPremisesController/CISO/getAppCodeWiseCountForAllPoliciesInCircularR09OnPremises`,
              {
                  method: "POST",
                  headers: {
                      Accept: "application/json",
                      "Content-Type": "application/json",
                      "DTOP-API-TOKEN": apiToken,
                      USERNAME: username,
                  },
                  body: JSON.stringify(payload),
              }
          );
         // handleResponseCode(response);
          const data = await response.json();

          return response.ok ? data : Promise.reject(data);
      } catch (error) {
          console.error("Error:", error);
          throw error;
      }
  }
);


//status for onpremise 

export const StatusForOnpremise = createAsyncThunk(
  "post/StatusForOnpremise",
  async (payload) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      // const { payload } = props;
      const response = await fetch(
        `${proxy}/api/v1/CircularNineOnPremisesController/CISO/getStatusCountForCircularR09ForOnPremises`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payload),
        }
      );
      //handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

export const StatusForRiskAssessment = createAsyncThunk(
  "post/StatusForRiskAssessment",
  async (payload) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      // const { payload } = props;
      const response = await fetch(
        `${proxy}/api/v1/CircularNineOnPremisesController/CISO/getStatusCountForAllPoliciesInCircularR09OnPremises`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payload),
        }
      );
      //handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

// On premise actionplan 

export const ActionplanForonpremiseC9 = createAsyncThunk(
  "post/ActionplanForonpremiseC9",
  async (payload) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      // const { payload } = props;
      const response = await fetch(
        `${proxy}/api/v1/CircularNineOnPremisesController/CISO/actionPlanCountForCircularR09ForOnPremises`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payload),
        }
      );
      // handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

export const ActionplanForonpremiseRA = createAsyncThunk(
  "post/ActionplanForonpremiseRA",
  async (payload) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      // const { payload } = props;
      const response = await fetch(
        `${proxy}/api/v1/CircularNineOnPremisesController/CISO/actionPlanCountForAllPoliciesInCircularR09OnPremises`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payload),
        }
      );
      // handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

//On- premise  Compliant drilldown
export const OPCisoListApi = createAsyncThunk(
  "post/OPCisoListApi",
  async (props) => {
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    try {
      const { payload, pageNumber, itemsPerPage } = props;
      const response = await fetch(
        `${proxy}/api/v1/CircularNineOnPremisesController/getCircularNineCISOList/${pageNumber}/${itemsPerPage}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": Apitoken,
            USERNAME: User,
          },
          body: JSON.stringify(payload),
        }
      );
//handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
export const RACisoListApi = createAsyncThunk(
  "post/RACisoListApi",
  async (props) => {
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    try {
      const { payload, pageNumber, itemsPerPage } = props;
      const response = await fetch(
        `${proxy}/api/v1/CircularNineOnPremisesController/getCircularNineCISOListForAllPolicies/${pageNumber}/${itemsPerPage}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": Apitoken,
            USERNAME: User,
          },
          body: JSON.stringify(payload),
        }
      );
//handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

//On-premise  Compliant count API
export const OPCisoCountAPI = createAsyncThunk(
  "post/OPCisoCountAPI",
  async (props) => {
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    try {
      const { payload } = props;
      const response = await fetch(
        `${proxy}/api/v1/CircularNineOnPremisesController/getCircularNineCISOListCount`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": Apitoken,
            USERNAME: User,
          },
          body: JSON.stringify(payload),
        }
      );
     // handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

//Onpremise C09 approve/reject

export const ApproveRejectC9Op = createAsyncThunk(
  "post/ApproveRejectC9Op",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { payload } = props;
      const response = await fetch(
        `${proxy}/api/v1/CircularNineOnPremisesController/circularNineApprovedRejected`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();
 
      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
 
 
export const getDetailsofOnPremise = createAsyncThunk(
  "post/getDetailsofOnPremise",
  async (props) => {
    const {complianceId} = props;
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const response = await fetch(
        `${proxy}/api/v1/CircularNineOnPremisesController/getCircularNineOnPremiseCommentList/${complianceId}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": apiToken,
            USERNAME: username,
          },
          // body: JSON.stringify(divisionPayload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();
 
      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);





    const initialState ={

      RequestStatusCISOData:[],
      RequestStatusCISODataLoading:false,
      ApplicationCISOData:[],
      ApplicationCISODataLoading:false,
      ActionplanCISOData:[],
      ActionplanCISODataLoading:false,
      TableVMListData:[],
      TableVMListDataloading:false,
      VMTablecountData:[],
      VMTablecountDataLoading:false,

      
    
      RequestStatusShfData:[],
      RequestStatusShfLoading:false,
      ApplicationShfData:[],
      ApplicationShfDataLoading:false,
      ActionplanForShfData:[],
      ActionplanForShfDataLoading:false,
      TableSHFListData:[],
      TableSHFListDataloading:false,
      SHFTablecountData:[],
      SHFTablecountDataLoading:false,



      RequestStatusTRMGData:[],
      RequestStatusTRMGLoading:false,
      ApplicationTRMGData:[],
      ApplicationTRMGLoading:false,
      ActionplanForTRMGData:[],
      ActionplanForTRMGLoading:false,
      Trmg_C9TableListData:[],
      Trmg_C9TableListDataloading:false,
      Trmg_c9TablecountData:[],
      Trmg_c9TablecountDataLoading:false,

      RequestStatusC9Data:[],
      RequestStatusC9Loading:false,
      ApplicationC9Data:[],
      ApplicationC9Loading:false,
      ActionplanForC9Data:[],
      ActionplanForC9Loading:false,

      RequestStatusCISData:[],
      RequestStatusCISLoading:false,
      ApplicationCISData:[],
      ApplicationCISLoading:false,
      ActionplanForCISData:[],
      ActionplanForCISLoading:false,
      TableCISListData:[],
      TableCISListDataloading:false,
      CISTablecountData:[],
      CISTablecountDataLoading:false,
      RequestStatusRMitLoading : false,
      RequestStatusRMiTData : [],
      ApplicationRMiTLoading : false,
      ApplicationRMitData : [],
      ActionplanForRMiTLoading : false,
      ActionplanForRMiTData : [],
      RMiTTableListData : [],
      RMiTTableListDataloading : false,
      RMiTTablecountDataLoading : false,
      RMiTTablecountData : [],


      ApplicationOnpremiseDataLoading:false,
      ApplicationOnpremiseData:[],


      StatusOnpremiseDataLoading:false,
      StatusOnpremiseData:[],

      ActionplanForOnpremiseC9Loading:false,
      ActionplanForOPC9Data:[],

      OPCisoListData:[],
      OPCisoListDataLoading:false,

      OPCisoCountDataLoading:false,
      OPCisoCountData:[],

      approve_rejectDataLoading:false,
      approve_rejectData:[],

      DetailsData:[],
      DetailsLoading:false,





    


    }


    export const CISOSlice = createSlice({
      name: "CISOSlice",
      initialState,
    
      reducers: {
        resetCISOData: () => {
          return initialState;
        }
      },
      extraReducers: (builder) => {
        builder

          //request status
          .addCase(StatusForVulVm.pending, (state, action) => {
            state.RequestStatusCISODataLoading = true;
          })
          .addCase(StatusForVulVm.fulfilled, (state, action) => {
            state.RequestStatusCISOData = action.payload;
            state.RequestStatusCISODataLoading = false;
          })
          .addCase(StatusForVulVm.rejected, (state, action) => {
            state.RequestStatusCISOData = [];
            state.RequestStatusCISODataLoading = false;
          })

          //Application VM
          .addCase(AppliactionForVulVm.pending, (state, action) => {
            state.ApplicationCISODataLoading = true;
          })
          .addCase(AppliactionForVulVm.fulfilled, (state, action) => {
            state.ApplicationCISOData = action.payload;
            state.ApplicationCISODataLoading = false;
          })
          .addCase(AppliactionForVulVm.rejected, (state, action) => {
            state.ApplicationCISOData = [];
            state.ApplicationCISODataLoading = false;
          })

          //Action plan Vm

          .addCase(ActionPlanForVulVm.pending, (state, action) => {
            state.ActionplanCISODataLoading = true;
          })
          .addCase(ActionPlanForVulVm.fulfilled, (state, action) => {
            state.ActionplanCISOData = action.payload;
            state.ActionplanCISODataLoading = false;
          })
          .addCase(ActionPlanForVulVm.rejected, (state, action) => {
            state.ActionplanCISOData = [];
            state.ActionplanCISODataLoading = false;
          })

          //table list

          .addCase(TablelistlVm.pending, (state, action) => {
            state.TableVMListDataloading = true;
          })
          .addCase(TablelistlVm.fulfilled, (state, action) => {
            state.TableVMListData = action.payload;
            state.TableVMListDataloading = false;
          })
          .addCase(TablelistlVm.rejected, (state, action) => {
            state.TableVMListData = [];
            state.TableVMListDataloading = false;
          })

          //count

          .addCase(TableCountVm.pending, (state, action) => {
            state.VMTablecountDataLoading = true;
          })
          .addCase(TableCountVm.fulfilled, (state, action) => {
            state.VMTablecountData = action.payload;
            state.VMTablecountDataLoading = false;
          })
          .addCase(TableCountVm.rejected, (state, action) => {
            state.VMTablecountData = [];
            state.VMTablecountDataLoading = false;
          })


      


            //SHF

          //status chart shf

          .addCase(StatusForVulSHF.pending, (state, action) => {
            state.RequestStatusShfLoading = true;
          })
          .addCase(StatusForVulSHF.fulfilled, (state, action) => {
            state.RequestStatusShfData = action.payload;
            state.RequestStatusShfLoading = false;
          })
           .addCase(StatusForVulSHF.rejected, (state, action) => {
            state.RequestStatusShfData = [];
            state.RequestStatusShfLoading = false;
          })

          //Application
        
          .addCase(AppliactionForVulShf.pending, (state, action) => {
            state.ApplicationShfDataLoading = true;
          })
          .addCase(AppliactionForVulShf.fulfilled, (state, action) => {
            state.ApplicationShfData = action.payload;
            state.ApplicationShfDataLoading = false;
          })
          .addCase(AppliactionForVulShf.rejected, (state, action) => {
            state.ApplicationShfData = [];
            state.ApplicationShfDataLoading = false;
          })
          

          //actionplan shf

          .addCase(ActionplanForShf.pending, (state, action) => {
            state.ActionplanForShfDataLoading = true;
          })
          .addCase(ActionplanForShf.fulfilled, (state, action) => {
            state.ActionplanForShfData = action.payload;
            state.ActionplanForShfDataLoading = false;
          })
          .addCase(ActionplanForShf.rejected, (state, action) => {
            state.ActionplanForShfData = [];
            state.ActionplanForShfDataLoading = false;
          })

          //list

          .addCase(TablelistlSHF.pending, (state, action) => {
            state.TableSHFListDataloading = true;
          })
          .addCase(TablelistlSHF.fulfilled, (state, action) => {
            state.TableSHFListData = action.payload;
            state.TableSHFListDataloading = false;
          })
          .addCase(TablelistlSHF.rejected, (state, action) => {
            state.TableSHFListData = [];
            state.TableSHFListDataloading = false;
          })

          //COUNT 

          .addCase(TableCountSHF.pending, (state, action) => {
            state.SHFTablecountDataLoading = true;
          })
          .addCase(TableCountSHF.fulfilled, (state, action) => {
            state.SHFTablecountData = action.payload;
            state.SHFTablecountDataLoading = false;
          })
          .addCase(TableCountSHF.rejected, (state, action) => {
            state.SHFTablecountData = [];
            state.SHFTablecountDataLoading = false;
          })


          //TRMG

          //status chart

          .addCase(StatusForTRMG.pending, (state, action) => {
            state.RequestStatusTRMGLoading = true;
          })
          .addCase(StatusForTRMG.fulfilled, (state, action) => {
            state.RequestStatusTRMGData = action.payload;
            state.RequestStatusTRMGLoading = false;
          })
           .addCase(StatusForTRMG.rejected, (state, action) => {
            state.RequestStatusTRMGData = [];
            state.RequestStatusTRMGLoading = false;
          })

          //application chart

             .addCase(ApplicationFoTRMG.pending, (state, action) => {
              state.ApplicationTRMGLoading = true;
            })
            .addCase(ApplicationFoTRMG.fulfilled, (state, action) => {
              state.ApplicationTRMGData = action.payload;
              state.ApplicationTRMGLoading = false;
            })
             .addCase(ApplicationFoTRMG.rejected, (state, action) => {
              state.ApplicationTRMGData = [];
              state.ApplicationTRMGLoading = false;
            })

            //action plan

            .addCase(ActionplanFoTRMG.pending, (state, action) => {
              state.ActionplanForTRMGLoading = true;
            })
            .addCase(ActionplanFoTRMG.fulfilled, (state, action) => {
              state.ActionplanForTRMGData = action.payload;
              state.ActionplanForTRMGLoading = false;
            })
             .addCase(ActionplanFoTRMG.rejected, (state, action) => {
              state.ActionplanForTRMGData = [];
              state.ActionplanForTRMGLoading = false;
             })

               //list

          .addCase(TablelistTRMG_C9.pending, (state, action) => {
            state.Trmg_C9TableListDataloading = true;
          })
          .addCase(TablelistTRMG_C9.fulfilled, (state, action) => {
            state.Trmg_C9TableListData = action.payload;
            state.Trmg_C9TableListDataloading = false;
          })
          .addCase(TablelistTRMG_C9.rejected, (state, action) => {
            state.Trmg_C9TableListData = [];
            state.Trmg_C9TableListDataloading = false;
          })



          //COUNT 

          .addCase(TableCountTRMG_C9.pending, (state, action) => {
            state.Trmg_c9TablecountDataLoading = true;
          })
          .addCase(TableCountTRMG_C9.fulfilled, (state, action) => {
            state.Trmg_c9TablecountData = action.payload;
            state.Trmg_c9TablecountDataLoading = false;
          })
          .addCase(TableCountTRMG_C9.rejected, (state, action) => {
            state.Trmg_c9TablecountData = [];
            state.Trmg_c9TablecountDataLoading = false;
          })

          //RMiT
          //Status Chart
          .addCase(StatusForRMiT.pending, (state, action) => {
            state.RequestStatusRMitLoading = true;
          })
          .addCase(StatusForRMiT.fulfilled, (state, action) => {
            state.RequestStatusRMiTData = action.payload;
            state.RequestStatusRMitLoading = false;
          })
           .addCase(StatusForRMiT.rejected, (state, action) => {
            state.RequestStatusRMiTData = [];
            state.RequestStatusRMitLoading = false;
          })

          //Application Chart
          .addCase(ApplicationFoRMiT.pending, (state, action) => {
            state.ApplicationRMiTLoading = true;
          })
          .addCase(ApplicationFoRMiT.fulfilled, (state, action) => {
            state.ApplicationRMitData = action.payload;
            state.ApplicationRMiTLoading = false;
          })
           .addCase(ApplicationFoRMiT.rejected, (state, action) => {
            state.ApplicationRMitData = [];
            state.ApplicationRMiTLoading = false;
          })
          //action plan

          .addCase(ActionplanFoRMiT.pending, (state, action) => {
            state.ActionplanForRMiTLoading = true;
          })
          .addCase(ActionplanFoRMiT.fulfilled, (state, action) => {
            state.ActionplanForRMiTData = action.payload;
            state.ActionplanForRMiTLoading = false;
          })
           .addCase(ActionplanFoRMiT.rejected, (state, action) => {
            state.ActionplanForRMiTData = [];
            state.ActionplanForRMiTLoading = false;
           })

          //List
          .addCase(TablelistRMiT.pending, (state, action) => {
            state.RMiTTableListDataloading = true;
          })
          .addCase(TablelistRMiT.fulfilled, (state, action) => {
            state.RMiTTableListData = action.payload;
            state.RMiTTableListDataloading = false;
          })
          .addCase(TablelistRMiT.rejected, (state, action) => {
            state.RMiTTableListData = [];
            state.RMiTTableListDataloading = false;
          })

          //COUNT
          .addCase(TableCountRMiT.pending, (state, action) => {
            state.RMiTTablecountDataLoading = true;
          })
          .addCase(TableCountRMiT.fulfilled, (state, action) => {
            state.RMiTTablecountData = action.payload;
            state.RMiTTablecountDataLoading = false;
          })
          .addCase(TableCountRMiT.rejected, (state, action) => {
            state.RMiTTablecountData = [];
            state.RMiTTablecountDataLoading = false;
          })
             //C9

             //status
          .addCase(StatusForC9.pending, (state, action) => {
            state.RequestStatusC9Loading = true;
          })
          .addCase(StatusForC9.fulfilled, (state, action) => {
            state.RequestStatusC9Data = action.payload;
            state.RequestStatusC9Loading = false;
          })
           .addCase(StatusForC9.rejected, (state, action) => {
            state.RequestStatusC9Data = [];
            state.RequestStatusSRequestStatusC9LoadinghfLoading = false;
          })

          //applicaton
          .addCase(ApplicationforC9.pending, (state, action) => {
            state.ApplicationC9Loading = true;
          })
          .addCase(ApplicationforC9.fulfilled, (state, action) => {
            state.ApplicationC9Data = action.payload;
            state.ApplicationC9Loading = false;
          })
           .addCase(ApplicationforC9.rejected, (state, action) => {
            state.ApplicationC9Data = [];
            state.ApplicationC9Loading = false;
          })
          
          //action plan

          .addCase(ActionplanForC9.pending, (state, action) => {
            state.ActionplanForC9Loading = true;
          })
          .addCase(ActionplanForC9.fulfilled, (state, action) => {
            state.ActionplanForC9Data = action.payload;
            state.ActionplanForC9Loading = false;
          })
           .addCase(ActionplanForC9.rejected, (state, action) => {
            state.ActionplanForC9Data = [];
            state.ActionplanForC9Loading = false;
           })

           //Cis 

           //status
           .addCase(StatusForCIS.pending, (state, action) => {
            state.RequestStatusCISLoading = true;
          })
          .addCase(StatusForCIS.fulfilled, (state, action) => {
            state.RequestStatusCISData = action.payload;
            state.RequestStatusCISLoading = false;
          })
           .addCase(StatusForCIS.rejected, (state, action) => {
            state.RequestStatusCISData = [];
            state.RequestStatusCISLoading = false;
          })

            //applicaton
            .addCase(ApplicationforCIS.pending, (state, action) => {
              state.ApplicationCISLoading = true;
            })
            .addCase(ApplicationforCIS.fulfilled, (state, action) => {
              state.ApplicationCISData = action.payload;
              state.ApplicationCISLoading = false;
            })
             .addCase(ApplicationforCIS.rejected, (state, action) => {
              state.ApplicationCISData = [];
              state.ApplicationCISLoading = false;
            })

            //ACTION PLAN 


            .addCase(ActionplanForCIS.pending, (state, action) => {
              state.ActionplanForCISLoading = true;
            })
            .addCase(ActionplanForCIS.fulfilled, (state, action) => {
              state.ActionplanForCISData = action.payload;
              state.ActionplanForCISLoading = false;
            })
             .addCase(ActionplanForCIS.rejected, (state, action) => {
              state.ActionplanForCISData = [];
              state.ActionplanForCISLoading = false;
             })

             
          //table list

          .addCase(TablelistlCIS.pending, (state, action) => {
            state.TableCISListDataloading = true;
          })
          .addCase(TablelistlCIS.fulfilled, (state, action) => {
            state.TableCISListData = action.payload;
            state.TableCISListDataloading = false;
          })
          .addCase(TablelistlCIS.rejected, (state, action) => {
            state.TableCISListData = [];
            state.TableCISListDataloading = false;
          })

          //count

          .addCase(TableCountCIS.pending, (state, action) => {
            state.CISTablecountDataLoading = true;
          })
          .addCase(TableCountCIS.fulfilled, (state, action) => {
            state.CISTablecountData = action.payload;
            state.CISTablecountDataLoading = false;
          })
          .addCase(TableCountCIS.rejected, (state, action) => {
            state.VMTablecountData = [];
            state.CISTablecountDataLoading = false;
          })


               //Application onpremise
               .addCase(AppliactionForOnpremise.pending, (state, action) => {
                state.ApplicationOnpremiseDataLoading = true;
              })
              .addCase(AppliactionForOnpremise.fulfilled, (state, action) => {
                state.ApplicationOnpremiseData = action.payload;
                state.ApplicationOnpremiseDataLoading = false;
              })
              .addCase(AppliactionForOnpremise.rejected, (state, action) => {
                state.ApplicationOnpremiseData = [];
                state.ApplicationOnpremiseDataLoading = false;
              })

              .addCase(AppliactionForRiskAssessment.pending, (state, action) => {
                state.ApplicationOnpremiseDataLoading = true;
              })
              .addCase(AppliactionForRiskAssessment.fulfilled, (state, action) => {
                state.ApplicationOnpremiseData = action.payload;
                state.ApplicationOnpremiseDataLoading = false;
              })
              .addCase(AppliactionForRiskAssessment.rejected, (state, action) => {
                state.ApplicationOnpremiseData = [];
                state.ApplicationOnpremiseDataLoading = false;
              })
               //Status onpremise
               .addCase(StatusForOnpremise.pending, (state, action) => {
                state.StatusOnpremiseDataLoading = true;
              })
              .addCase(StatusForOnpremise.fulfilled, (state, action) => {
                state.StatusOnpremiseData = action.payload;
                state.StatusOnpremiseDataLoading = false;
              })
              .addCase(StatusForOnpremise.rejected, (state, action) => {
                state.StatusOnpremiseData = [];
                state.StatusOnpremiseDataLoading = false;
              })

              .addCase(StatusForRiskAssessment.pending, (state, action) => {
                state.StatusOnpremiseDataLoading = true;
              })
              .addCase(StatusForRiskAssessment.fulfilled, (state, action) => {
                state.StatusOnpremiseData = action.payload;
                state.StatusOnpremiseDataLoading = false;
              })
              .addCase(StatusForRiskAssessment.rejected, (state, action) => {
                state.StatusOnpremiseData = [];
                state.StatusOnpremiseDataLoading = false;
              })

              //onpremise actionplan

              .addCase(ActionplanForonpremiseC9.pending, (state, action) => {
                state.ActionplanForOnpremiseC9Loading = true;
              })
              .addCase(ActionplanForonpremiseC9.fulfilled, (state, action) => {
                state.ActionplanForOPC9Data = action.payload;
                state.ActionplanForOnpremiseC9Loading = false;
              })
               .addCase(ActionplanForonpremiseC9.rejected, (state, action) => {
                state.ActionplanForOPC9Data = [];
                state.ActionplanForOnpremiseC9Loading = false;
               })

               .addCase(ActionplanForonpremiseRA.pending, (state, action) => {
                state.ActionplanForOnpremiseC9Loading = true;
              })
              .addCase(ActionplanForonpremiseRA.fulfilled, (state, action) => {
                state.ActionplanForOPC9Data = action.payload;
                state.ActionplanForOnpremiseC9Loading = false;
              })
               .addCase(ActionplanForonpremiseRA.rejected, (state, action) => {
                state.ActionplanForOPC9Data = [];
                state.ActionplanForOnpremiseC9Loading = false;
               })


               // ciso on premise  details

               .addCase(OPCisoListApi.pending, (state, action) => {
                state.OPCisoListDataLoading = true;
              })
              .addCase(OPCisoListApi.fulfilled, (state, action) => {
                state.OPCisoListData = action.payload;
                state.OPCisoListDataLoading = false;
              })
              .addCase(OPCisoListApi.rejected, (state, action) => {
                state.OPCisoListData = [];
                state.OPCisoListDataLoading = false;
              })

              .addCase(RACisoListApi.pending, (state, action) => {
                state.OPCisoListDataLoading = true;
              })
              .addCase(RACisoListApi.fulfilled, (state, action) => {
                state.OPCisoListData = action.payload;
                state.OPCisoListDataLoading = false;
              })
              .addCase(RACisoListApi.rejected, (state, action) => {
                state.OPCisoListData = [];
                state.OPCisoListDataLoading = false;
              })

              //ciso on premise count

              
              .addCase(OPCisoCountAPI.pending, (state, action) => {
                state.OPCisoCountDataLoading = true;
              })
              .addCase(OPCisoCountAPI.fulfilled, (state, action) => {
                state.OPCisoCountData = action.payload;
                state.OPCisoCountDataLoading = false;
              })
              .addCase(OPCisoCountAPI.rejected, (state, action) => {
                state.OPCisoCountData = [];
                state.OPCisoCountDataLoading = false;
              })

              //Onpremise Approve/Rejecet

              .addCase(ApproveRejectC9Op.pending, (state, action) => {
                state.approve_rejectDataLoading = true;
              })
              .addCase(ApproveRejectC9Op.fulfilled, (state, action) => {
                state.approve_rejectData = action.payload;
                state.approve_rejectDataLoading = false;
              })
              .addCase(ApproveRejectC9Op.rejected, (state, action) => {
                state.approve_rejectData = [];
                state.approve_rejectDataLoading = false;
              })

              //Details onpremise

                
                   .addCase(getDetailsofOnPremise.pending, (state, action) => {
                    state.DetailsLoading = true;
                  })
                  .addCase(getDetailsofOnPremise.fulfilled, (state, action) => {
                    state.DetailsData = action.payload;
                    state.DetailsLoading = false;
                  })
                  .addCase(getDetailsofOnPremise.rejected, (state, action) => {
                    state.DetailsData = [];
                    state.DetailsLoading = false;
                  })
    







      },
  });


export const { resetCISOData } = CISOSlice.actions;
export default CISOSlice.reducer;