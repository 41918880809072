import React from "react";
import {
  observability,
  gnc,
  efficiency,
  configuration,
  house,
  Architecture,
  UserAccessReviewTab,
  Inventory,
  CloudInsightsCloud,
  RAMP
} from "../assets/images/index";
const SignIn = React.lazy(() => import("../app/auth/SignIn.js"));
const LicenseBackend = React.lazy(() => import("../app/auth/LicenseBackend.js"));
const ConfigurationPage = React.lazy(() => import("../app/auth/ConfigurationPage.js"));
const HomePage = React.lazy(() =>
  import("../Home/HomePageLanding")
);

const FlowMetrics = React.lazy(() =>
  import("../DevopsInsights/Observability/flowMetrics/FlowMetricsLanding")
);
const PeopleMetrics = React.lazy(() =>
  import("../DevopsInsights/Observability/PeopleMetricsObs/Landing")
);

const ProductivityMetricsObs = React.lazy(() =>
  import(
    "../DevopsInsights/Observability/ProductivityMetricsObs/Landing.js"
  )
);

const Manageopsmetrics = React.lazy(() =>
  import("../DevopsInsights/Observability/opsMetrics/Manageopsmetrics")
);
const CloudCostInsights = React.lazy(() =>
  import("../gnc/CloudCostInsights/Landing.js")
);
const LicenseManagement = React.lazy(() =>
  import("../gnc/LicenseManagement/LincenseLanding")
);

const Pii = React.lazy(() => import("../gnc/Compliance/Pii/PiiLandingPage.js"));
const Vulnerabilites = React.lazy(() =>
  import("../gnc/Compliance/Vulnerabilites/VulnerabilitesLanding.js")
);
const CISBenchmarkCompliance = React.lazy(() =>
  import("../gnc/Compliance/CISBenchmarkCompliance/CISBenchmarkComplianceLanding")
);

const CisoC09DBoardManagement = React.lazy(() =>
  import("../gnc/cisoDashboard/ManageCisoC09.js")
);

const OnprimseCisoLandingPage = React.lazy(() =>
  import("../gnc/cisoDashboard/OnPremiseCisolanding.js")
);
const CisoDBoardManagement = React.lazy(() =>
  import("../gnc/cisoDashboard/CisoLandingPage.js")
);
const CisoSHFLanding = React.lazy(() =>
  import("../gnc/cisoDashboard/CisoSHFLanding.js")
);
const CisoTRMGLanding = React.lazy(() =>
  import("../gnc/cisoDashboard/CisoTRMGLanding.js")
);
const CisoRMITLanding = React.lazy(() =>
  import("../gnc/cisoDashboard/CisoRMITLanding.js")
);
const CisoLandingCircular09 = React.lazy(() =>
  import("../gnc/cisoDashboard/CisoLandingCircular09.js")
);
const CisoLandingCIS = React.lazy(() =>
  import("../gnc/cisoDashboard/CisoLandingCIS.js")
);

const GNCLanding = React.lazy(() =>
  import("../gnc/Compliance/Summary/GNCLanding.js")
);
const SBOMLanding = React.lazy(() =>
  import("../DevopsInsights/Observability/SBOM/SBOMLanding.js")
);
const CISOSBOMLanding = React.lazy(() =>
  import("../DevopsInsights/Observability/CISOSBOM/CISOSBOMLanding.js"));
const TRMGLanding = React.lazy(() =>
  import("../gnc/RegulatoryCompliance/TRMG/TRMGLanding.js")
);
const RMiTLanding = React.lazy(() =>
  import("../gnc/RegulatoryCompliance/RMiT/RMiTLanding.js")
);
const ManagementC09Complaince = React.lazy(() =>
  import("../gnc/RegulatoryCompliance/circular9/C09ComplianceManage.js")
);
const SecurityHubFindings = React.lazy(() =>
  import(
    "../gnc/Compliance/securityHub/securityHubFindingsLandings"
  )
);


const CreateRiskAssessment = React.lazy(() =>
  import("../gnc/Compliance/CreateRiskAssessment/ManageRiskAssessment.js")
);

const AddRiskAssessment = React.lazy(() =>
  import("../gnc/Compliance/CreateRiskAssessment/AssessmentAddRisk.js")
);

const TopicName = React.lazy(() =>
  import("../gnc/Compliance/CreateRiskAssessment/TopicName.js")
);

const ClausePage = React.lazy(() =>
  import("../gnc/Compliance/CreateRiskAssessment/Clause.js")
)

const SubTopicNameAssessment = React.lazy(() =>
  import("../gnc/Compliance/CreateRiskAssessment/TopicSubName.js")
);

const QuestionsAssessment = React.lazy(() =>
  import("../gnc/Compliance/CreateRiskAssessment/AssessmentQuestion.js")
);

const RiskAssesmentQuestion = React.lazy(() =>
  import("../gnc/Compliance/CreateRiskAssessment/QuestionAssessmentRisk.js")
);

const UserAccessManagement = React.lazy(() =>
  import("../gnc/cisoDashboard/CisoUserAccess/cisouseraccesslanding.js")
);
// let currentRole = '';
// export const UserRoleForNavBar = (userRole) => {
// console.log(userRole);
// currentRole = userRole;
// };

// console.log(currentRole);

// compliance Landing

const AppConfig = React.lazy(() =>
  import("../configuration/cmdb/CmdbConfig.js")
);
const AppConfigProjects = React.lazy(() =>
  import("../configuration/cmdb/Projects.js")
);
const AppConfigServers = React.lazy(() =>
  import("../configuration/cmdb/cmdbChild/Device.js")
);
const LicenseConfigpage = React.lazy(() =>
  import("../configuration/cmdb/cmdbMaster/OsLicenseConfig.js")
);

const ApplicationConfiguration = React.lazy(() =>
  import("../configuration/cmdb/cmdbChild/CmdbApplicationConfiguration.js")
);
const AppConfigUsers = React.lazy(() =>
  import("../configuration/cmdb/cmdbChild/User.js")
);
const AppConfigAccountId = React.lazy(() =>
  import("../configuration/cmdb/cmdbChild/CloudAccounts.js")
);
const AuditLog = React.lazy(() =>
  import("../configuration/auditLog/AuditLog.js")
);

const Tools = React.lazy(() =>
  import("../configuration/admin/tools/Tools")
);

const Business = React.lazy(() =>
  import("../configuration/cmdb/cmdbMaster/BusinessInfoConfig.js")
);

const RoleAllocation = React.lazy(() =>
  import(
    "../configuration/cmdb/cmdbMaster/RoleAllocation/OrganizationalInfo.js"
  )
);

const User = React.lazy(() =>
  import("../configuration/cmdb/cmdbMaster/UserInfoConfig.js")
);

const DevicesConfig = React.lazy(() =>
  import("../configuration/cmdb/cmdbMaster/Serverconfig.js")
);
const CloudAccount = React.lazy(() =>
  import("../configuration/cmdb/cmdbMaster/cloudaccount.js")
);
//Tools coonfiguration Roles
const BambooRoles = React.lazy(() =>
  import(
    "../configuration/admin/tools/configurationRoles/BambooRoles"
  )
);
const BitBucketRoles = React.lazy(() =>
  import(
    "../configuration/admin/tools/configurationRoles/BitBucketRoles"
  )
);
const ConfluenceCloudRole = React.lazy(() =>
  import(
    "../configuration/admin/tools/configurationRoles/ConfluenceCloudRole"
  )
);
const JFrogPermission = React.lazy(() =>
  import(
    "../configuration/admin/tools/configurationRoles/JFrogPermission"
  )
);
const JenkinRoles = React.lazy(() =>
  import(
    "../configuration/admin/tools/configurationRoles/JenkinRoles"
  )
);
const JiraCloudRoles = React.lazy(() =>
  import(
    "../configuration/admin/tools/configurationRoles/JiraCloudRoles"
  )
);
const JiraRoles = React.lazy(() =>
  import(
    "../configuration/admin/tools/configurationRoles/JiraRoles"
  )
);
const NexusRoles = React.lazy(() =>
  import(
    "../configuration/admin/tools/configurationRoles/NexusRoles"
  )
);
const SonarQubeRoles = React.lazy(() =>
  import(
    "../configuration/admin/tools/configurationRoles/SonarQubeRoles"
  )
);
// const Groups = React.lazy(() =>
//   import("../configuration/admin/groups/Groups")
// );
const PIIDataConfiguration = React.lazy(() =>
  import(
    "../configuration/admin/PIIConfiguration/PIIConfigurationContainer"
  )
);

const PrivilegeConfiguration = React.lazy(() =>
  import(
    "../configuration/admin/Policy Categorization/PolicyPriviledge.js"
  )
);

const Schedulers = React.lazy(() =>
  import(
    "../configuration/admin/Schedulers/ManageSchedulers.js"
  )
);
const LdapConfig = React.lazy(() =>
  import("../configuration/settings/ldapConfig/LdapConfig.js")
);
const SmtpConfig = React.lazy(() =>
  import("../configuration/settings/smtpConfig/SmtpConfig.js")
);
const LicenseConfig = React.lazy(() =>
  import("../configuration/settings/licenseConfig/LicenseConfig.js")
);
const UserManagement = React.lazy(() =>
  import("../configuration/admin/userManagement/UserManagement")
);
const LoggingConfig = React.lazy(() =>
  import("../configuration/settings/loggingConfig/LoggingConfig.js")
);
const RiskAssessement = React.lazy(() =>
  import("../configuration/admin/riskAssessement/RiskAssessementLanding")
);

const UserAccessReview = React.lazy(() =>
  import("../Home/ApplicationUserAccessReview/userReviewLanding.js")
);

//
const ArchitectureLanding = React.lazy(() =>
  import("../ArchitectureCompliance/Compliance/ArchitectureComplianceLanding.js")
);

const ArchitectureDiagramLanding = React.lazy(() =>
  import("../ArchitectureCompliance/CreateDiagram/DiagramLanding.js")
);

const UserGraph = React.lazy(() =>
  import("../CloudAccessGovernance/UserGraphs/UserGraphLanding.js")
)

const UserReview = React.lazy(() =>
  import("../CloudAccessGovernance/AccessReview/UserReviewLanding.js")
)


const InventoryServer = React.lazy(() =>
  import("../Inventory/Server/Server.js")
);

const InventoryProject = React.lazy(() =>
  import("../Inventory/Server/Projects.js")
);
const CreateRiskAssessmentLandings = React.lazy(() =>
  import("../gnc/Compliance/CreateRiskAssessment/ComplianceAssessmentLandingPage.js")
);
const Onpremiseaccounts = React.lazy(() =>
  import("../gnc/RegulatoryCompliance/circular9/Circular9OnpremiseLanding.js")
);
export const authRoutes = [
  {
    name: "SignIn",
    path: "/logout",
    component: <SignIn />,
    key: 1,
  },
  {
    name: "SignIn",
    path: "/auth",
    component: <SignIn />,
    key: 2,
  },
  {
    name: "ConfigurationPage",
    path: "/ConfigurationPage",
    component: <ConfigurationPage />,
    key: 3,
  },
  {
    name: "LicenseBackend",
    path: "/LicenseBackend",
    component: <LicenseBackend />,
    key: 4,
  },
];

export const routes = [
  //CODE8 Home page
  {
    name: "Home",
    path: "home",
    component: <HomePage />,
    key: "5",
    icon: house,
    iconNavigation: true,
    childNavs: [
      {
        name: "Home",
        text: "Home",
        path: "home",
        component: <HomePage />,
        key: "5.1",
        navigationDisable: true,
        isNotSideBarNavigation: true,
      },
      // ]

      {
        name: "Application User Access Review",
        path: "home/userReview",
        component: <UserAccessReview />,
        key: "5.2",
        isNotSideBarNavigation: true,
      },
      {
        name: "Business Info",
        path: "home/BusinessInfoConfig",
        component: <Business />,
        key: "5.3",
        isNotSideBarNavigation: true,
      },
      {
        name: "Server",
        path: "home/:appName/DeviceConfig",
        isNotSideBarNavigation: true,
        key: "5.4",
        component: <AppConfigServers />,
      },
      {
        name: "Account IDs",
        path: "home/:appName/accountIDs",
        isNotSideBarNavigation: true,
        key: "5.5",
        component: <AppConfigAccountId />,
      },
      {
        name: "Users",
        path: "home/:appName/users",
        isNotSideBarNavigation: true,
        key: "5.6",
        component: <AppConfigUsers />,
      },
    ],
  },

  //CODE8 Observability Observability Name changed to DevOps Insights
  {
    name: "DevOps Insights",
    path: "DevOpsInsight",
    component: <FlowMetrics />,
    key: "1",
    icon: observability,
    childNavs: [
      {
        name: "FlowMetrics",
        text: "Flow Metrics",
        path: "DevOpsInsight/flowMetrics",
        component: <FlowMetrics />,
        key: "1.1.1",
      },
      {
        name: "PeopleMetrics",
        text: "People Metrics",
        path: "DevOpsInsight/peopleMetrics",
        component: <PeopleMetrics />,
        key: "1.1.2",
      },
      {
        text: "Productivity Metrics",
        name: "ProductivityMetrics",
        path: "DevOpsInsight/productivityMetrics",
        component: <ProductivityMetricsObs />,
        key: "1.1.3",
      },
      {
        name: "SBOM",
        text: "SBOM",
        path: "DevOpsInsight/SBOM",
        component: <SBOMLanding />,
        key: "1.1.4",
      },
      {
        name: "SBOM CISO",
        text: "SBOM CISO",
        path: "DevOpsInsight/SBOMCISO",
        component: <CISOSBOMLanding />,
        key: "1.1.5",
      },
    ],
  },
  
  //CODE8 Governance and Compliance
  {
    name: "Governance and Compliance",
    path: "gnc",
    component: <LicenseManagement />,
    key: "2",
    icon: gnc,
    //iconNavigation:true,
    toolTipCustomClass: "hovertipgnc",
    childNavs: [
      {
        name: "Compliance",
        text: "Compliance",
        path: "gnc/Compliance/Summary",
        component: <GNCLanding />,
        navigationDisable: false,
        key: "2.2",
        childNavs: [
          {
            name: "Vulnerability Management",
            text: "Vulnerability Management",
            path: "gnc/compliance/Vulnerability",
            component: <Vulnerabilites />,
            key: "2.2.1",
          },
          {
            name: "SBOM",
            text: "SBOM",
            path: "gnc/compliance/SBOM",
            component: <SBOMLanding isGnc={true} />,
            key: "2.2.2",
          },
          {
            name: "CISO Dashboard",
            text: "CISO Dashboard",
            path: "gnc/CISOInsights/Vulnerability",
            component: <CisoDBoardManagement />,
            key: "2.2.2",
          },
          {
            name: "SBOM CISO",
            text: "SBOM CISO",
            path: "gnc/CISOInsights/SBOMCISO",
            component: <CISOSBOMLanding isGnc={true}/>,
            key: "2.5",
          },
          {
            name: "CISO Dashboard",
            text: "CISO Dashboard",
            path: "gnc/CISOInsights/securityHubFindings",
            component: <CisoSHFLanding />,
            key: "2.2.2",
          },
          {
            name: "CISO Dashboard",
            text: "CISO Dashboard",
            path: "gnc/CISOInsights/trmgCompliance",
            component: <CisoTRMGLanding />,
            key: "2.2.2",
          },
          {
            name: "CISO Dashboard",
            text: "CISO Dashboard",
            path: "gnc/CISOInsights/RMiTCompliance",
            component: <CisoRMITLanding />,
            key: "2.2.2",
          },
          {
            name: "CISO Dashboard",
            text: "CISO Dashboard",
            path: "gnc/CISOInsights/circular09Compliance",
            component: <CisoC09DBoardManagement />,
            key: "2.2.2",
          },
          {
            name: "CISO Dashboard",
            text: "CISO Dashboard",
            path: "gnc/CISOInsights/cisoriskAssessment",
            component: <OnprimseCisoLandingPage activeTab="Risk Assessment"/>,
            key: "2.2.2",
          },
          {
            name: "CISO Dashboard",
            text: "CISO Dashboard",
            path: "gnc/CISOInsights/CISBenchmarkCompliance",
            component: <CisoLandingCIS />,
            key: "2.2.2",
          },
          {
            name: "TRMG Compliance",
            text: "TRMG Compliance",
            path: "gnc/regulatoryCompliance/trmgCompliance",
            component: <TRMGLanding />,
            key: "2.2.4",
          },
          {
            name: "RMiT Compliance",
            text: "RMiT Compliance",
            path: "gnc/regulatoryCompliance/RMiTCompliance",
            component: <RMiTLanding />,
            key: "2.2.4"
          },
          {
            name: "Circular 09 Compliance",
            text: "Circular 09 Compliance",
            path: "gnc/regulatoryCompliance/circular09Compliance",
            component: <ManagementC09Complaince />,
            key: "2.2.4",
          },

          {
            name: "Security Hub Findings",
            text: "Security Hub Findings",
            path: "gnc/compliance/securityHub/securityHubFindingsLandings",
            component: <SecurityHubFindings />,
            key: "2.2.5",
          },        
          {
            name: "Vulnerability Management",
            text: "Vulnerability Management",
            path: "gnc/compliance/CISBenchmarkCompliance",
            component: <CISBenchmarkCompliance />,
            key: "2.2.7",
          },
          {
            name: "Compliance",
            text: "Compliance",
            path: "gnc/Compliance/Summary",
            component: <GNCLanding />,
            key: "2.2.8",
          },
          {
            name: "PII Data",
            text: "PII Data",
            path: "gnc/compliance/PII",
            component: <Pii />,
            key: "2.2.9",
          },
          {
            name: "Risk Assessment",
            text: "Risk Assessment",
            path: "gnc/compliance/RiskAssessment",
            component: <CreateRiskAssessment />,
            key: "2.2.12",
          },
          {
            name: "Create Risk Assessment",
            text: "Create Risk Assessment",
            path: "gnc/compliance/AddRiskAssessment",
            component: <AddRiskAssessment />,
            key: "2.2.13",
          },
          {
            name: "Topic Name",
            text: "Topic Name",
            path: "gnc/compliance/RiskAssessment/Topics",
            component: <TopicName />,
            key: "2.2.14",
          },
          {
            name: "Sub Topic Name Assessment",
            text: "Sub Topic Name Assessment",
            path: "gnc/compliance/RiskAssessment/Topics/SubTopics",
            component: <SubTopicNameAssessment />,
            key: "2.2.15",
          },
          {
            name: "Questions Assessment",
            text: "Questions Assessment",
            path: "gnc/compliance/RiskAssessment/Topics/SubTopics/Clause/Questions",
            component: <QuestionsAssessment />,
            key: "2.2.16",
          },
          {
            name: "Risk Assessment Questions",
            text: "Risk Assessment Questions",
            path: "gnc/compliance/RiskAssessment/Topics/SubTopics/Clause/Questions/Assessment",
            component: <RiskAssesmentQuestion />,
            key: "2.2.17",
          },
          {
            name: "Clause",
            test: "Clause",
            path: "gnc/compliance/RiskAssessment/Topics/SubTopics/Clause",
            component: <ClausePage />,
            key: "2.2.18"
          }
          // {
          //   name: "User Access Review",
          //   text: "User Access Review",
          //   path: "gnc/UserAccessReview",
          //   component: <UserAssessReviewTable />,
          //   key: "2.2.18",
          // }
        ],
      },
      {
        name: "Regulatory Compliance",
        text: "Regulatory Compliance",
        path: "gnc/regulatoryCompliance/trmgCompliance",
        component: <TRMGLanding />,
        navigationDisable: false,
        key: "2.3",
        // childNavs: [
        //   {
        //     name: "TRMG Compliance",
        //     text: "TRMG Compliance",
        //     path: "gnc/regulatoryCompliance/trmgCompliance",
        //     component: <TRMGLanding />,
        //     key: "2.2.4",
        //   },
        //   {
        //     name: "RMiT Compliance",
        //     text: "RMiT Compliance",
        //     path: "gnc/regulatoryCompliance/RMiTCompliance",
        //     component: <RMiTLanding />,
        //     key: "2.2.4"
        //   },
        //   {
        //     name: "Circular 09 Compliance",
        //     text: "Circular 09 Compliance",
        //     path: "gnc/regulatoryCompliance/circular09Compliance",
        //     component: <ManagementC09Complaince />,
        //     key: "2.2.4",
        //   },
        // ],
      },
      {
        name: "Ciso Insights",
        text: "CISO Insights",
        path: "gnc/CISOInsights/Vulnerability",
        component: <CisoDBoardManagement />,
        key: "2.4",
      },
      // {
      //   name: "Ciso Insights",
      //   text: "CISO Insights",
      //   path: "gnc/CISOInsights/circular09Compliance",
      //   component: <CisoC09DBoardManagement />,
      //   key: "2.3",
      // },
      {
        name: "gnc",
        text: "License Management",
        //isNotSideBarNavigation: true,
        path: "gnc/licenseManagement",
        component: <LicenseManagement />,
        key: "2.6",
      },
     
      // {
      //   name: "User Access Review",
      //   text: "User Access Review",
      //   isNotSideBarNavigation: true,
      //   path: "gnc/Compliance/userAccessReview",
      //   component: <UserAccessManagement />,
      //   key: "2.8",
      // },
      {
        name: "Risk Assessment",
        text: "User Access Review",
        isNotSideBarNavigation: true,
        path: "gnc/Compliance/userAccessReview",
        component: <UserAccessManagement />,
        key: "2.9",
      },
    ],
  },
  // CODE8 Architetural Compliance & UAR
  {
    name: "Cloud Insights",
    path: "cloudInsights",
    // component: <ArchitectureLanding />,
    key: "3",
    icon: CloudInsightsCloud,
    childNavs: [
      {
        name: "Architecture Compliance",
        text: "Architecture Compliance",
        path: "cloudInsights/architeturalCompliance/overview",
        navigationDisable: true,
        customDropContainerClass: "CMDB",
        component: <ArchitectureLanding />,
        key: "3.1",
        childNavs: [
          {
            name: "Overview",
            text: "Overview",
            path: "cloudInsights/architeturalCompliance/overview",
            component: <ArchitectureLanding />,
            key: "3.1.1",
          },
          {
            name: "Create Architecture Diagram",
            text: "Create Architecture Diagram",
            path: "cloudInsights/architeturalCompliance/creatediagram",
            component: <ArchitectureDiagramLanding />,
            key: "3.1.2",
          },
        ],
      },
      {
        name: "Access Governance",
        text: "Access Governance",
        path: "cloudInsights/cloudAccessGovernance",
        navigationDisable: true,
        customDropContainerClass: "cloudAccessGovernance",
        component: <UserReview />,
        key: "3.2",
        childNavs: [
          {
            name: "Access Review",
            text: "Access Review",
            path: "cloudInsights/cloudAccessGovernance/accessreview",
            component: <UserReview />,
            key: "3.2.1",
          },
          {
            name: "Policy Insights",
            text: "Policy Insights",
            path: "cloudInsights/cloudAccessGovernance/policyInsights",
            component: <UserGraph />,
            key: "3.2.2",
          },
        ],
      },
      {
        name: "Cost Insights",
        text: "Cost Insights",
        //isNotSideBarNavigation: true,
        path: "cloudInsights/CostInsights",
        component: <CloudCostInsights />,
        key: "3.3",
      },
    ],
  },
  {
    name: "Risk and Audit Management Platform",
    path: "RiskAndAuditManagement",
    // component: <ArchitectureLanding />,
    key: "3",
    icon: RAMP,
    childNavs: [
      {
        name: "Risk Assessment",
        text: "Risk Assessment",
        path: "RiskAndAuditManagement/RiskAssessment",
        // navigationDisable: true,
        customDropContainerClass: "CMDB",
        component: <CreateRiskAssessmentLandings />,
        key: "3.1",
      },
      {
        name: "Compliance",
        text: "Compliance",
        path: "RiskAndAuditManagement/Compliance",
        component: <Onpremiseaccounts />,
        key: "3.1.1",
      },
      {
        name: "CISO Insights",
        text: "CISO Insights",
        path: "RiskAndAuditManagement/CISOInsights",
        component: <OnprimseCisoLandingPage activeTab="Risk Assessment"/>,
        key: "3.1.1",
      },
    ],
  },

  //CODE8 Configuration
  {

    name: "Configuration",
    path: "configuration",
    component: <Tools />,
    key: "5",
    icon: configuration,
    childNavs: [{
      name: "CMDB",
      text: "CMDB",
      path: "configuration/cmdb",
      //navigationDisable: true,
      customDropContainerClass: "CMDB",
      component: <AppConfig />,
      key: "4.11",
      childNavs: [
        {
          name: "Business Info",
          text: "Business Info",
          path: "configuration/cmdb/BusinessInfoConfig",
          component: <Business />,
          key: "4.11.1",
        },
        {
          name: "Role Allocation",
          text: "Role Allocation",
          path: "configuration/cmdb/BusinessInfoConfig/roleAllocation",
          component: <RoleAllocation />,
          key: "4.11.2",
          isNotSideBarNavigation: true,
        },

        {
          name: "Users",
          text: "Users",
          path: "configuration/cmdb/UserInfoConfig",
          component: <User />,
          key: "4.11.3",
        },

        {
          name: "Devices",
          text: "Devices",
          path: "configuration/cmdb/Devices",
          component: <DevicesConfig />,
          key: "4.11.4",
        },
        {
          name: "Cloud Accounts",
          text: "Cloud Accounts",
          path: "configuration/cmdb/cloudaccounttable/cloudaccount",
          component: <CloudAccount />,
          key: "4.11.5",
        },
        // {
        //   name:"Risk Assessement",
        //   text:"Risk Assessement"
        //   path:"",

        // }
        {
          name: "AppConfig",
          // text: "App Config",
          path: "configuration/cmdb",
          isNotSideBarNavigation: true,
          component: <AppConfig />,
          key: "4.11.6",
        },
        {
          key: "4.11.7",
          name: "Project",
          path: "/configuration/cmdb/:appname/project",
          isNotSideBarNavigation: true,
          component: <AppConfigProjects />,
        },
        {
          key: "4.11.8",
          name: "Server",
          path: "configuration/cmdb/:appName/DeviceConfig",
          isNotSideBarNavigation: true,
          component: <AppConfigServers />,
        },
        {
          key: "4.11.9",
          name: "Users",
          path: "configuration/cmdb/:appName/users",
          isNotSideBarNavigation: true,
          component: <AppConfigUsers />,
        },
        {
          key: "4.11.10",
          name: "Account IDs",
          path: "configuration/cmdb/:appName/accountIDs",
          isNotSideBarNavigation: true,
          component: <AppConfigAccountId />,
        },
        {
          key: "4.11.11",
          name: "Application Configuration",
          text: "Application Configuration",
          path: "configuration/cmdb/applicationConfiguration",
          isNotSideBarNavigation: true,
          component: <ApplicationConfiguration />,
        },
      ],
    },

    {
      name: "SonarQube Roles",
      path: "configuration/administration/tools/sonarqubeRole",
      isNotSideBarNavigation: true,
      component: <SonarQubeRoles />,
      key: "5.0",
    },
    {
      key: "6.0",
      name: "License Config",
      path: "configuration/cmdb/Devices/licenseconfigpage",
      isNotSideBarNavigation: true,
      component: <LicenseConfigpage />,
    },
    // {
    //   key: "7.0",
    //   name: "Infra Provisioning",
    //   path: "configuration/cmdb/Devices/newProvisionpage",
    //   isNotSideBarNavigation: true,
    //   component: <NewProvisionpage />,
    // },
    {
      name: "Administration",
      text: "Administration",
      path: "configuration/administration/ldapConfig",
      navigationDisable: true,
      customDropContainerClass: "admin",
      component: <LdapConfig />,
      key: "4.2",
      childNavs: [
        {
          name: "UserManagement",
          text: "User Management",
          path: "configuration/administration/userManagement",
          component: <UserManagement />,
          key: "4.2.4",
        },
        {
          name: "Schedulers",
          text: "Schedulers",
          path: "configuration/administration/Schedulers",
          component: <Schedulers />,
          key: "4.1.1",
        },
        {
          name: "Tools",
          text: "Tools",
          path: "configuration/administration/tools",
          component: <Tools />,
          key: "4.1.2",
        },
        // {
        //   name: "Groups",
        //   text: "Groups",
        //   path: "configuration/administration/groups",
        //   component: <Groups />,
        //   key: "4.1.3",
        // },
        // {
        //   name: "PII Data Configuration",
        //   text: "PII Data Configuration",
        //   path: "configuration/administration/PIIDataConfiguration",
        //   component: <PIIDataConfiguration />,
        //   key: "4.1.4",
        // },
        {
          name: "Policy Categorization",
          text: "IAM Policy Categorization",
          path: "configuration/administration/Policy Categorization",
          component: <PrivilegeConfiguration />,
          key: "4.1.4",
        },
        {
          name: "RiskAssessment",
          text: "Assessment Questionnaire",
          path: "configuration/administration/AssessmentQuestionnaire",
          component: <RiskAssessement />,
          key: "4.2.6",
        },
        
        // {
        //   name: "RiskAssessment",
        //   text: "Risk Assessment",
        //   path: "configuration/administration/riskAssessment",
        //   component: <RiskAssessement />,
        //   key: "4.2.6",
        // },

      ],
    },
    {
      name: "Settings",
      text: "Settings",
      path: "configuration/administration/tools",
      navigationDisable: true,
      customDropContainerClass: "settings",
      component: <Tools />,
      key: "4.1",
      childNavs: [
        {
          name: "LDAP Configuration",
          text: "LDAP Configuration",
          path: "configuration/settings/ldapConfig",
          component: <LdapConfig />,
          key: "4.2.1",
        },
        {
          name: "SmtpConfig",
          text: "SMTP Configuration",
          path: "configuration/settings/smtpConfig",
          component: <SmtpConfig />,
          key: "4.2.2",
        },
        {
          name: "LicenseConfig",
          text: "License Configuration",
          path: "configuration/settings/licenseConfig",
          component: <LicenseConfig />,
          key: "4.2.3",
        },
        {
          name: "LoggingConfig",
          text: "Logging Configuration",
          path: "configuration/settings/loggingConfig",
          component: <LoggingConfig />,
          key: "4.2.5",
        },
      ],
    },
    {
      name: "Bamboo Roles",
      path: "configuration/administration/tools/bambooRole",
      isNotSideBarNavigation: true,
      component: <BambooRoles />,
      key: "4.3",
    },
    {
      name: "Bitbucket Roles",
      path: "configuration/administration/tools/bitbucketRole",
      isNotSideBarNavigation: true,
      component: <BitBucketRoles />,
      key: "4.4",
    },
    {
      name: "Jenkins Roles",
      path: "configuration/administration/tools/jenkinsRole",
      isNotSideBarNavigation: true,
      component: <JenkinRoles />,
      key: "4.5",
    },
    {
      name: "Nexus Roles",
      path: "configuration/administration/tools/nexusRole",
      isNotSideBarNavigation: true,
      component: <NexusRoles />,
      key: "4.6",
    },
    {
      name: "JFrog Permission",
      path: "configuration/administration/tools/jfrogRole",
      isNotSideBarNavigation: true,
      component: <JFrogPermission />,
      key: "4.7",
    },
    {
      name: "Jira Roles",
      path: "configuration/administration/tools/jiraRole",
      isNotSideBarNavigation: true,
      component: <JiraRoles />,
      key: "4.8",
    },
    {
      name: "Jira Cloud Roles",
      path: "configuration/administration/tools/jiracloudRole",
      isNotSideBarNavigation: true,
      component: <JiraCloudRoles />,
      key: "4.9",
    },
    {
      name: "Confluence Cloud Roles",
      path: "configuration/administration/tools/confluencecloudRole",
      isNotSideBarNavigation: true,
      component: <ConfluenceCloudRole />,
      key: "4.9",
    },
    {
      name: "gnc",
      text: "License Management",
      isNotSideBarNavigation: true,
      path: "/gnc/licenseManagement/licenseconfigpage",
      component: <LicenseConfigpage />,
      key: "2.5",
    },
    {
      name: "AuditLog",
      text: "Audit Log",
      path: "configuration/administration/auditLog",
      component: <AuditLog />,
      key: "4.2.6",
    },


    ],

  },


  // Inventory
  {
    name: "Inventory",
    path: "inventory",
    key: "9",
    icon: Inventory,
    childNavs: [
      {
        name: "Servers",
        text: "Servers",
        path: "inventory/Servers",
        component: <InventoryServer />,
        key: "3.1",
      },
      {
        name: "Projects",
        text: "Projects",
        path: "inventory/Projects",
        component: <InventoryProject />,
        key: "3.2",
      },
    ],
  },


];