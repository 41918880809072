/* eslint-disable no-dupe-keys */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "universal-cookie";
import { handleResponseCode } from "../../../app/utilities/helpers";
import GetProxy from "../../../app/GetProxy";


const proxy = GetProxy()
const cookies = new Cookies();

export const saveCBudget = createAsyncThunk(
  "post/saveCB",
  async (props, { dispatch }) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { userName, payload, listPayload } = props;

      const response = await fetch(
        `${proxy}/api/v1/cloudCostBudget/saveOrUpdateCloudBudgetCost/${userName}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const res = await response.text();
      dispatch(
        getCountCB({ payload: listPayload.payload })
      );
      dispatch(getListCB(listPayload));

      return res;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
export const getCountCB = createAsyncThunk("post/getCountCB", async (props) => {
  const { payload } = props;
  const apiToken = cookies.get("apitoken");
  const username = cookies.get("username");
  const res = await fetch(
    `${proxy}/api/v1/cloudCostBudget/getCloudBudgetCostTotalCount`,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "DTOP-API-TOKEN": apiToken,
        USERNAME: username,
      },
      body: JSON.stringify(payload),
    },
  ).then((resp) => resp.json());
  handleResponseCode(res);
  return res;
});
export const getListCB = createAsyncThunk("post/getListCB", async (props) => {
  const { pageNumber, itemsPerPage, payload } = props;
  const apiToken = cookies.get("apitoken");
  const username = cookies.get("username");
  const res = await fetch(
    `${proxy}/api/v1/cloudCostBudget/getCloudBudgetCostListBySearchSortAndPagination/${pageNumber}/${itemsPerPage}
    `,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "DTOP-API-TOKEN": apiToken,
        USERNAME: username,
      },
      body: JSON.stringify(payload),
    },
  ).then((resp) => resp.json());
  handleResponseCode(res);
  return res;
});
export const getServiceListCB = createAsyncThunk(
  "get/serviceProviderCB",
  async (props) => {
    const cmdbId = props;
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    const res = await fetch(
      `${proxy}/api/v1/cloudCostBudget/getCloudServiceProviderList/${cmdbId}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "DTOP-API-TOKEN": apiToken,
          USERNAME: username,
        },
      }
    ).then((resp) => resp.json());
    handleResponseCode(res);
    return res;
  }
);
export const getAccountIdListCB = createAsyncThunk(
  "get/accountIdCB",
  async (props) => {
    const cmdbId = props;
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    let resultArray = [];
    const res = await fetch(
      `${proxy}/api/v1/cloudCostBudget/getAccountIdList/${cmdbId}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "DTOP-API-TOKEN": apiToken,
          USERNAME: username,
        },
      }
    ).then((resp) => resp.json());
    // resultArray=res.map((ids, index) => ({
    //   value: ids,
    //   label: ids,
    // }))
    handleResponseCode(res);
    return res;
  }
);
export const getByIdCB = createAsyncThunk("get/getByIdCB", async (props) => {
  const id = props;
  const apiToken = cookies.get("apitoken");
  const username = cookies.get("username");
  const res = await fetch(`${proxy}/api/v1/cloudCostBudget/getById/${id}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "DTOP-API-TOKEN": apiToken,
      USERNAME: username,
    },
  }).then((resp) => resp.json());
  handleResponseCode(res);
  return res;
});
export const deleteCB = createAsyncThunk(
  "post/deleteCB",
  async (props, { dispatch }) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { id, cmdbId, listPayload } = props;

      const response = await fetch(
        `${proxy}/api/v1/cloudCostBudget/deleteCloudBudgetCost/${id}`,
        {
          method: "Delete",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": apiToken,
            USERNAME: username,
          },
        }
      );
      handleResponseCode(response);

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const res = await response.text();

      dispatch(
        getCountCB({ payload: listPayload.payload })
      );
      dispatch(getListCB(listPayload));

      return res;
    } catch (error) {
      console.error("Error:", error);
      throw error; // Rethrow the error so the calling code can handle it
    }
  }
);
const initialState = {
  dataCB: "",
  serviceListCB: [],
  accountIdListCB: [],
  tableListCB: [],
  getDataCB: [],
  saveLoadingCB: false,
  tableLoadingCB: false,
  getLoadingCB: false,
  getLoadingCB: false,
  deleteDataCB: "",
  deleteLoadingCB: false,
  CountCB: '',
  CountCBLoading: false,
}
export const CBudgetSlice = createSlice({
  name: "ApiCall",
  initialState,
  reducers: {
    resetDataCB: (state) => {
      const resetDatas = {
        dataCB: "",
        serviceListCB: [],
        accountIdListCB: [],
        tableListCB: [],
        getDataCB: [],
        saveLoadingCB: false,
        tableLoadingCB: false,
        getLoadingCB: false,
        getLoadingCB: false,
        deleteDataCB: "",
        deleteLoadingCB: false,
        CountCB: '',
        CountCBLoading: false,
      }
      return resetDatas;
      // state.dataCB = ''; // Reset dataCB to its initial state
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(saveCBudget.pending, (state, action) => {
        state.saveLoadingCB = true;
        state.dataCB = "";
      })
      .addCase(saveCBudget.fulfilled, (state, action) => {
        state.saveLoadingCB = false;
        state.dataCB = action.payload;
      })
      .addCase(saveCBudget.rejected, (state, action) => {
        state.saveLoadingCB = false;
      })
      .addCase(getServiceListCB.fulfilled, (state, action) => {
        state.serviceListCB = action.payload;
      })
      .addCase(getAccountIdListCB.fulfilled, (state, action) => {
        state.accountIdListCB = action.payload;
      })

      .addCase(getListCB.pending, (state, action) => {
        state.tableLoadingCB = true;
      })
      .addCase(getListCB.fulfilled, (state, action) => {
        state.tableLoadingCB = false;
        state.tableListCB = action.payload;
      })
      .addCase(getListCB.rejected, (state, action) => {
        state.tableLoadingCB = false;
      })
      .addCase(getByIdCB.pending, (state, action) => {
        state.getLoadingCB = true;
      })
      .addCase(getByIdCB.fulfilled, (state, action) => {
        state.getLoadingCB = false;
        state.getDataCB = action.payload;
      })
      .addCase(getByIdCB.rejected, (state, action) => {
        state.getLoadingCB = false;
      })
      .addCase(deleteCB.pending, (state, action) => {
        state.deleteLoadingCB = true;
        state.deleteDataCB = "";
      })
      .addCase(deleteCB.fulfilled, (state, action) => {
        state.deleteLoadingCB = false;
        state.deleteDataCB = action.payload;
      })
      .addCase(deleteCB.rejected, (state, action) => {
        state.deleteLoadingCB = false;
      })
      .addCase(getCountCB.pending, (state, action) => {
        state.CountCBLoading = true;
      })
      .addCase(getCountCB.fulfilled, (state, action) => {
        state.CountCBLoading = false;
        state.CountCB = action.payload;
      });
  },
});
export const { resetDataCB } = CBudgetSlice.actions;
export default CBudgetSlice.reducer;
