import React from "react";
import ButtonComponent from "./ButtonComponent";
import PropTypes from "prop-types";
const DiscardChanges = (props) => {


    const cancelClick=()=>{
        return props.cancelClick()
    }

    const discardClick=()=>{
        return props.discardClick()
    }

    return (
        <>
            <h4 className="delete-h"> Discard Changes</h4>
            {props.title=='add' ? <p>
            Are you sure you want to cancel this new entry? Any unsaved data will be lost.
            </p> : <p>
            Are you sure you want to cancel editing this entry? Any unsaved changes will be lost.
            </p>}
            <div className="btnswrap btn-dlt">
                <ButtonComponent
                    text="No"
                    className={"cancelBtnStyle mx-0 btn btn-primary"}
                    onClick={() => cancelClick()}
                />
                <ButtonComponent
                    text="Yes"
                    className={"btnStyle btn btn-primary"}
                    onClick={() => discardClick()}
                />
            </div>
        </>
    )
}
DiscardChanges.propTypes = {
    cancelClick: PropTypes.bool,
    discardClick: PropTypes.arrayOf(PropTypes.object).isRequired,
    title: PropTypes.arrayOf(PropTypes.object).isRequired,   
  };
export default DiscardChanges