import React from "react";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { propTypes } from "react-bootstrap/esm/Image";
import Cookies from "universal-cookie";
import { handleResponseCode } from "../../../app/utilities/helpers";
import GetProxy from "../../../app/GetProxy";
const cookies = new Cookies();
const proxy = GetProxy()

export const treeMapAccOpenApi = createAsyncThunk(
  "post/treeMapData",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const response = await fetch(`${proxy}/api/v1/circularNineComplianceTreeMapping/getCircular09TreeMappedData/${props}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": apiToken,
            USERNAME: username,
          },
        }
      );
      handleResponseCode(response);
      const data = await response.json();
      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
)

export const treeMapOnPremiseAccOpenApi = createAsyncThunk(
  "post/treeMapOnPremiseData",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    const {payload} = props;
    try {
      const response = await fetch(`${proxy}/api/v1/CircularNineOnPremisesController/getCircular09OnPremiseTreeMappedData`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();
      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
)

export const treeMapComplianceDrillDown = createAsyncThunk(
  "post/treeMapComplianceDrillDown",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { drillcurrentPage, drillPageLimit, drilltableTreePayload, dataTree } = props;
      const response = await fetch(`${proxy}/api/v1/circularNineComplianceTreeMapping/getComplianceTreeMappedListWithSearch/${dataTree.accountId}/${dataTree.articleDescription}/${drillcurrentPage}/${drillPageLimit}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(drilltableTreePayload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();
      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
)

export const treeMapComplianceDrillDownCount = createAsyncThunk(
  "post/treeMapComplianceDrillDownCount",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { drilltableTreePayload, dataTree } = props;
      const response = await fetch(`${proxy}/api/v1/circularNineComplianceTreeMapping/getComplianceTreeMappedListWithSearchCount/${dataTree.accountId}/${dataTree.articleDescription}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(drilltableTreePayload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();
      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
)

export const treeMapNonComplianceDrillDown = createAsyncThunk(
  "post/treeMapNonComplianceDrillDown",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");

    try {
      const { drillcurrentPage, drillPageLimit, drilltableTreeOpenPayload, dataTree } = props;
      console.log("Datatress,", dataTree)
      const response = await fetch(`${proxy}/api/v1/circularNineComplianceTreeMapping/getNonComplianceTreeMappedListWithSearch/${dataTree.accountId}/${dataTree.articleDescription}/${drillcurrentPage}/${drillPageLimit}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(drilltableTreeOpenPayload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();
      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
)

export const treeMapNonComplianceDrillDownCount = createAsyncThunk(
  "post/treeMapNonComplianceDrillDownCount",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { drilltableTreeOpenPayload, dataTree } = props;
      const response = await fetch(`${proxy}/api/v1/circularNineComplianceTreeMapping/getNonComplianceTreeMappedListWithSearchCount/${dataTree.accountId}/${dataTree.articleDescription}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(drilltableTreeOpenPayload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();
      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
)
export const treeMapNotApplicableDrillDown = createAsyncThunk(
  "post/treeMapNotApplicableDrillDown",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { drillcurrentPage, drillPageLimit, drilltableTreePayload, dataTree } = props;
      const response = await fetch(`${proxy}/api/v1/circularNineComplianceTreeMapping/getNotApplicableCircularNineMappedListWithSearch/${dataTree.accountId}/${dataTree.articleDescription}/${drillcurrentPage}/${drillPageLimit}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(drilltableTreePayload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();
      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
)

export const treeMapNotApplicableDrillDownCount = createAsyncThunk(
  "post/treeMapNotApplicableDrillDownCount",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { drilltableTreePayload, dataTree } = props;
      const response = await fetch(`${proxy}/api/v1/circularNineComplianceTreeMapping/getNotApplicableCircularNineMappedListWithSearchCount/${dataTree.accountId}/${dataTree.articleDescription}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(drilltableTreePayload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();
      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
)

export const graphtDataApi = createAsyncThunk(
  "post/GraphData",
  async (props) => {
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    try {
      const { payload, packName } = props;
      const response = await fetch(
        `${proxy}/api/v1/conformancePack/TRMG/GraphData/${packName}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": Apitoken,
            USERNAME: User,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

export const getCountForNonComplianceAPI = createAsyncThunk(
  "post/getCountForNonComplianceAPI",
  async (props) => {
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    try {
      const { payload, packName, accountId } = props;
      const response = await fetch(
        `${proxy}/api/v1/conformancePack/TRMG/getCountForNonCompliance/${packName}/${accountId}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": Apitoken,
            USERNAME: User,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

export const getListForNonComplianceAPI = createAsyncThunk(
  "post/getListForNonComplianceAPI",
  async (props) => {
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    try {
      const { payload, packName, accountId, pageNumber, itemsPerPage } = props;
      const response = await fetch(
        `${proxy}/api/v1/conformancePack/TRMG/getSearchNonCompliancesRules/list/${packName}/${accountId}/${pageNumber}/${itemsPerPage}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": Apitoken,
            USERNAME: User,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

export const getCompliantListAPI = createAsyncThunk(
  "post/getCompliantListAPI",
  async (props) => {
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    try {
      const { payload, packName, accountId, pageNumber, itemsPerPage } = props;
      const response = await fetch(
        `${proxy}/api/v1/conformancePack/TRMG/getSearchCompliancesRules/list/${packName}/${accountId}/${pageNumber}/${itemsPerPage}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": Apitoken,
            USERNAME: User,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

export const getCompliantCountAPI = createAsyncThunk(
  "post/getCompliantCountAPI",
  async (props) => {
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    try {
      const { payload, packName, accountId } = props;
      const response = await fetch(
        `${proxy}/api/v1/conformancePack/TRMG/getCountForTrmgCompliance/${packName}/${accountId}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": Apitoken,
            USERNAME: User,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

export const getNotApplicableListAPI = createAsyncThunk(
  "post/getNotApplicableListAPI",
  async (props) => {
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    try {
      const { payload, packName, accountId, pageNumber, itemsPerPage } = props;
      const response = await fetch(
        `${proxy}/api/v1/conformancePack/TRMG/GetNotApplicable/list/${packName}/${accountId}/${pageNumber}/${itemsPerPage}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": Apitoken,
            USERNAME: User,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

export const getNotApplicableCountAPI = createAsyncThunk(
  "post/getNotApplicableCountAPI",
  async (props) => {
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    try {
      const { payload, packName, accountId } = props;
      const response = await fetch(
        `${proxy}/api/v1/conformancePack/TRMG/getCountForNotApplicable/${packName}/${accountId}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": Apitoken,
            USERNAME: User,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);


//On- premise deatils table

export const OnPremiseTableListAPI = createAsyncThunk(
  "post/OnPremiseTableListAPI",
  async (props) => {
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    try {
      const { payload, pageNumber, itemsPerPage } = props;
      const response = await fetch(
        `${proxy}/api/v1/CircularNineOnPremisesController/getCircular09DataListOnlyCircular09/${pageNumber}/${itemsPerPage}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": Apitoken,
            USERNAME: User,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

export const OnPremiseRATableListAPI = createAsyncThunk(
  "post/getWithOutCircular09ListAndPagination",
  async (props) => {
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    try {
      const { payload, pageNumber, itemsPerPage } = props;
      const response = await fetch(
        `${proxy}/api/v1/CircularNineOnPremisesController/getWithOutCircular09ListAndPagination/${pageNumber}/${itemsPerPage}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": Apitoken,
            USERNAME: User,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);


export const OnpremiseChartAPI = createAsyncThunk(
  "post/OnpremiseChartAPI",
  async (props) => {
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    try {
      const { payload, } = props;
      const response = await fetch(
        `${proxy}/api/v1/CircularNineOnPremisesController/nonComplianceByAccountIdGraphForOnPremises`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": Apitoken,
            USERNAME: User,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

export const OnpremiseRAChartAPI = createAsyncThunk(
  "post/OnpremiseRAChartAPI",
  async (props) => {
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    try {
      const { payload, } = props;
      const response = await fetch(
        `${proxy}/api/v1/CircularNineOnPremisesController/nonComplianceByAccountIdGraphForOnPremisesWithoutC09`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": Apitoken,
            USERNAME: User,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

//On- premise Non Compliant drilldown
export const OnPremiseNonCompliantTableListAPI = createAsyncThunk(
  "post/OnPremiseNonCompliantTableListAPI",
  async (props) => {
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    try {
      const { payload, pageNumber, itemsPerPage } = props;
      const response = await fetch(
        `${proxy}/api/v1/CircularNineOnPremisesController/getNonCompliantList/${pageNumber}/${itemsPerPage}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": Apitoken,
            USERNAME: User,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

//On-premise Non Compliant count API
export const OnPremiseNonCompliantTableCountAPI = createAsyncThunk(
  "post/OnPremiseNonCompliantTableCountAPI",
  async (props) => {
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    try {
      const { payload } = props;
      const response = await fetch(
        `${proxy}/api/v1/CircularNineOnPremisesController/getNonCompliantListCount`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": Apitoken,
            USERNAME: User,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

//On- premise  Compliant drilldown
export const OnPremiseCompliantTableListAPI = createAsyncThunk(
  "post/OnPremiseCompliantTableListAPI",
  async (props) => {
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    try {
      const { payload, pageNumber, itemsPerPage } = props;
      const response = await fetch(
        `${proxy}/api/v1/CircularNineOnPremisesController/getCompliantList/${pageNumber}/${itemsPerPage}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": Apitoken,
            USERNAME: User,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

//On-premise  Compliant count API
export const OnPremiseCompliantTableCountAPI = createAsyncThunk(
  "post/OnPremiseCompliantTableCountAPI",
  async (props) => {
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    try {
      const { payload } = props;
      const response = await fetch(
        `${proxy}/api/v1/CircularNineOnPremisesController/getCompliantListCount`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": Apitoken,
            USERNAME: User,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);


//On- premise Not Applicabledrilldown
export const OnPremiseNotApplicableTableListAPI = createAsyncThunk(
  "post/OnPremiseNotApplicableTableListAPI",
  async (props) => {
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    try {
      const { payload, pageNumber, itemsPerPage } = props;
      const response = await fetch(
        `${proxy}/api/v1/CircularNineOnPremisesController/getNotApplicableList/${pageNumber}/${itemsPerPage}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": Apitoken,
            USERNAME: User,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

//On-premise Not Applicable count API
export const OnPremiseNotApplicableTableCountAPI = createAsyncThunk(
  "post/OnPremiseNotApplicableTableCountAPI",
  async (props) => {
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    try {
      const { payload } = props;
      const response = await fetch(
        `${proxy}/api/v1/CircularNineOnPremisesController/getNotApplicableListCount`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": Apitoken,
            USERNAME: User,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

//Partially Compliant drilldown list
export const OnPremisePartiallyCompliantTableListAPI = createAsyncThunk(
  "post/OnPremisePartiallyCompliantTableListAPI",
  async (props) => {
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    try {
      const { payload, pageNumber, itemsPerPage } = props;
      const response = await fetch(
        `${proxy}/api/v1/CircularNineOnPremisesController/getPartiallyCompliantList/${pageNumber}/${itemsPerPage}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": Apitoken,
            USERNAME: User,
          },
          body: JSON.stringify(payload),
        }
      );
      // handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

//On-premise Partially Compliant count API
export const OnPremisePartiallyCompliantTableCountAPI = createAsyncThunk(
  "post/OnPremisePartiallyCompliantTableCountAPI",
  async (props) => {
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    try {
      const { payload } = props;
      const response = await fetch(
        `${proxy}/api/v1/CircularNineOnPremisesController/getPartiallyCompliantListCount`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": Apitoken,
            USERNAME: User,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

export const getDetailsofOnPremise = createAsyncThunk(
  "post/getDetailsofOnPremise",
  async (props) => {
    const {complianceId} = props;
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const response = await fetch(
        `${proxy}/api/v1/CircularNineOnPremisesController/getCircularNineOnPremiseCommentList/${complianceId}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(divisionPayload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();
 
      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

const initialState = {
  treeMapData: [],
  mapDataApiLoading: false,
  trmgComplianceDrillDown: [],
  trmgComplianceDrillDownCount: [],
  trmgComplianceDrillDownLoading: false,
  trmgComplianceDrillDownCountLoading: false,
  trmgNonComplianceDrillDown: [],
  trmgNonComplianceDrillDownCount: [],
  trmgNonComplianceDrillDownLoading: false,
  trmgNonComplianceDrillDownCountLoading: false,
  trmgNotApplicableDrillDown: [],
  trmgNotApplicableDrillDownCount: [],
  trmgNotApplicableDrillDownLoading: false,
  trmgNotApplicableDrillDownCountLoading: false,
  getResourceDataStore: [],
  getResourceDataCountData: [],
  getResourceDataLoading: false,
  getResourceDataCountLoading: false,
  graphtData: [],
  graphtDataLoading: false,
  getCountForNonComplianceData: [],
  getCountForNonComplianceLoading: false,
  getListForNonComplianceData: [],
  getListForNonComplianceLoading: false,
  getCompliantListData: [],
  getCompliantListLoading: false,
  getCompliantCountData: [],
  getCompliantCountLoading: false,
  getNotApplicableListData: [],
  getNotApplicableListLoading: false,
  getNotApplicableCountData: [],
  getNotApplicableCountLoading: false,
  error: "",

  //On-premise

  OnPremiseTableListLoading: false,
  OnPremiseRATableListLoading: false,
  OnPremiseTableListData: [],
  OnPremiseRATableListData: [],

  //chart
  OnpremiseChartDataLoading: false,
  OnpremiseChartData: [],
//drilldown

OnPremiseNonCompliantTableListLoading: false,
OnPremiseNonCompliantTableListData: [],
OnPremiseNonCompliantTableCountLoading: false,
OnPremiseNonCompliantTableCountData: [],

OnPremiseCompliantTableCountLoading:false,
OnPremiseCompliantTableCountData:[],
OnPremiseCompliantTableListData:[],
OnPremiseCompliantTableListLoading:false,


OnPremiseNotApplicableTableListData:[],
OnPremiseNotAppliTableListLoading:false,
OnPremiseNotApplicableTableCountData:[],
OnPremiseNotApplicableTableCountLoading:false,


OnPremisepartiallyCompliantTableListData:[],
OnPremisepartiallyCompliantTableListLoading:false,
OnPremisePartiallyCompliantTableCountData:[],
OnPremisePartiallyCompliantTableCountLoading:false,

DetailsData:[],
DetailsLoading:false,

clickAble : null,

}

export const Circular9Slice = createSlice({
  name: 'circular9Slice',
  initialState,

  reducers: {
    resetCircular9Data: () => {
      return initialState;
    },
    setClickAble: (state, action) => {
      state.clickAble = action.payload;
    },
    resetdrillDownData: (state) => {
      state.trmgComplianceDrillDown = [];
      state.trmgComplianceDrillDownCount = [];
      state.trmgNonComplianceDrillDown = [];
      state.trmgNonComplianceDrillDownCount = [];
      state.trmgNotApplicableDrillDown = [];
      state.trmgNotApplicableDrillDownCount = [];
    },
  }, extraReducers: (builder) => {
    builder
      // C9 chart
      .addCase(graphtDataApi.pending, (state, action) => {
        state.graphtDataLoading = true;
      })
      .addCase(graphtDataApi.fulfilled, (state, action) => {
        state.graphtData = action.payload;
        state.graphtDataLoading = false;
      })
      .addCase(graphtDataApi.rejected, (state, action) => {
        state.graphtDataLoading = false;
      })

      // getCountForNonComplianceAPI
      .addCase(getCountForNonComplianceAPI.pending, (state, action) => {
        state.getCountForNonComplianceLoading = true;
      })
      .addCase(getCountForNonComplianceAPI.fulfilled, (state, action) => {
        state.getCountForNonComplianceData = action.payload;
        state.getCountForNonComplianceLoading = false;
      })
      .addCase(getCountForNonComplianceAPI.rejected, (state, action) => {
        state.getCountForNonComplianceLoading = false;
      })

      // getListForNonComplianceAPI
      .addCase(getListForNonComplianceAPI.pending, (state, action) => {
        state.getListForNonComplianceLoading = true;
      })
      .addCase(getListForNonComplianceAPI.fulfilled, (state, action) => {
        state.getListForNonComplianceData = action.payload;
        state.getListForNonComplianceLoading = false;
      })
      .addCase(getListForNonComplianceAPI.rejected, (state, action) => {
        state.getListForNonComplianceLoading = false;
      })

      // getCompliantListAPI
      .addCase(getCompliantListAPI.pending, (state, action) => {
        state.getCompliantListLoading = true;
      })
      .addCase(getCompliantListAPI.fulfilled, (state, action) => {
        state.getCompliantListData = action.payload;
        state.getCompliantListLoading = false;
      })
      .addCase(getCompliantListAPI.rejected, (state, action) => {
        state.getCompliantListLoading = false;
      })

      // getCompliantCountAPI
      .addCase(getCompliantCountAPI.pending, (state, action) => {
        state.getCompliantCountLoading = true;
      })
      .addCase(getCompliantCountAPI.fulfilled, (state, action) => {
        state.getCompliantCountData = action.payload;
        state.getCompliantCountLoading = false;
      })
      .addCase(getCompliantCountAPI.rejected, (state, action) => {
        state.getCompliantCountLoading = false;
      })

      // getNotApplicableListAPI
      .addCase(getNotApplicableListAPI.pending, (state, action) => {
        state.getNotApplicableListLoading = true;
      })
      .addCase(getNotApplicableListAPI.fulfilled, (state, action) => {
        state.getNotApplicableListData = action.payload;
        state.getNotApplicableListLoading = false;
      })
      .addCase(getNotApplicableListAPI.rejected, (state, action) => {
        state.getNotApplicableListLoading = false;
      })

      // getNotApplicableCountAPI
      .addCase(getNotApplicableCountAPI.pending, (state, action) => {
        state.getNotApplicableCountLoading = true;
      })
      .addCase(getNotApplicableCountAPI.fulfilled, (state, action) => {
        state.getNotApplicableCountData = action.payload;
        state.getNotApplicableCountLoading = false;
      })
      .addCase(getNotApplicableCountAPI.rejected, (state, action) => {
        state.getNotApplicableCountLoading = false;
      })



      // On-premise listAPI
      .addCase(OnPremiseTableListAPI.pending, (state, action) => {
        state.OnPremiseTableListLoading = true;
      })
      .addCase(OnPremiseTableListAPI.fulfilled, (state, action) => {
        state.OnPremiseTableListData = action.payload;
        state.OnPremiseTableListLoading = false;
      })
      .addCase(OnPremiseTableListAPI.rejected, (state, action) => {
        state.OnPremiseTableListLoading = false;
      })

      .addCase(OnPremiseRATableListAPI.pending, (state, action) => {
        state.OnPremiseRATableListLoading = true;
      })
      .addCase(OnPremiseRATableListAPI.fulfilled, (state, action) => {
        state.OnPremiseRATableListData = action.payload;
        state.OnPremiseRATableListLoading = false;
      })
      .addCase(OnPremiseRATableListAPI.rejected, (state, action) => {
        state.OnPremiseRATableListLoading = false;
      })

      // Onpremise C9 chart
      .addCase(OnpremiseChartAPI.pending, (state, action) => {
        state.OnpremiseChartDataLoading = true;
      })
      .addCase(OnpremiseChartAPI.fulfilled, (state, action) => {
        state.OnpremiseChartData = action.payload;
        state.OnpremiseChartDataLoading = false;
      })
      .addCase(OnpremiseChartAPI.rejected, (state, action) => {
        state.OnpremiseChartDataLoading = false;
      })
      .addCase(OnpremiseRAChartAPI.pending, (state, action) => {
        state.OnpremiseChartDataLoading = true;
      })
      .addCase(OnpremiseRAChartAPI.fulfilled, (state, action) => {
        state.OnpremiseChartData = action.payload;
        state.OnpremiseChartDataLoading = false;
      })
      .addCase(OnpremiseRAChartAPI.rejected, (state, action) => {
        state.OnpremiseChartDataLoading = false;
      })
      //TreeMap Account Open Api
      .addCase(treeMapAccOpenApi.pending, (state, action) => {
        state.mapDataApiLoading = true;
      })
      .addCase(treeMapAccOpenApi.fulfilled, (state, action) => {
        state.treeMapData = action.payload;
        state.mapDataApiLoading = false;
      })
      .addCase(treeMapAccOpenApi.rejected, (state, action) => {
        state.mapDataApiLoading = false;
      })

      // onpremise treemap api
      .addCase(treeMapOnPremiseAccOpenApi.pending, (state, action) => {
        state.mapDataApiLoading = true
      })
      .addCase(treeMapOnPremiseAccOpenApi.fulfilled, (state, action) => {
        state.treeMapData = action.payload;
        state.mapDataApiLoading = false;
      })
      .addCase(treeMapOnPremiseAccOpenApi.rejected, (state, action) => {
        state.mapDataApiLoading = false;
      })

      //TRMG Compliance DrillDown Api
      .addCase(treeMapComplianceDrillDown.pending, (state, action) => {
        state.trmgComplianceDrillDownLoading = true;
      })
      .addCase(treeMapComplianceDrillDown.fulfilled, (state, action) => {
        state.trmgComplianceDrillDown = action.payload;
        state.trmgComplianceDrillDownLoading = false;
      })
      .addCase(treeMapComplianceDrillDown.rejected, (state, action) => {
        state.trmgComplianceDrillDownLoading = false;
      })

      //TRMG Compliance DrillDown Count Api
      .addCase(treeMapComplianceDrillDownCount.pending, (state, action) => {
        state.trmgComplianceDrillDownCountLoading = true;
      })
      .addCase(treeMapComplianceDrillDownCount.fulfilled, (state, action) => {
        state.trmgComplianceDrillDownCount = action.payload;
        state.trmgComplianceDrillDownCountLoading = false;
      })
      .addCase(treeMapComplianceDrillDownCount.rejected, (state, action) => {
        state.trmgComplianceDrillDownCountLoading = false;
      })

      //TRMG Non Compliance DrillDown Api
      .addCase(treeMapNonComplianceDrillDown.pending, (state, action) => {
        state.trmgNonComplianceDrillDownLoading = true;
      })
      .addCase(treeMapNonComplianceDrillDown.fulfilled, (state, action) => {
        state.trmgNonComplianceDrillDown = action.payload;
        state.trmgNonComplianceDrillDownLoading = false;
      })
      .addCase(treeMapNonComplianceDrillDown.rejected, (state, action) => {
        state.trmgNonComplianceDrillDownLoading = false;
      })

      //TRMG Non Compliance DrillDown Count Api
      .addCase(treeMapNonComplianceDrillDownCount.pending, (state, action) => {
        state.trmgNonComplianceDrillDownCountLoading = true;
      })
      .addCase(treeMapNonComplianceDrillDownCount.fulfilled, (state, action) => {
        state.trmgNonComplianceDrillDownCount = action.payload;
        state.trmgNonComplianceDrillDownCountLoading = false;
      })
      .addCase(treeMapNonComplianceDrillDownCount.rejected, (state, action) => {
        state.trmgNonComplianceDrillDownCountLoading = false;
      })
      //TRMG Not Applicable DrillDown Api
      .addCase(treeMapNotApplicableDrillDown.pending, (state, action) => {
        state.trmgNotApplicableDrillDownLoading = true;
      })
      .addCase(treeMapNotApplicableDrillDown.fulfilled, (state, action) => {
        state.trmgNotApplicableDrillDown = action.payload;
        state.trmgNotApplicableDrillDownLoading = false;
      })
      .addCase(treeMapNotApplicableDrillDown.rejected, (state, action) => {
        state.trmgNotApplicableDrillDownLoading = false;
      })

      //TRMG Not Applicable DrillDown Count Api
      .addCase(treeMapNotApplicableDrillDownCount.pending, (state, action) => {
        state.trmgNotApplicableDrillDownCountLoading = true;
      })
      .addCase(treeMapNotApplicableDrillDownCount.fulfilled, (state, action) => {
        state.trmgNotApplicableDrillDownCount = action.payload;
        state.trmgNotApplicableDrillDownCountLoading = false;
      })
      .addCase(treeMapNotApplicableDrillDownCount.rejected, (state, action) => {
        state.trmgNotApplicableDrillDownCountLoading = false;
      })

      // On-premise Non Compliant listAPI
      .addCase(OnPremiseNonCompliantTableListAPI.pending, (state, action) => {
        state.OnPremiseNonCompliantTableListLoading = true;
      })
      .addCase(OnPremiseNonCompliantTableListAPI.fulfilled, (state, action) => {
        state.OnPremiseNonCompliantTableListData = action.payload;
        state.OnPremiseNonCompliantTableListLoading = false;
      })
      .addCase(OnPremiseNonCompliantTableListAPI.rejected, (state, action) => {
        state.OnPremiseNonCompliantTableListLoading = false;
      })

      // OnPremiseNonCompliantTableCountAPI
      .addCase(OnPremiseNonCompliantTableCountAPI.pending, (state, action) => {
        state.OnPremiseNonCompliantTableCountLoading = true;
      })
      .addCase(OnPremiseNonCompliantTableCountAPI.fulfilled, (state, action) => {
        state.OnPremiseNonCompliantTableCountData = action.payload;
        state.OnPremiseNonCompliantTableCountLoading = false;
      })
      .addCase(OnPremiseNonCompliantTableCountAPI.rejected, (state, action) => {
        state.OnPremiseNonCompliantTableCountLoading = false;
      })


        // On-premise Compliant listAPI
        .addCase(OnPremiseCompliantTableListAPI.pending, (state, action) => {
          state.OnPremiseCompliantTableListLoading = true;
        })
        .addCase(OnPremiseCompliantTableListAPI.fulfilled, (state, action) => {
          state.OnPremiseCompliantTableListData = action.payload;
          state.OnPremiseCompliantTableListLoading = false;
        })
        .addCase(OnPremiseCompliantTableListAPI.rejected, (state, action) => {
          state.OnPremiseCompliantTableListLoading = false;
        })

        // OnPremise Compliant TableCountAPI
        .addCase(OnPremiseCompliantTableCountAPI.pending, (state, action) => {
          state.OnPremiseCompliantTableCountLoading = true;
        })
        .addCase(OnPremiseCompliantTableCountAPI.fulfilled, (state, action) => {
          state.OnPremiseCompliantTableCountData = action.payload;
          state.OnPremiseCompliantTableCountLoading = false;
        })
        .addCase(OnPremiseCompliantTableCountAPI.rejected, (state, action) => {
          state.OnPremiseCompliantTableCountLoading = false;
        })

                // On-premise not applicable listAPI
                .addCase(OnPremiseNotApplicableTableListAPI.pending, (state, action) => {
                  state.OnPremiseNotAppliTableListLoading = true;
                })
                .addCase(OnPremiseNotApplicableTableListAPI.fulfilled, (state, action) => {
                  state.OnPremiseNotApplicableTableListData = action.payload;
                  state.OnPremiseNotAppliTableListLoading = false;
                })
                .addCase(OnPremiseNotApplicableTableListAPI.rejected, (state, action) => {
                  state.OnPremiseNotAppliTableListLoading = false;
                })

                // OnPremise not applicable TableCountAPI
                .addCase(OnPremiseNotApplicableTableCountAPI.pending, (state, action) => {
                  state.OnPremiseNotApplicableTableCountLoading = true;
                })
                .addCase(OnPremiseNotApplicableTableCountAPI.fulfilled, (state, action) => {
                  state.OnPremiseNotApplicableTableCountData = action.payload;
                  state.OnPremiseNotApplicableTableCountLoading = false;
                })
                .addCase(OnPremiseNotApplicableTableCountAPI.rejected, (state, action) => {
                  state.OnPremiseNotApplicableTableCountLoading = false;
                })


                        // On-premise Partially CompliantlistAPI
                        .addCase(OnPremisePartiallyCompliantTableListAPI.pending, (state, action) => {
                          state.OnPremisepartiallyCompliantTableListLoading = true;
                        })
                        .addCase(OnPremisePartiallyCompliantTableListAPI.fulfilled, (state, action) => {
                          state.OnPremisepartiallyCompliantTableListData = action.payload;
                          state.OnPremisepartiallyCompliantTableListLoading = false;
                        })
                        .addCase(OnPremisePartiallyCompliantTableListAPI.rejected, (state, action) => {
                          state.OnPremisepartiallyCompliantTableListLoading = false;
                        })
        
                        // OnPremise partially Compliant TableCountAPI
                        .addCase(OnPremisePartiallyCompliantTableCountAPI.pending, (state, action) => {
                          state.OnPremisePartiallyCompliantTableCountLoading = true;
                        })
                        .addCase(OnPremisePartiallyCompliantTableCountAPI.fulfilled, (state, action) => {
                          state.OnPremisePartiallyCompliantTableCountData = action.payload;
                          state.OnPremisePartiallyCompliantTableCountLoading = false;
                        })
                        .addCase(OnPremisePartiallyCompliantTableCountAPI.rejected, (state, action) => {
                          state.OnPremisePartiallyCompliantTableCountLoading = false;
                        })
                        //details of onpremise
                        .addCase(getDetailsofOnPremise.pending, (state, action) => {
                          state.DetailsLoading = true;
                        })
                        .addCase(getDetailsofOnPremise.fulfilled, (state, action) => {
                          state.DetailsData = action.payload;
                          state.DetailsLoading = false;
                        })
                        .addCase(getDetailsofOnPremise.rejected, (state, action) => {
                          state.DetailsData = [];
                          state.DetailsLoading = false;
                        })


  },
});

export const { resetCircular9Data, resetdrillDownData, setClickAble } = Circular9Slice.actions;
export default Circular9Slice.reducer;