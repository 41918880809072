import React from 'react';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Cookies from 'universal-cookie';
import { handleResponseCode } from '../../app/utilities/helpers';
import GetProxy from '../../app/GetProxy';

const proxy = GetProxy()
const cookies = new Cookies();

//CurrentCycleTableList
export const   CurrentCycleTableList= createAsyncThunk(
  "post/CurrentCycleTableList",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { Payload, pageNumber, itemsPerPage } = props;
      const response = await fetch(
        `${proxy}/api/v1/awsIam/getAwsDetailsWithPaginationAndSearch/${pageNumber}/${itemsPerPage}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(Payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
//PreviousCycleTableCount
export const CurrentCycleTableCount = createAsyncThunk(
  "post/CurrentCycleTableCount",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { Payload, pageNumber, itemsPerPage } = props;
      const response = await fetch(
        `${proxy}/api/v1/awsIam/getAwsDetailsWithSearchCount`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(Payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

//PreviousCycleTableList
export const   PreviousCycleTableList= createAsyncThunk(
  "post/PreviousCycleTableList",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { Payload, pageNumber, itemsPerPage } = props;
      const response = await fetch(
        `${proxy}/api/v1/awsIam/getAwsPreviousCycleMainDetailsWithPaginationAndSearch/${pageNumber}/${itemsPerPage}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(Payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
//PreviousCycleTableCount
export const PreviousCycleTableCount = createAsyncThunk(
  "post/PreviousCycleTableCount",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { Payload, pageNumber, itemsPerPage } = props;
      const response = await fetch(
        `${proxy}/api/v1/awsIam/getAwsPreviousCycleMainDetailsWithSearchCount`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(Payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
//Grouppopup1
export const GroupTable = createAsyncThunk(
  "post/GroupTable",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { Payload, pageNumber, itemsPerPage } = props;
      const response = await fetch(
        `${proxy}/api/v1/awsIam/getAwsGroupUserPolicyPendingAndCompletedDetailsWithPaginationAndSearch/${pageNumber}/${itemsPerPage}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(Payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
//GrouppopupCompleted1
export const GroupTableCompleted = createAsyncThunk(
  "post/GroupTableCompleted",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { Payload, pageNumber, itemsPerPage } = props;
      const response = await fetch(
        `${proxy}/api/v1/awsIam/getAwsGroupUserPolicyPendingAndCompletedDetailsWithPaginationAndSearch/${pageNumber}/${itemsPerPage}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(Payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();
    console.log(response,"....")
      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
//GroupsPrevious
export const GroupTablePrevious = createAsyncThunk(
  "post/GroupTablePrevious",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { Payload, pageNumber, itemsPerPage } = props;
      const response = await fetch(
        `${proxy}/api/v1/awsIam/getAwsGroupUserPolicyPreviousCycleDetailsWithPaginationAndSearch/${pageNumber}/${itemsPerPage}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(Payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

//Groupcountpopup1
export const GroupTableCount = createAsyncThunk(
  "post/GroupTableCount",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { Payload, pageNumber, itemsPerPage } = props;
      const response = await fetch(
        `${proxy}/api/v1/awsIam/getAwsGroupUserPolicyPendingAndCompletedDetailsCount`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(Payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
//GroupcountCompletedpopup1
export const GroupTableCountCompleted = createAsyncThunk(
  "post/GroupTableCountCompleted",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { Payload, pageNumber, itemsPerPage } = props;
      const response = await fetch(
        `${proxy}/api/v1/awsIam/getAwsGroupUserPolicyPendingAndCompletedDetailsCount`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(Payload),
        }
      );
      // handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
//GroupcountPrevious
export const GroupTableCountPrevious = createAsyncThunk(
  "post/GroupTableCountPrevious",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { Payload, pageNumber, itemsPerPage } = props;
      const response = await fetch(
        `${proxy}/api/v1/awsIam/getAwsGroupUserPolicyPreviousCycleCount`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(Payload),
        }
      );
      // handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

//Userspopup1
export const UsersTable = createAsyncThunk(
  "post/UsersTable",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { Payload, pageNumber, itemsPerPage } = props;
      const response = await fetch(
        `${proxy}/api/v1/awsIam/getAwsUserDetailsWithPaginationAndSearch/${pageNumber}/${itemsPerPage}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(Payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
//UsersPrevious
export const UsersTablePrevious = createAsyncThunk(
  "post/UsersTablePrevious",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { Payload, pageNumber, itemsPerPage } = props;
      const response = await fetch(
        `${proxy}/api/v1/awsIam/getAwsPreviousCycleUserDetailsWithPaginationAndSearch/${pageNumber}/${itemsPerPage}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(Payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

//Userscountpopup1
export const UsersTableCount = createAsyncThunk(
  "post/UsersTableCount",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { Payload, pageNumber, itemsPerPage } = props;
      const response = await fetch(
        `${proxy}/api/v1/awsIam/getAwsUserDetailsCount`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(Payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
//UsersTableCountPrevious
export const UsersTableCountPrevious = createAsyncThunk(
  "post/UsersTableCountPrevious",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { Payload, pageNumber, itemsPerPage } = props;
      const response = await fetch(
        `${proxy}/api/v1/awsIam/getAwsPreviousCycleUserDetailsCount`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(Payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

//ServiceRolespopup1
export const ServiceRolesTable = createAsyncThunk(
  "post/ServiceRolesTable",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { Payload, pageNumber, itemsPerPage } = props;
      const response = await fetch(
        `${proxy}/api/v1/awsIam/getAwsServiceRolesWithPaginationAndSearch/${pageNumber}/${itemsPerPage}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(Payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
//ServiceRolesTablePrevious
export const ServiceRolesTablePrevious = createAsyncThunk(
  "post/ServiceRolesTablePrevious",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { Payload, pageNumber, itemsPerPage } = props;
      const response = await fetch(
        `${proxy}/api/v1/awsIam/getAwsPreviousCycleServiceRolesWithPaginationAndSearch/${pageNumber}/${itemsPerPage}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(Payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

//ServiceRolescountpopup1
export const ServiceRolesTableCount = createAsyncThunk(
  "post/ServiceRolesTableCount",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { Payload } = props;
      const response = await fetch(
        `${proxy}/api/v1/awsIam/getAwsServiceRolesCount`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(Payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
//ServiceRolesTableCountPrevious
export const ServiceRolesTableCountPrevious = createAsyncThunk(
  "post/ServiceRolesTableCountPrevious",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { Payload } = props;
      const response = await fetch(
        `${proxy}/api/v1/awsIam/getAwsPreviousCycleServiceRolesCount`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(Payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
//Rolespopup1
export const RolesTable = createAsyncThunk(
  "post/RolesTable",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { Payload, pageNumber, itemsPerPage } = props;
      const response = await fetch(
        `${proxy}/api/v1/awsIam/getAwsRoleDetailsWithPaginationAndSearch/${pageNumber}/${itemsPerPage}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(Payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
//RolesTablePrevious
export const RolesTablePrevious = createAsyncThunk(
  "post/RolesTablePrevious",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { Payload, pageNumber, itemsPerPage } = props;
      const response = await fetch(
        `${proxy}/api/v1/awsIam/getAwsPreviousCycleRoleDetailsWithPaginationAndSearch/${pageNumber}/${itemsPerPage}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(Payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

//Rolescountpopup1
export const RolesTableCount = createAsyncThunk(
  "post/RolesTableCount",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { Payload } = props;
      const response = await fetch(
        `${proxy}/api/v1/awsIam/getAwsRoleDetailsCount`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(Payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
//RolesTableCountPrevious
export const RolesTableCountPrevious = createAsyncThunk(
  "post/RolesTableCountPrevious",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { Payload } = props;
      const response = await fetch(
        `${proxy}/api/v1/awsIam/getPreviousCycleAwsRoleDetailsCount`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(Payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
//Remove..Continue
export const continueRemove = createAsyncThunk(
  "post/continueRemove",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { Payload} = props;
      const response = await fetch(
        `${proxy}/api/v1/awsIam/changeReviewStatus`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(Payload),
        }
      );
      handleResponseCode(response);

      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  });

  //Remove..Continue..Groups
  export const continueRemoveGroups = createAsyncThunk(
    "post/continueRemoveGroups",
    async (props) => {
      const apiToken = cookies.get("apitoken");
      const username = cookies.get("username");
      try {
        const { Payload} = props;
        const response = await fetch(
          `${proxy}/api/v1/awsIam/changeReviewStatusGroup`,
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "DTOP-API-TOKEN": apiToken,
              USERNAME: username,
            },
            body: JSON.stringify(Payload),
          }
        );
        handleResponseCode(response);

        const data = await response.json();
        return data;
      } catch (error) {
        console.error("Error:", error);
        throw error;
      }
    });


// export const CloudUserTotal = createAsyncThunk(
//   "get/Ctotal",
//   async (props) => {
//     const Apitoken = cookies.get("apitoken");
//     const User = cookies.get("username");
//     try {
//       const { appname } = props;
//       const response = await fetch(`${proxy}/api/v1/UserAccessRest/reviewPendingPageDD/${appname}`,
//         {
//           method: "GET",
//           headers: {
//             Accept: "application/json",
//             "Content-Type": "application/json",
//             "DTOP-API-TOKEN": Apitoken,
//             USERNAME: User,
//           },
//         }
//       );
//       handleResponseCode(response);
//       const data = await response.json();

//       return response.ok ? data : Promise.reject(data);
//     } catch (error) {
//       throw error;
//     }
//   }
// )
// export const CloudUserPending = createAsyncThunk(
//   "get/Cpending",
//   async (props) => {
//     const Apitoken = cookies.get("apitoken");
//     const User = cookies.get("username");
//     try {
//       const { appname } = props;
//       const response = await fetch(`${proxy}/api/v1/UserAccessRest/reviewPendingPageDDPending/${appname}`,
//         {
//           method: "GET",
//           headers: {
//             Accept: "application/json",
//             "Content-Type": "application/json",
//             "DTOP-API-TOKEN": Apitoken,
//             USERNAME: User,
//           },
//         }
//       );
//       handleResponseCode(response);
//       const data = await response.json();

//       return response.ok ? data : Promise.reject(data);
//     } catch (error) {
//       throw error;
//     }
//   }
// )

// export const CloudUserContinue = createAsyncThunk(
//   "get/CContinue",
//   async (props) => {
//     const Apitoken = cookies.get("apitoken");
//     const User = cookies.get("username");
//     try {
//       const { appname } = props;
//       const response = await fetch(`${proxy}/api/v1/UserAccessRest/reviewPendingPageDDContinue/${appname}`,
//         {
//           method: "GET",
//           headers: {
//             Accept: "application/json",
//             "Content-Type": "application/json",
//             "DTOP-API-TOKEN": Apitoken,
//             USERNAME: User,
//           },
//         }
//       );
//       handleResponseCode(response);
//       const data = await response.json();

//       return response.ok ? data : Promise.reject(data);
//     } catch (error) {
//       throw error;
//     }
//   }
// )
// export const CloudUserRemove = createAsyncThunk(
//   "get/CRemove",
//   async (props) => {
//     const Apitoken = cookies.get("apitoken");
//     const User = cookies.get("username");
//     try {
//       const { appname } = props;
//       const response = await fetch(`${proxy}/api/v1/UserAccessRest/reviewPendingPageDDRemove/${appname}`,
//         {
//           method: "GET",
//           headers: {
//             Accept: "application/json",
//             "Content-Type": "application/json",
//             "DTOP-API-TOKEN": Apitoken,
//             USERNAME: User,
//           },
//         }
//       );
//       handleResponseCode(response);
//       const data = await response.json();

//       return response.ok ? data : Promise.reject(data);
//     } catch (error) {
//       throw error;
//     }
//   }
// )

// export const onpremiseTotal = createAsyncThunk(
//   "get/PTotal",
//   async (props) => {
//     const Apitoken = cookies.get("apitoken");
//     const User = cookies.get("username");
//     try {
//       const { appname } = props;
//       const response = await fetch(`${proxy}/api/v1/UserAccessRest/reviewPendingPageOnPremiseDD/${appname}`,
//         {
//           method: "GET",
//           headers: {
//             Accept: "application/json",
//             "Content-Type": "application/json",
//             "DTOP-API-TOKEN": Apitoken,
//             USERNAME: User,
//           },
//         }
//       );
//       handleResponseCode(response);
//       const data = await response.json();

//       return response.ok ? data : Promise.reject(data);
//     } catch (error) {
//       throw error;
//     }
//   }
// )

// export const onpremisePending = createAsyncThunk(
//   "get/PPending",
//   async (props) => {
//     const Apitoken = cookies.get("apitoken");
//     const User = cookies.get("username");
//     try {
//       const { appname } = props;
//       const response = await fetch(`${proxy}/api/v1/UserAccessRest/reviewPendingPageOnPremiseDDPending/${appname}`,
//         {
//           method: "GET",
//           headers: {
//             Accept: "application/json",
//             "Content-Type": "application/json",
//             "DTOP-API-TOKEN": Apitoken,
//             USERNAME: User,
//           },
//         }
//       );
//       handleResponseCode(response);
//       const data = await response.json();

//       return response.ok ? data : Promise.reject(data);
//     } catch (error) {
//       throw error;
//     }
//   }
// )

// export const onpremiseContinue = createAsyncThunk(
//   "get/PContinue",
//   async (props) => {
//     const Apitoken = cookies.get("apitoken");
//     const User = cookies.get("username");
//     try {
//       const { appname } = props;
//       const response = await fetch(`${proxy}/api/v1/UserAccessRest/reviewPendingPageOnPremiseDDContinue/${appname}`,
//         {
//           method: "GET",
//           headers: {
//             Accept: "application/json",
//             "Content-Type": "application/json",
//             "DTOP-API-TOKEN": Apitoken,
//             USERNAME: User,
//           },
//         }
//       );
//       handleResponseCode(response);
//       const data = await response.json();

//       return response.ok ? data : Promise.reject(data);
//     } catch (error) {
//       throw error;
//     }
//   }
// )

// export const onpremiseRemove = createAsyncThunk(
//   "get/PRemove",
//   async (props) => {
//     const Apitoken = cookies.get("apitoken");
//     const User = cookies.get("username");
//     try {
//       const { appname } = props;
//       const response = await fetch(`${proxy}/api/v1/UserAccessRest/reviewPendingPageOnPremiseDDRemove/${appname}`,
//         {
//           method: "GET",
//           headers: {
//             Accept: "application/json",
//             "Content-Type": "application/json",
//             "DTOP-API-TOKEN": Apitoken,
//             USERNAME: User,
//           },
//         }
//       );
//       handleResponseCode(response);
//       const data = await response.json();

//       return response.ok ? data : Promise.reject(data);
//     } catch (error) {
//       throw error;
//     }
//   }
// )
// //completed page 
// export const CompletedCContinue = createAsyncThunk(
//   "get/ComContinue",
//   async (props) => {
//     const Apitoken = cookies.get("apitoken");
//     const User = cookies.get("username");
//     try {
//       const { appname } = props;
//       const response = await fetch(`${proxy}/api/v1/UserAccessRest/reviewCompletePageDDCloudContinue/${appname}`,
//         {
//           method: "GET",
//           headers: {
//             Accept: "application/json",
//             "Content-Type": "application/json",
//             "DTOP-API-TOKEN": Apitoken,
//             USERNAME: User,
//           },
//         }
//       );
//       handleResponseCode(response);
//       const data = await response.json();

//       return response.ok ? data : Promise.reject(data);
//     } catch (error) {
//       throw error;
//     }
//   }
// )
// export const CompletedCRemove = createAsyncThunk(
//   "get/ComRemove",
//   async (props) => {
//     const Apitoken = cookies.get("apitoken");
//     const User = cookies.get("username");
//     try {
//       const { appname } = props;
//       const response = await fetch(`${proxy}/api/v1/UserAccessRest/reviewCompletePageDDCloudRemove/${appname}`,
//         {
//           method: "GET",
//           headers: {
//             Accept: "application/json",
//             "Content-Type": "application/json",
//             "DTOP-API-TOKEN": Apitoken,
//             USERNAME: User,
//           },
//         }
//       );
//       handleResponseCode(response);
//       const data = await response.json();

//       return response.ok ? data : Promise.reject(data);
//     } catch (error) {
//       throw error;
//     }
//   }
// )

// export const CompletedPermiseContinue = createAsyncThunk(
//   "get/ComPerContinue",
//   async (props) => {
//     const Apitoken = cookies.get("apitoken");
//     const User = cookies.get("username");
//     try {
//       const { appname } = props;
//       const response = await fetch(`${proxy}/api/v1/UserAccessRest/reviewCompletePageOnPremiseDD/${appname}`,
//         {
//           method: "GET",
//           headers: {
//             Accept: "application/json",
//             "Content-Type": "application/json",
//             "DTOP-API-TOKEN": Apitoken,
//             USERNAME: User,
//           },
//         }
//       );
//       handleResponseCode(response);
//       const data = await response.json();

//       return response.ok ? data : Promise.reject(data);
//     } catch (error) {
//       throw error;
//     }
//   }
// )

// export const CompletedPermiseRemove = createAsyncThunk(
//   "get/ComPerRemove",
//   async (props) => {
//     const Apitoken = cookies.get("apitoken");
//     const User = cookies.get("username");
//     try {
//       const { appname } = props;
//       const response = await fetch(`${proxy}/api/v1/UserAccessRest/reviewCompletePageOnPremiseDDRemove/${appname}`,
//         {
//           method: "GET",
//           headers: {
//             Accept: "application/json",
//             "Content-Type": "application/json",
//             "DTOP-API-TOKEN": Apitoken,
//             USERNAME: User,
//           },
//         }
//       );
//       handleResponseCode(response);
//       const data = await response.json();

//       return response.ok ? data : Promise.reject(data);
//     } catch (error) {
//       throw error;
//     }
//   }
// )
export const ComplianceTrendAPI = createAsyncThunk(
  "post/ComplianceTrendAPI",
  async (props) => {
    const { payload } = props;
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    try {

      const response = await fetch(`${proxy}/api/v1/UserAccessRest/CISOGraphOne`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": Apitoken,
            USERNAME: User,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();
      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

const initialState = {
  CTotalData: [],
  CPendingData: [],
  CContinueData: [],
  CRemovedData: [],
  PTotalData: [],
  PPendingData: [],
  PContinueData: [],
  PRemovedData: [],
  CompltedCContinueData: [],
  CompltedCRemoveData: [],
  CompltedPContinueData: [],
  CompltedPRemoveData: [],
  CompletedLoading: false,
  CTotalloading: false,
  complianceGraphData: [],
  complianceLoading: false,
  UsersTableListData: [],
  UsersTableListDataLoading: false,
  UsersTablePreviousListData:[],
  UsersTablePreviousListDataLoading:false,
  ServiceRolesTableListData: [],
  ServiceRolesTableListDataLoading: false,
  ServiceRolesPreviousTableListData:[],
  ServiceRolesPreviousTableListDataLoading:false,
  RolesTableListData: [],
  RolesTableListDataLoading: false,
  RolesTablePreviousListData:[],
  RolesTablePreviousListDataLoading:false,
  RolesListPreviousCountData:[],
  RolesListPreviousCountLoading:false,
  GroupsUsersListData: [],
  GroupsUsersListDataLoading: false,
  GroupsUsersPreviousListData:[],
  GroupsUsersPreviousListDataLoading:false,
  UsersListCountData: [],
  UsersTableListCountLoading: false,
  UsersListPreviousCountData:[],
  UsersTablePreviousListCountLoading:false,
  ServiceRolesListCountData: [],
  ServiceRolesListCountLoading: false,
  ServiceRolesPreviousListCountData:[],
  ServiceRolesPreviousListCountLoading:false,
  RolesListCountData: [],
  RolesListCountLoading: false,
  AttachedPolicyListData: [],
  AttachedPolicyListDataLoading: false,
  AttachedPolicyPreviousListData:[],
  AttachedPolicyPreviousListDataLoading:false,
  InlinePolicyListData: [],
  InlinePolicyListDataLoading: false,
  InlinePolicyPreviousListData:[],
  InlinePolicyPreviousListDataLoading:false,
  GroupsUsersListCountData: [],
  GroupsUsersListCountLoading: false,
  GroupsUsersPreviousListCountData:[],
  GroupsUsersPreviousListCountLoading:false,
  AttachedPolicyListCountData: [],
  AttachedPolicyListCountLoading: false,
  AttachedPolicyPreviousListCountData:[],
  AttachedPolicyPreviousListCountLoading:false,
  InlinePolicyListCountData: [],
  InlinePolicyListCountLoading: false,
  InlinePolicyPreviousListCountData:[],
  InlinePolicyPreviousListCountLoading:false,
  GroupTableListData:[],
  GroupTableListDataLoading:false,
  GroupListCountData:[],
  GroupTableListCountLoading:false,
  continueRemoveAction:{},
  continueRemoveLoading:false,
  continueRemoveGroupsAction:{},
  continueRemoveGroupsLoading:false,
  GroupListCompletedCountData:[],
  GroupTableCompletedListCountLoading:false,
  GroupListPreviousCountData:[],
  GroupTablePreviousListCountLoading:false,
  GroupTableCompletedListData:[],
  GroupTableCompletedListDataLoading:false,
  GroupTablePreviousListData:[],
  GroupTablePreviousListDataLoading:false,
  PreviousCycleTableListData:[],
  PreviousCycleTableListDataLoading:false,
  PreviousCycleTableCountData:[],
  PreviousCycleTableCountDataLoading:false,
  CurrentCycleTableCountData:[],
  CurrentCycleTableCountDataLoading:false,
  CurrentCycleTableListData:[],
  CurrentCycleTableListDataLoading:false,
  error: '',
}


export const reviewslice = createSlice({
  name: 'review',
  initialState,
  reducers: {
    reviewSlicereset: () => {
      return initialState;
    }
  },
  extraReducers: (builder) => {
    builder

    
      //PreviousCycleTableList
      .addCase(PreviousCycleTableList.pending, (state, action) => {
        state.PreviousCycleTableListDataLoading = true;
      })
      .addCase(PreviousCycleTableList.fulfilled, (state, action) => {
        state.PreviousCycleTableListData = action.payload;
        state.PreviousCycleTableListDataLoading = false;
      })
      .addCase(PreviousCycleTableList.rejected, (state, action) => {
        state.PreviousCycleTableListData = [];
        state.PreviousCycleTableListDataLoading = false;
      })
       //PreviousCycleTableCount
       .addCase(PreviousCycleTableCount.pending, (state, action) => {
        state.PreviousCycleTableCountDataLoading = true;
      })
      .addCase(PreviousCycleTableCount.fulfilled, (state, action) => {
        state.PreviousCycleTableCountData = action.payload;
        state.PreviousCycleTableCountDataLoading = false;
      })
      .addCase(PreviousCycleTableCount.rejected, (state, action) => {
        state.PreviousCycleTableCountData = [];
        state.PreviousCycleTableCountDataLoading = false;
      })
        //CurrentCycleTableList
        .addCase(CurrentCycleTableList.pending, (state, action) => {
          state.CurrentCycleTableListDataLoading = true;
        })
        .addCase(CurrentCycleTableList.fulfilled, (state, action) => {
          state.CurrentCycleTableListData = action.payload;
          state.CurrentCycleTableListDataLoading = false;
        })
        .addCase(CurrentCycleTableList.rejected, (state, action) => {
          state.CurrentCycleTableListData = [];
          state.CurrentCycleTableListDataLoading = false;
        })
         //CurrentCycleTableCount
         .addCase(CurrentCycleTableCount.pending, (state, action) => {
          state.CurrentCycleTableCountDataLoading = true;
        })
        .addCase(CurrentCycleTableCount.fulfilled, (state, action) => {
          state.CurrentCycleTableCountData = action.payload;
          state.CurrentCycleTableCountDataLoading = false;
        })
        .addCase(CurrentCycleTableCount.rejected, (state, action) => {
          state.CurrentCycleTableCountData = [];
          state.CurrentCycleTableCountDataLoading = false;
        })

      //Grouppopup1
      .addCase(GroupTable.pending, (state, action) => {
        state.GroupTableListDataLoading = true;
      })
      .addCase(GroupTable.fulfilled, (state, action) => {
        state.GroupTableListData = action.payload;
        state.GroupTableListDataLoading = false;
      })
      .addCase(GroupTable.rejected, (state, action) => {
        state.GroupTableListData = [];
        state.GroupTableListDataLoading = false;
      })
        //GrouppopupCompleted1
        .addCase(GroupTableCompleted.pending, (state, action) => {
          state.GroupTableCompletedListDataLoading = true;
        })
        .addCase(GroupTableCompleted.fulfilled, (state, action) => {
          state.GroupTableCompletedListData = action.payload;
          state.GroupTableCompletedListDataLoading = false;
        })
        .addCase(GroupTableCompleted.rejected, (state, action) => {
          state.GroupTableCompletedListData = [];
          state.GroupTableCompletedListDataLoading = false;
        })
          // //GroupsPrevious
          .addCase(GroupTablePrevious.pending, (state, action) => {
            state.GroupTablePreviousListDataLoading = true;
          })
          .addCase(GroupTablePrevious.fulfilled, (state, action) => {
            state.GroupTablePreviousListData = action.payload;
            state.GroupTablePreviousListDataLoading = false;
          })
          .addCase(GroupTablePrevious.rejected, (state, action) => {
            state.GroupTablePreviousListData = [];
            state.GroupTablePreviousListDataLoading = false;
          })

      //Groupcountpopup1
      .addCase(GroupTableCount.pending, (state, action) => {
        state.GroupTableListCountLoading = true;
      })
      .addCase(GroupTableCount.fulfilled, (state, action) => {
        state.GroupListCountData = action.payload;
        state.GroupTableListCountLoading = false;
      })
      .addCase(GroupTableCount.rejected, (state, action) => {
        state.GroupListCountData = [];
        state.GroupTableListCountLoading = false;
      })
       //GroupcountCompletedpopup1
       .addCase(GroupTableCountCompleted.pending, (state, action) => {
        state.GroupTableCompletedListCountLoading = true;
      })
      .addCase(GroupTableCountCompleted.fulfilled, (state, action) => {
        state.GroupListCompletedCountData = action.payload;
        state.GroupTableCompletedListCountLoading = false;
      })
      .addCase(GroupTableCountCompleted.rejected, (state, action) => {
        state.GroupListCompletedCountData = [];
        state.GroupTableCompletedListCountLoading = false;
      })
        //  //GroupcountPrevious
         .addCase(GroupTableCountPrevious.pending, (state, action) => {
          state.GroupTablePreviousListCountLoading = true;
        })
        .addCase(GroupTableCountPrevious.fulfilled, (state, action) => {
          state.GroupListPreviousCountData = action.payload;
          state.GroupTablePreviousListCountLoading = false;
        })
        .addCase(GroupTableCountPrevious.rejected, (state, action) => {
          state.GroupListPreviousCountData = [];
          state.GroupTablePreviousListCountLoading = false;
        })
      //Userspopup1
      .addCase(UsersTable.pending, (state, action) => {
        state.UsersTableListDataLoading = true;
      })
      .addCase(UsersTable.fulfilled, (state, action) => {
        state.UsersTableListData = action.payload;
        state.UsersTableListDataLoading = false;
      })
      .addCase(UsersTable.rejected, (state, action) => {
        state.UsersTableListData = [];
        state.UsersTableListDataLoading = false;
      })
      //  //UsersPrevious
       .addCase(UsersTablePrevious.pending, (state, action) => {
        state.UsersTablePreviousListDataLoading = true;
      })
      .addCase(UsersTablePrevious.fulfilled, (state, action) => {
        state.UsersTablePreviousListData = action.payload;
        state.UsersTablePreviousListDataLoading = false;
      })
      .addCase(UsersTablePrevious.rejected, (state, action) => {
        state.UsersTablePreviousListData = [];
        state.UsersTablePreviousListDataLoading = false;
      })

      //Userscountpopup1
      .addCase(UsersTableCount.pending, (state, action) => {
        state.UsersTableListCountLoading = true;
      })
      .addCase(UsersTableCount.fulfilled, (state, action) => {
        state.UsersListCountData = action.payload;
        state.UsersTableListCountLoading = false;
      })
      .addCase(UsersTableCount.rejected, (state, action) => {
        state.UsersListCountData = [];
        state.UsersTableListCountLoading = false;
      })
      //UsersTableCountPrevious
      .addCase(UsersTableCountPrevious.pending, (state, action) => {
        state.UsersTablePreviousListCountLoading = true;
      })
      .addCase(UsersTableCountPrevious.fulfilled, (state, action) => {
        state.UsersListPreviousCountData = action.payload;
        state.UsersTablePreviousListCountLoading = false;
      })
      .addCase(UsersTableCountPrevious.rejected, (state, action) => {
        state.UsersListPreviousCountData = [];
        state.UsersTablePreviousListCountLoading = false;
      })

      //ServiceRolespopup1
      .addCase(ServiceRolesTable.pending, (state, action) => {
        state.ServiceRolesTableListDataLoading = true;
      })
      .addCase(ServiceRolesTable.fulfilled, (state, action) => {
        state.ServiceRolesTableListData = action.payload;
        state.ServiceRolesTableListDataLoading = false;
      })
      .addCase(ServiceRolesTable.rejected, (state, action) => {
        state.ServiceRolesTableListData = [];
        state.ServiceRolesTableListDataLoading = false;
      })
      //  //ServiceRolesTablePrevious
       .addCase(ServiceRolesTablePrevious.pending, (state, action) => {
        state.ServiceRolesPreviousTableListDataLoading = true;
      })
      .addCase(ServiceRolesTablePrevious.fulfilled, (state, action) => {
        state.ServiceRolesPreviousTableListData = action.payload;
        state.ServiceRolesPreviousTableListDataLoading = false;
      })
      .addCase(ServiceRolesTablePrevious.rejected, (state, action) => {
        state.ServiceRolesPreviousTableListData = [];
        state.ServiceRolesPreviousTableListDataLoading = false;
      })

      //ServiceRolescountpopup1
      .addCase(ServiceRolesTableCount.pending, (state, action) => {
        state.ServiceRolesListCountLoading = true;
      })
      .addCase(ServiceRolesTableCount.fulfilled, (state, action) => {
        state.ServiceRolesListCountData = action.payload;
        state.ServiceRolesListCountLoading = false;
      })
      .addCase(ServiceRolesTableCount.rejected, (state, action) => {
        state.ServiceRolesListCountData = [];
        state.ServiceRolesListCountLoading = false;
      })
      //ServiceRolesTableCountPrevious
      .addCase(ServiceRolesTableCountPrevious.pending, (state, action) => {
        state.ServiceRolesPreviousListCountLoading = true;
      })
      .addCase(ServiceRolesTableCountPrevious.fulfilled, (state, action) => {
        state.ServiceRolesPreviousListCountData = action.payload;
        state.ServiceRolesPreviousListCountLoading = false;
      })
      .addCase(ServiceRolesTableCountPrevious.rejected, (state, action) => {
        state.ServiceRolesPreviousListCountData = [];
        state.ServiceRolesPreviousListCountLoading = false;
      })

      //Rolespopup1
      .addCase(RolesTable.pending, (state, action) => {
        state.RolesTableListDataLoading = true;
      })
      .addCase(RolesTable.fulfilled, (state, action) => {
        state.RolesTableListData = action.payload;
        state.RolesTableListDataLoading = false;
      })
      .addCase(RolesTable.rejected, (state, action) => {
        state.RolesTableListData = [];
        state.RolesTableListDataLoading = false;
      })
      //  //RolesPrevious
       .addCase(RolesTablePrevious.pending, (state, action) => {
        state.RolesTablePreviousListDataLoading = true;
      })
      .addCase(RolesTablePrevious.fulfilled, (state, action) => {
        state.RolesTablePreviousListData = action.payload;
        state.RolesTablePreviousListDataLoading = false;
      })
      .addCase(RolesTablePrevious.rejected, (state, action) => {
        state.RolesTablePreviousListData = [];
        state.RolesTablePreviousListDataLoading = false;
      })
      
      //Rolescountpopup1
      .addCase(RolesTableCount.pending, (state, action) => {
        state.RolesListCountLoading = true;
      })
      .addCase(RolesTableCount.fulfilled, (state, action) => {
        state.RolesListCountData = action.payload;
        state.RolesListCountLoading = false;
      })
      .addCase(RolesTableCount.rejected, (state, action) => {
        state.RolesListCountData = [];
        state.RolesListCountLoading = false;
      })
        // //RolescountPrevious
        .addCase(RolesTableCountPrevious.pending, (state, action) => {
          state.RolesListPreviousCountLoading = true;
        })
        .addCase(RolesTableCountPrevious.fulfilled, (state, action) => {
          state.RolesListPreviousCountData = action.payload;
          state.RolesListPreviousCountLoading = false;
        })
        .addCase(RolesTableCountPrevious.rejected, (state, action) => {
          state.RolesListPreviousCountData = [];
          state.RolesListPreviousCountLoading = false;
        })

      //Remove..Continue
      .addCase(continueRemove.pending, (state, action) => {
        //state.TopicDataSave = "";
        state.continueRemoveLoading = true;
      })
      .addCase(continueRemove.fulfilled, (state, action) => {
        state.continueRemoveAction = action.payload;
        state.continueRemoveLoading = false;
      })
      .addCase(continueRemove.rejected, (state, action) => {
        state.continueRemoveLoading = false;
        //state.TopicDataSave = "";
        state.TopicerrorSave = "Something went wrong";
      })

       //Remove..Continue..Groups
       .addCase(continueRemoveGroups.pending, (state, action) => {
        //state.TopicDataSave = "";
        state.continueRemoveGroupsLoading = true;
      })
      .addCase(continueRemoveGroups.fulfilled, (state, action) => {
        state.continueRemoveGroupsAction = action.payload;
        state.continueRemoveGroupsLoading = false;
      })
      .addCase(continueRemoveGroups.rejected, (state, action) => {
        state.continueRemoveGroupsLoading = false;
      })

      // //CTtoal
      // .addCase(CloudUserTotal.pending, (state, action) => {
      //   state.CTotalloading = true;
      //   state.error = "";
      // })
      // .addCase(CloudUserTotal.fulfilled, (state, action) => {
      //   state.CTotalData = action.payload;
      //   state.CTotalloading = false;
      // })
      // .addCase(CloudUserTotal.rejected, (state, action) => {
      //   state.CTotalloading = false;
      //   state.CTotalData = [];
      //   state.error = "something went wrong";

      // })
      //CPending
      // .addCase(CloudUserPending.pending, (state, action) => {
      //   state.CTotalloading = true;
      //   state.error = "";
      // })
      // .addCase(CloudUserPending.fulfilled, (state, action) => {
      //   state.CPendingData = action.payload;
      //   state.CTotalloading = false;
      // })
      // .addCase(CloudUserPending.rejected, (state, action) => {
      //   state.CTotalloading = false;
      //   state.CPendingData = [];
      //   state.error = "something went wrong";

      // })
      //CContinue
      // .addCase(CloudUserContinue.pending, (state, action) => {
      //   state.CTotalloading = true;
      //   state.error = "";
      // })
      // .addCase(CloudUserContinue.fulfilled, (state, action) => {
      //   state.CContinueData = action.payload;
      //   state.CTotalloading = false;
      // })
      // .addCase(CloudUserContinue.rejected, (state, action) => {
      //   state.CTotalloading = false;
      //   state.CContinueData = [];
      //   state.error = "something went wrong";

      // })

      // //CRemove
      // .addCase(CloudUserRemove.pending, (state, action) => {
      //   state.CTotalloading = true;
      //   state.error = "";
      // })
      // .addCase(CloudUserRemove.fulfilled, (state, action) => {
      //   state.CRemovedData = action.payload;
      //   state.CTotalloading = false;
      // })
      // .addCase(CloudUserRemove.rejected, (state, action) => {
      //   state.CTotalloading = false;
      //   state.CRemovedData = [];
      //   state.error = "something went wrong";

      // })
      // //PTotal
      // .addCase(onpremiseTotal.pending, (state, action) => {
      //   state.CTotalloading = true;
      //   state.error = "";
      // })
      // .addCase(onpremiseTotal.fulfilled, (state, action) => {
      //   state.PTotalData = action.payload;
      //   state.CTotalloading = false;
      // })
      // .addCase(onpremiseTotal.rejected, (state, action) => {
      //   state.CTotalloading = false;
      //   state.PTotalData = [];
      //   state.error = "something went wrong";

      // })
      // //PPending
      // .addCase(onpremisePending.pending, (state, action) => {
      //   state.CTotalloading = true;
      //   state.error = "";
      // })
      // .addCase(onpremisePending.fulfilled, (state, action) => {
      //   state.PPendingData = action.payload;
      //   state.CTotalloading = false;
      // })
      // .addCase(onpremisePending.rejected, (state, action) => {
      //   state.CTotalloading = false;
      //   state.PPendingData = [];
      //   state.error = "something went wrong";

      // })
      // //PContinue
      // .addCase(onpremiseContinue.pending, (state, action) => {
      //   state.CTotalloading = true;
      //   state.error = "";
      // })
      // .addCase(onpremiseContinue.fulfilled, (state, action) => {
      //   state.PContinueData = action.payload;
      //   state.CTotalloading = false;
      // })
      // .addCase(onpremiseContinue.rejected, (state, action) => {
      //   state.CTotalloading = false;
      //   state.PContinueData = [];
      //   state.error = "something went wrong";

      // })
      // //PRemove
      // .addCase(onpremiseRemove.pending, (state, action) => {
      //   state.CTotalloading = true;
      //   state.error = "";
      // })
      // .addCase(onpremiseRemove.fulfilled, (state, action) => {
      //   state.PRemovedData = action.payload;
      //   state.CTotalloading = false;
      // })
      // .addCase(onpremiseRemove.rejected, (state, action) => {
      //   state.CTotalloading = false;
      //   state.PRemovedData = [];
      //   state.error = "something went wrong";

      // })
      // //COMContinue
      // .addCase(CompletedCContinue.pending, (state, action) => {
      //   state.CompletedLoading = true;
      //   state.error = "";
      // })
      // .addCase(CompletedCContinue.fulfilled, (state, action) => {
      //   state.CompltedCContinueData = action.payload;
      //   state.CompletedLoading = false;
      // })
      // .addCase(CompletedCContinue.rejected, (state, action) => {
      //   state.CompletedLoading = false;
      //   state.CompltedCContinueData = [];
      //   state.error = "something went wrong";

      // })
      // //COMemove
      // .addCase(CompletedCRemove.pending, (state, action) => {
      //   state.CompletedLoading = true;
      //   state.error = "";
      // })
      // .addCase(CompletedCRemove.fulfilled, (state, action) => {
      //   state.CompltedCRemoveData = action.payload;
      //   state.CompletedLoading = false;
      // })
      // .addCase(CompletedCRemove.rejected, (state, action) => {
      //   state.CompletedLoading = false;
      //   state.CompltedCRemoveData = [];
      //   state.error = "something went wrong";

      // })

      // //COMPerContinue
      // .addCase(CompletedPermiseContinue.pending, (state, action) => {
      //   state.CompletedLoading = true;
      //   state.error = "";
      // })
      // .addCase(CompletedPermiseContinue.fulfilled, (state, action) => {
      //   state.CompltedPContinueData = action.payload;
      //   state.CompletedLoading = false;
      // })
      // .addCase(CompletedPermiseContinue.rejected, (state, action) => {
      //   state.CompletedLoading = false;
      //   state.CompltedPContinueData = [];
      //   state.error = "something went wrong";

      // })
      // //COMPerRemove
      // .addCase(CompletedPermiseRemove.pending, (state, action) => {
      //   state.CompletedLoading = true;
      //   state.error = "";
      // })
      // .addCase(CompletedPermiseRemove.fulfilled, (state, action) => {
      //   state.CompltedPRemoveData = action.payload;
      //   state.CompletedLoading = false;
      // })
      // .addCase(CompletedPermiseRemove.rejected, (state, action) => {
      //   state.CompletedLoading = false;
      //   state.CompltedPRemoveData = [];
      //   state.error = "something went wrong";

      // })
      //ComplianceTrendAPI
      .addCase(ComplianceTrendAPI.pending, (state, action) => {
        state.complianceLoading = true;
      })
      .addCase(ComplianceTrendAPI.fulfilled, (state, action) => {
        state.complianceGraphData = action.payload;
        state.complianceLoading = false;
      })
      .addCase(ComplianceTrendAPI.rejected, (state, action) => {
        state.complianceLoading = false;
      })
  },
});

export const { reviewSlicereset } = reviewslice.actions;
export default reviewslice.reducer;