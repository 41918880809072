import React, { useEffect, useState } from 'react';
import CompleteTableData from '../../app/common-components/Table/CompleteTableData';
import { deletedIcon, Download_Report, editIcon } from '../../assets/images';
import { TbReport } from "react-icons/tb";
import { setIsOffCanvasOpen, setPageLoading } from '../../app/commonSlice';
import ButtonComponent from '../../app/common-components/ButtonComponent';
import Input from '../../app/common-components/form/input';
import Spinners from '../../app/common-components/Spinner';
import CustomOffCanvas from '../../app/common-components/CustomOffCanvas';
import { Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { getApplicationProjects, ProjectInfoListApi, projectScanDelete, projectScanListAPI, projecttriggerScanAPI, projScanFileDownloadAPI, saveUpdateProjectsAPI } from './InventorySlice';
import { useSelector } from 'react-redux';
import { convertEpochToDate, decrypt, useCommonAlert } from '../../app/utilities/helpers';
import DiscardChanges from '../../app/common-components/DiscardChanges';
import CustomModal from '../../app/common-components/CustomModal';
import { isEmpty } from 'lodash';
import CustomSelect from '../../app/common-components/select';
import { DownloadPDF } from './downloadReport';
import Cookies from "universal-cookie";
import { projectPDF } from '../projectPDF';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import { FaInfoCircle, FaFileDownload, FaFileExport } from 'react-icons/fa';
import './style.scss';
import AlertBox from '../../app/common-components/Alert';
import { GrInProgress } from "react-icons/gr";
import ProjectScanModel from './ProjectScanModel';


const ProjectsTable = (props) => {
    const cookies = new Cookies();

    const drilldownLoader = false;
    const dispatch = useDispatch();
    const { common } = useSelector((state) => state);
    const { userName, userRole } = common.loggedUser;
    const { scanStatus } = common.ProjectStatusUpdate;
    const encoded = decrypt(userName)

    const {
        projectScanListData,
        projectScanListAPILoading,
        saveUpdateProjectsData,
        saveUpdateProjectsDataLoading,
        projectScanDeleteData,
        projectScanDeleteLoading,
        getApplicationProjectData,
        getApplicationProjectLoading,
        projecttriggerScanData,
        projecttriggerScanLoading,
        ProjectInfoListData,
        ProjectInfoListLoading,
        projScanFileDownloadLoading
    } = useSelector((state) => state.InventorySliceAPI);

    const ProjectInfoData = ProjectInfoListData?.results ?ProjectInfoListData?.results:[];
    const ProjectInfoCount = ProjectInfoListData?.totalElements ?ProjectInfoListData?.totalElements:0;
    const appList = getApplicationProjectData?.filter((list) => list.appName !== null).map(list => {
        return {
            label: list.appName,
            value: list.appName
        }
    })
    const TableContent = projectScanListData?.results ? projectScanListData?.results : [];
    const totalElements = projectScanListData?.totalElements ? projectScanListData?.totalElements : "";
    const [state, setState] = useState({
        formData: {
            Application: [],
            Version: "",
            Branch: "",
            Repository: "",
            Project: "",
            id: "",
        },
        BranchError: "",
        BranchErrorMessage: "",
        RepositoryError: "",
        RepositoryErrorMessage: "",
        ProjectError: "",
        ProjectErrorMessage: "",
        deleteId: "",
        conformationPop: false,
        ModelOpen: false,
        DeletemodalOpen: false,
        InfoModal: false,
        InfoID:"",
        InfoRepo:"",
        InfoProjectName:"",
        scan: false,
    });
    const ProjectStatusUpdate = scanStatus;

    const [currentAction, setCurrentAction] = useState("");
    const [selectAll, setSelectAll] = useState(false);
    const [selectableIDS, setSelectableIds] = useState([]);
    const [selectedIds, setSelectedIds] = useState([]);
    const [selectedRepo, setSelectedRepo] = useState([]);
    const [selectedBranch, setSelectedBranch] = useState([]);
    const [pageNo, setpageNo] = useState(0);
    const [pageLimit, setpageLimit] = useState(10);
    const [searchKey, setsearchKey] = useState("");
    const calculatedPageCount = totalElements
        ? Math.ceil(totalElements / pageLimit)
        : 0;
    const finalPageCount = calculatedPageCount !== 0 ? calculatedPageCount : 1;
    const [InfopageNo, setInfopageNo] = useState(0);
    const [InfopageLimit, setInfopageLimit] = useState(10);
    const [InfosearchKey, setInfosearchKey] = useState("");
    const calculatedInfoPageCount = totalElements
        ? Math.ceil(totalElements / pageLimit)
        : 0;
    const finalPageInfoCount = calculatedInfoPageCount !== 0 ? calculatedPageCount : 1;
    const { callCommonAlert, hideCommonAlert } = useCommonAlert();

    const callAlert = (type, msg) => {
        callCommonAlert(type, msg);
        hideCommonAlert();
    }

    useEffect(() => {
        const rowID = TableContent
            .filter((data) => data.status === "available") // Filter based on connectivityStatus
        setSelectableIds(rowID);
    }, [TableContent]);

    useEffect(() => {
        if (!_.isEmpty(TableContent) && !_.isEmpty(selectedIds) && !isEmpty(selectableIDS) && selectableIDS.every((data) => selectedIds.includes(data.id))) {
            setSelectAll(true);
        } else {
            setSelectAll(false);
        }
    }, [selectedIds, selectableIDS])

        useEffect(() => {
        if(projScanFileDownloadLoading){
            callCommonAlert('info', "Generating your file!");
        } else {
            hideCommonAlert(3000);
        }
    },[projScanFileDownloadLoading])

    const handleSelectAll = (event) => {
        const checked = event.target.checked;
        setSelectAll(checked);
        setSelectedIds(checked ? selectableIDS.map((data) => data.id) : [])
        setSelectedRepo(checked ? selectableIDS.map((data) => data.repo) : [])
        setSelectedBranch(checked ? selectableIDS.map((data) => data.branch) : [])
    };
    const handleCheckboxChange = (event, row) => {
        const checked = event.target.checked;
        if (checked) {
            setSelectedIds([...selectedIds, row.original.id]);
            const selectRepo = {
                label: row?.original?.repo,
                value: row?.original?.id,
            };
            const selectBranch = {
                label: row?.original?.branch,
                value: row?.original?.id,
            };
            setSelectedRepo([...selectedRepo, selectRepo]);
            setSelectedBranch([...selectedBranch, selectBranch]);
        } else {
            const updatedSelectedIds = selectedIds.filter((dataID) => dataID !== row.original.id);
            const updatedSelectedRepo = selectedRepo.filter(
                (repo) => repo.value !== row.original.id
            );
            const updatedSelectedBranch = selectedBranch.filter(
                (branch) => branch.value !== row.original.id
            );
            setSelectedIds(updatedSelectedIds);
            setSelectedRepo(updatedSelectedRepo);
            setSelectedBranch(updatedSelectedBranch);
        }
    }

    const getPayload = (keyword) => {
        return {
            keyword: keyword ? keyword : '',
        };
    };
    const getInfoPayload = (keyword) => {
        return {
            keyword: keyword ? keyword : '',
        };
    };

    const fetchData = (payload, pageNo, pageLimit) => {
        dispatch(projectScanListAPI({ payload, pageNumber: pageNo, itemsPerPage: pageLimit }));
    };
    const fetchInfoData = (payload, pageNo, pageLimit ,id) => {
        dispatch(ProjectInfoListApi({ payload , id:id , pageNumber: pageNo, itemsPerPage: pageLimit}))
    };

    useEffect(() => {
        const payload = getPayload("");
        fetchData(payload, 0, 10);
        dispatch(getApplicationProjects({ userName, userRole }));

    }, []);

    const handleDownload = (row) => {
        dispatch(projScanFileDownloadAPI({ id: row?.original?.id })).then((response) => {
            const blob = response.payload;
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${row?.original?.repo}_report.pdf`);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

        })

    };
    const ProgressBarStatus = (value) => {
        const total = Object.values(value).reduce((sum, count) => sum + count, 0);
        const percentages = Object.entries(value).reduce((result, [key, value]) => {
            result[key] = total > 0 ? Math.round((value / total) * 100) + "%" : "0%";
            return result;
        }, {});
        return (
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px', width:"100%" }}>
            <div class="progressBar progressBar-container" style={{ display: "flex" }}>
                <div data-tooltip={`Blocker: ${value?.BLOCKER}`} class="progress-blocker progressBar-wrapper" style={{ width: percentages?.BLOCKER }}></div>
                <div data-tooltip={`Critical: ${value?.CRITICAL}`} class="progress-red progressBar-wrapper" style={{ width: percentages?.CRITICAL }}></div>
                <div data-tooltip={`Major: ${value?.MAJOR}`} class="progress-orange progressBar-wrapper" style={{ width: percentages?.MAJOR }}></div>
                <div data-tooltip={`Minor: ${value?.MINOR}`} class="progress-yellow progressBar-wrapper" style={{ width: percentages?.MINOR }}></div>
                <div data-tooltip={`Info: ${value?.INFO}`} class="progress-blue progressBar-wrapper" style={{ width: percentages?.INFO }}></div>
            </div>
            <span className='' style={{ width: '40px', textAlign: 'left', fontWeight: "bold" }}>{total}</span>
            </div>
        );
    };
    const columns = [

        {
            Header: ({ data }) => {
                const allStatusNotAvailable = data.every(item => item.status === "notAvailable");
                return (
                    <div className="center-checkbox">
                        <input
                            type="checkbox"
                            onChange={handleSelectAll}
                            checked={selectAll}
                            disabled={allStatusNotAvailable ||data.length === 0}
                        />
                    </div>
                );
            },
            disableSortBy: true,
            className: "noSort",
            accessor: "select",
            Cell: ({ row }) => {
                return (
                    <div className="center-checkbox">
                        <input
                            type="checkbox"
                            className="center-checkbox"
                            data-testid="rowCheck"
                            onChange={(event) => handleCheckboxChange(event, row)}
                            checked={selectedIds.includes(row.original.id)}
                            disabled={row.original.status !== "available"}
                        />
                    </div>
                );
            },
        },
        {
            Header: "Project",
            accessor: "projectName",
            Cell: ({ row, value }) => {
                return <div >{value ? value : "-"}</div>;
            },
        },
        {
            Header: "Repository",
            accessor: "repo",
            tdClassName:"text-center",
            Cell: ({ row, value }) => {

                return <>{value ? <div className={row.original.status === "available" ? ' test_Repository_open': "test_Repository_closed "}>{value}</div>  : '-'}</>;
            },
        },
        {
            Header: "Branch",
            accessor: "branch",
            Cell: ({ row, value }) => {

                return <div >{value ? value : "-"}</div>;
            },
        },
        {
            Header: "Version",
            accessor: "version",
            Cell: ({ row, value }) => {

                return <div >{value ? value : "-"}</div>;
            },
        },
        {
            Header: "Application Name",
            accessor: "application",
            thClassName: "text-start businesCofgTabAlign",
            Cell: ({ row, value }) => {
                if (Array.isArray(value)) {
                    return (
                        <div>
                            {value.length !== 0 ? value.map((item, index) => (
                                <div key={index}>
                                    {item}{index !== value.length - 1 ? "," : ""}
                                </div>
                            )) : "-"}
                        </div>
                    );
                } else {
                    return <div>{value ? value : "-"}</div>;
                }
            },
        },
        {
            Header: "Last Scanned",
            accessor: "lastScannedDate",
            thClassName: "wrap_class",
            Cell: ({ row,value }) => {

                return <div >{value ? convertEpochToDate(value) : '-'}</div>;
            },
        },
        {
            Header: "Severity Details",
            accessor: "severity",
            tdClassName: "text-center",
            thClassName: "wrap_class text-center ProjectScanprogress",
            disableSortBy: true,
            Cell: ({ row,value }) => {

                return <>{(value!==null) ? ProgressBarStatus(value) : <span  >N/A</span>}</>;
            },
        },
        {
            Header: "Report",
            accessor: "report",
            tdClassName: "text-center",
            thClassName: "text-center",
            Cell: ({ row }) => {
                const isMatchingRepository = row?.original?.repo === ProjectStatusUpdate?.repository;
                const lastScanAvailable =  (row?.original?.lastScannedDate || row?.original?.repo === ProjectStatusUpdate?.repository) ? true :false;
                return (
                    <div>
                        {lastScanAvailable ? (
                            <div className="defaultCursor">
                                {isMatchingRepository ? (
                                    ProjectStatusUpdate?.status !== "Success" && ProjectStatusUpdate?.percentage !== 100 ? (
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={<Tooltip className="tooltipHeader">{"Scan is in Progress"}</Tooltip>}
                                        >
                                            <span className="InvenReportStatus">Pending</span>
                                        </OverlayTrigger>
                                    ) : (
                                        <div className="InvenReport">
                                            <a
                                                className="InvenReport"
                                                onClick={() => handleDownload(row)}
                                            >

                                                <FaFileDownload className='faInfoCircleStyle' size={20} title='Download Report' />
                                            </a>
                                        </div>
                                    )
                                ) : (
                                    <div className="InvenReport">
                                        <a
                                            className="InvenReport"
                                            onClick={() => handleDownload(row)}
                                        >
                                            <FaFileDownload className='faInfoCircleStyle' size={20} title='Download Report' />
                                        </a>
                                    </div>
                                )}
                            </div>) : <div  >N/A</div>}
                    </div>
                );
            },
        },
        {
            Header: "Details",
            accessor: "Details",
            disableSortBy: true,
            thClassName: "templateCursor",
            disableSortBy: true,
            Cell: ({ row }) => {
                return (
                    <td
                        className="text-center"
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <button
                            onClick={row.original.lastScannedDate ? () => handleInfo(row) : undefined}
                            className="modelButton p-0"
                        >
                            <FaInfoCircle
                                className={!row.original.lastScannedDate ? "disabled-icon" : "faInfoCircleStyle"}
                                size={20}
                                data-bs-toggle="tooltip"
                                data-bs-placement="left"
                                disabled={row.original.lastScannedDate? false :true}
                            />
                        </button>
                    </td>
                );
            },
        },
        {
            Header: "Actions",
            accessor: "Action",
            tdClassName: "text-center",
            thClassName: "text-center",
            disableSortBy: true,

            Cell: ({ row, value }) => (
                <div className="actionsBtn d-flex justify-content-center">
                    {" "}
                    <div className="actcol">
                        <a
                            title="Edit"
                            onClick={() => onEditData("edit", row)}
                        >
                            <img src={editIcon} onClick={() => onEditData("edit", row)} />
                        </a></div>
                    <div className="actcol">
                        <a
                            title="Delete"
                            onClick={() => {
                                setState((state) => ({
                                    ...state,
                                    DeletemodalOpen: true,
                                    deleteId: row.original.id,
                                }));
                            }}
                        >
                            <img src={deletedIcon} />
                        </a></div>
                </div>
            ),
        },
    ];


    function getLimit(limit) {
        setpageLimit(limit);
        setpageNo(0);
        const payload = getPayload(searchKey);
        fetchData(payload, 0, limit);
    }
    function getPage(pageNo) {
        setSelectableIds([])
        setSelectAll(false)
        setpageNo(pageNo);
        const payload = getPayload(searchKey);
        fetchData(payload, pageNo, pageLimit);
    }

    function getSearchKey(key) {
        setpageNo(0);
        setpageLimit(10);
        setsearchKey(key);
        const payload = getPayload(key);
        fetchData(payload, 0, 10);
    }

    const handleInfo = (row) => {
        const payload = getInfoPayload("");
        fetchInfoData(payload, 0, 10, row?.original?.id);

        setState((state) => ({
            ...state,
            InfoModal: true,
            InfoID: row?.original?.id,
            InfoProjectName: row?.original?.projectName,
            InfoRepo: row?.original?.repo,
        }));
    }

    const handleReportDownloadInfo = (Data) => {
            if(!Data){
                callCommonAlert("error",  "Somthing went wrong");
                hideCommonAlert();
            } else {
                DownloadPDF({base64Data : Data, fileName : `${state.InfoRepo}`})
            }
    };

    const infoModalBody = () => {
        const columns = [
            {
                Header: "Scanned On",
                accessor: "lastScannedDate",
                Cell: ({ row, value }) => {
                    return <div >{value ? convertEpochToDate(value) : "-"}</div>;
                },
            },
            {
                Header: "Scan Triggered By",
                accessor: "updateBy",
                Cell: ({ row, value }) => {
                    return <div >{value ? value : "-"}</div>;
                },
            },
            {
                Header: "Scanned Report",
                accessor: "report",
                tdClassName: "text-center",
                thClassName: "text-center",
                Cell: ({ row }) => {
                    return (
                        <div className="InvenReport">
                            <a
                                className="InvenReport"
                                onClick={() => handleReportDownloadInfo(item?.report)}
                            >
                                <FaFileDownload className='faInfoCircleStyle' size={20} title='Download Report' />
                            </a>
                        </div>
                    );
                },
            },
        ]
        function getLimit(limit) {
            setInfopageLimit(limit);
            setInfopageNo(0);
            const payload = getInfoPayload(InfosearchKey);
            fetchInfoData(payload, 0, limit,state.InfoID);
        }
        function getPage(pageNo) {
            setInfopageNo(pageNo);
            const payload = getInfoPayload(InfosearchKey);
            fetchInfoData(payload, pageNo, InfopageLimit,state.InfoID);
        }

        function getSearchKey(key) {
            setInfopageNo(0);
            setInfopageLimit(10);
            setInfosearchKey(key);
            const payload = getInfoPayload(key);
            fetchInfoData(payload, 0, 10,state.InfoID);
        }
        return (
            < div>
                <h4 className="delete-h">Details: {state?.InfoProjectName}</h4>

                <CompleteTableData
                    isLoading={ProjectInfoListLoading}
                    data={ProjectInfoData}
                    columns={columns}
                    onPageLimitChange={getLimit}
                    onPageNoChange={getPage}
                    onSearch={getSearchKey}
                    curentPageNumber={InfopageNo}
                    pageLimit={InfopageLimit}
                    searchKey={InfosearchKey}
                    scrollClass={"ProjectInfoScroll"}
                    totalPageCount={finalPageInfoCount}
                    listCount={ProjectInfoCount}
                    moveSearchToEnd={true}
                />
            </div>

        );
    }
    const openserver = async (status, id) => {
        errorReset()
        dispatch(
            setIsOffCanvasOpen({
                isDrilldownOpen: true,
                isDropDownhide: true,
            })
        );
    }

    const clickbtn = (action, value) => {
        const type = 'Add';
        setCurrentAction(type)
        if (type === 'Add') {
            setState((prevState) => ({
                ...prevState,
                formData: {
                    Application: [],
                    Version: "",
                    Branch: "",
                    Repository: "",
                    Project: "",
                },
                BranchError: "",
                BranchErrorMessage: "",
                RepositoryError: "",
                RepositoryErrorMessage: "",
                ProjectError: "",
                ProjectErrorMessage: "",
            }));
        }
        return openserver(type, 0);
    }
    const onEditData = (action, value) => {
        setCurrentAction(action)
        if (action === 'edit') {
            setState((prevState) => ({
                ...prevState,
                formData: {
                    Application: value?.original?.application.map((item) => ({
                        label: item,
                        value: item,
                    })),
                    Version: value?.original?.version,
                    Branch: value?.original?.branch,
                    Repository: value?.original?.repo,
                    Project: value?.original?.projectName,
                    id: value?.original?.id,
                },
            }));
        }
        return openserver(action, 0);
    }


    const btnData = [
        {
            text: "Add",
            function: clickbtn,
        },
        {
            text: "Scan",
            function: (e) => {
                setState((prevState) => ({
                    ...prevState,
                    scan: true,
                }));
            },
            disabled: selectedIds.length === 0,
        }
    ];

    const saveAPICall = (payload) => {
        dispatch(saveUpdateProjectsAPI({ payload, userName: encoded })).then((data) => {
            if (data.payload.statusCodeValue == 200) {
                callAlert("success", data.payload.body);
                onCancel()
                setpageNo(0)
                setsearchKey("")
                const payload = getPayload("");
                fetchData(payload, 0, pageLimit);
            } else if (data.payload.statusCodeValue == 400) {
                callAlert("error", data.payload.body);
            } else {
                callAlert("error", "Something went wrong please try again.");
            }
        });
    }
    const saveFunction = () => {
        let hasError = false;

        ["Branch", "Repository", "Project"].forEach((field) => {
            if (state?.formData[field] === "") {
                setState((prevState) => ({
                    ...prevState,
                    [`${field}ErrorMessage`]: `${field} should not be empty`,
                    [`${field}Error`]: true,
                }));
                hasError = true;
            }
            else if (!/^[A-Za-z]/.test(state?.formData[field])&&field!=="Branch") {
                setState((prevState) => ({
                    ...prevState,
                    [`${field}ErrorMessage`]: `First letter of the ${field} should be alphabet.`,
                    [`${field}Error`]: true,
                }));
                hasError = true;
            }
            else if (state?.formData[field].length < 2 || state?.formData[field].length > 100) {
                setState((prevState) => ({
                    ...prevState,
                    [`${field}ErrorMessage`]: `${field} should contain minimum 2 and maximum 100 characters`,
                    [`${field}Error`]: true,
                }));
                hasError = true;
            }
            else if (/^[0]+$/.test(state?.formData[field]) || /^[^a-zA-Z0-9]+$/.test(state?.formData[field])) {
                setState((prevState) => ({
                    ...prevState,
                    [`${field}ErrorMessage`]: `Please enter a valid information`,
                    [`${field}Error`]: true,
                }));
                hasError = true;
            }
        });

        if (!hasError) {
            const payload = {
                "project": state?.formData?.Project.trim(),
                "repo": state?.formData?.Repository.trim(),
                "branch": state?.formData?.Branch.trim(),
                "id": currentAction === "edit" ? state?.formData?.id : 0,
                "version": state?.formData?.Version.trim(),
                "application": state?.formData?.Application ? (state?.formData?.Application.map((item) => item.value)) : [],
                "report": "Pending",
                "createBy": encoded,
                "updateBy": encoded
            };
            saveAPICall(payload);
        }
    }

    const addScanCancel = () => {
        setState((state) => ({
            ...state,
            ModelOpen: true,
        }));
    };
    const addModelConfirmClose = () => {
        setState({
            ...state,
            ModelOpen: false,
        });
    };
    const addModelConfirm = () => {
        return (
            <>
                <DiscardChanges title={state.formData.id != 0 ? 'edit' : 'add'} discardClick={onCancel} cancelClick={addModelConfirmClose} />
            </>
        )
    }

    const handleClose = () => {
        setState({
            ...state,
            DeletemodalOpen: false,
        });
        hideCommonAlert();
    };
    const handleDelete = (row) => {
        const id = state?.deleteId;
        dispatch(projectScanDelete({ id })).then((data) => {
            if (data.payload.statusCodeValue == 201) {
                callAlert("success", data.payload.body);
                setState((state) => ({
                    ...state,
                    DeletemodalOpen: false,
                }));
                setpageNo(0)
                setsearchKey("")
                const payload = getPayload("");
                fetchData(payload, 0, pageLimit);
            } else if (data.payload.statusCodeValue == 400) {
                callAlert("error", data.payload.body);
            } else {
                callAlert("error", "Something went wrong please try again.");
            }
        });
        handleClose();
    };
    const DeletemodalBody = () => (
        <>
            <h4 className="delete-h"> Delete Project</h4>
            {projectScanDeleteLoading ? <Spinners /> : (<div>
                <p>
                    Are you sure you would like to delete the project details?
                </p>
                <div className="btnswrap btn-dlt">
                    <ButtonComponent
                        text="Cancel"
                        className={"cancelBtnStyle btn btn-primary"}
                        onClick={handleClose}
                    />
                    <ButtonComponent
                        text="Delete"
                        className={"btnStyle btn btn-primary"}
                        onClick={() => handleDelete(state.handleDelete)}
                    />
                </div>
            </div>)}
        </>
    );
    const ScanClick = () => {

        const payload = {
            // "branchNames": ["main"],
            // "repositories": ["exora_celcom_demo"]
            "branchNames": selectedBranch.map((item) => item.label),
            "repositories": selectedRepo.map((item) => item.label),
        }
        dispatch(projecttriggerScanAPI({ payload })).then((data) => {
            if (data.payload.statusCodeValue == 200) {
                callAlert("success", data.payload.body);
                setState((state) => ({
                    ...state,
                    scan: false,
                }));
                setSelectedIds([])
                setSelectedRepo([])
                setSelectedBranch([])
                setpageNo(0)
                setsearchKey("")
                const payload = getPayload("");
                fetchData(payload, 0, pageLimit);
            } else if (data.payload.statusCodeValue == 400) {
                callAlert("error", data.payload.body);
            } else if (data.payload.statusCodeValue == 500) {
                callAlert("error", data.payload.body);
            } else {
                callAlert("error", "Something went wrong please try again.");
            }
        })
    }
    const scanModalBody = () => {
        return (<ProjectScanModel
            ScanClick={()=>ScanClick()}
            ScanModleCLose={() => {
                setState((prevState) => ({
                    ...prevState,
                    scan: false,
                }));
            }}
        />)
    };
    const errorReset = (name) => {
        if (name) {
            setState((prevState) => ({
                ...prevState,
                [`${name}ErrorMessage`]: "",
                [`${name}Error`]: false,
            }));
        } else {
            setState((state) => ({
                ...state,
                BranchError: "",
                BranchErrorMessage: "",
                RepositoryError: "",
                RepositoryErrorMessage: "",
                ProjectError: "",
                ProjectErrorMessage: "",
            }));
        }
    }
    const valueReset = () => {
        setState((state) => ({
            ...state,
            formData: {
                Application: [],
                Version: "",
                Branch: "",
                Repository: "",
                Project: "",
                id: "",
            },
        }));
    }

    const onCancel = () => {
        errorReset();
        valueReset();
        dispatch(
            setIsOffCanvasOpen({
                isDrilldownOpen: false,
                isDropDownhide: false,
            })
        );
        setState({
            ...state,
            conformationPop: false,
            ModelOpen: false,
        });
    }

    const handleOnChange = (name, selectedValue) => {
        errorReset(name)
        setState((state) => ({
            ...state,
            formData: {
                ...state.formData,
                [name]: selectedValue && selectedValue.length === 0 ? "" : selectedValue,
            },
            conformationPop: selectedValue === "" ? false : true,
        }));
    };

    return (
        <>
            <div className={`grid-table${projectScanListAPILoading ? " InvProjectTableLOader InvProjectTable":"InvProjectTable"}`}>
                <CompleteTableData
                    isLoading={projectScanListAPILoading}
                    data={TableContent}
                    columns={columns}
                    onPageLimitChange={getLimit}
                    onPageNoChange={getPage}
                    onSearch={getSearchKey}
                    curentPageNumber={pageNo}
                    pageLimit={pageLimit}
                    searchKey={searchKey}
                    totalPageCount={finalPageCount}
                    listCount={totalElements}
                    btnData={btnData}
                    moveSearchToEnd={true}
                />

            </div>

            <CustomModal
                modalOpen={state.ModelOpen}
                modalBody={addModelConfirm}
                modalHide={addModelConfirmClose}
                closeBtn={true}
                closeBtnFuc={addModelConfirmClose}
            />
            <CustomModal
                modalOpen={state.InfoModal}
                modalBody={infoModalBody}
                modalTitle={"Details"}
                modalCustomClass={"InvServerModal"}
                closeBtn={true}
                closeBtnFuc={() => {
                    setInfopageNo(0);
                    setInfopageLimit(10);
                    setInfosearchKey("");
                    setState((prevState) => ({
                        ...prevState,
                        InfoModal: false,
                        InfoID:"",
                        InfoProjectName:"",
                    }));
                }}
                size={"lg"}

            />
            <CustomModal
                modalOpen={state.DeletemodalOpen}
                modalBody={DeletemodalBody}
                modalTitle={"Delete Configuration"}
                closeBtn={false}
                closeBtnFuc={handleClose}
            />
            <CustomModal
                modalOpen={state?.scan}
                modalBody={scanModalBody}
                modalTitle={"Scan Servers"}
                closeBtn={false}
                closeBtnFuc={() => {
                    setState((prevState) => ({
                        ...prevState,
                        scan: false,
                    }));
                }}
                size={"xs"}
            />
            <div>
                <CustomOffCanvas className="custom-off-canvas">
                    <h3
                        className="flowhead p-0-5"
                        id="updatehead"
                        style={{ display: "block" }}
                    >
                        {currentAction === "edit" ? "Update Project Details" : "Add Project Details"}{" "}
                    </h3>
                    {saveUpdateProjectsDataLoading ? (
                        <Spinners />
                    ) : (
                        <>
                            <Row className=" m-0">
                                <Col>
                                    <div className="mb-3">
                                        <Input
                                            type="text"
                                            label="Project*"
                                            name="Project"
                                            containerClassName={"frmgroup validate default-input"}
                                            placeholder="Enter Project"
                                            autoComplete={"off"}
                                            onChange={(e) => { handleOnChange("Project", e.target.value) }}
                                            value={state?.formData?.Project}
                                            error={state?.ProjectError}
                                            errorMessage={state?.ProjectErrorMessage}
                                            maxLength={100}
                                        />
                                    </div>
                                </Col>
                                <Col>
                                    <div className="mb-3">
                                        <Input
                                            type="text"
                                            label="Repository*"
                                            name="Repository"
                                            placeholder="Enter Repository"
                                            autoComplete={"off"}
                                            containerClassName={"frmgroup validate default-input"}
                                            onChange={(e) => { handleOnChange("Repository", e.target.value) }}
                                            value={state?.formData?.Repository}
                                            error={state?.RepositoryError}
                                            errorMessage={state?.RepositoryErrorMessage}
                                            maxLength={100}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row className=" m-0">
                                <Col>
                                    <div className="mb-3">
                                        <Input
                                            type="text"
                                            label="Branch*"
                                            name="Branch"
                                            placeholder="Enter Branch"
                                            autoComplete={"off"}
                                            containerClassName={"frmgroup validate default-input"}
                                            onChange={(e) => { handleOnChange("Branch", e.target.value) }}
                                            value={state?.formData?.Branch}
                                            error={state?.BranchError}
                                            errorMessage={state?.BranchErrorMessage}
                                            maxLength={100}
                                        />
                                    </div>
                                </Col>
                                <Col>
                                    <div className="mb-3">
                                        <label>Application Name</label>

                                        <CustomSelect
                                            options={appList}
                                            isMulti={true}
                                            name="Application"
                                            hideSelectedOptions={false}
                                            isCheckboxSelect={true}
                                            placeholder="Application"
                                            searchPlaceHolder="Search"
                                            valueClassName={"selection-ProjectValue"}
                                            isSearchable={false}
                                            closeMenuOnSelect={false}
                                            onChange={(SelectedValue) => { handleOnChange("Application", SelectedValue) }}
                                            value={state?.formData?.Application}
                                        // {...props}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row className=" m-0">
                                <Col>
                                    <div className="mb-3">
                                        <Input
                                            type="text"
                                            label="Version"
                                            name="Version"
                                            containerClassName={"frmgroup validate default-input"}
                                            placeholder="Enter Version"
                                            autoComplete={"off"}
                                            onChange={(e) => { handleOnChange("Version", e.target.value) }}
                                            value={state?.formData?.Version}
                                        />
                                    </div>
                                </Col>
                                <Col>
                                </Col>
                            </Row>
                            <Row className="m-0">
                                <Col></Col>
                                <Col>
                                    <div
                                        className="server-btn"
                                        style={{ display: "block", justifyContent: "end" }}
                                    >
                                        <ButtonComponent
                                            text="Cancel"
                                            className={"cancelBtnStyle btn btn-primary"}
                                            onClick={() => state.conformationPop ? addScanCancel() : onCancel()}
                                        />
                                        <ButtonComponent text="Save" onClick={() => { saveFunction() }}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </>
                    )}
                </CustomOffCanvas>
            </div>
        </>
    )
}

export default ProjectsTable;