import React from "react";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Cookies from "universal-cookie";
import { handleResponseCode } from "../../app/utilities/helpers";
import GetProxy from "../../app/GetProxy";


const proxy = GetProxy()
const cookies = new Cookies();

export const getAuditList = createAsyncThunk(
  "get/getData",
  async (props) => {
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    try {
      const { page, count, payload } = props;
      const response = await fetch(
        `${proxy}/api/v1/auditLog/list/${page}/${count}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": Apitoken,
            USERNAME: User,
          },
          body: JSON.stringify(payload),
        }
      )
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

export const getAuditLogCount = createAsyncThunk(
  "get/getDataCount",
  async (props) => {
    const { payload } = props;
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    try {
      const response = await fetch(
        `${proxy}/api/v1/auditLog/count`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "DTOP-API-TOKEN": Apitoken,
            USERNAME: User,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

const AuditLogSlice = createSlice({
  name: "AuditLogSlice",
  initialState: {
    getAuditListData: [],
    auditCount: [],
    error: "",
    auditLoading: false,
    auditcountLoading: false,
  },
  reducers: {
    AuditLogSliceReset: () => {
      return initialState;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAuditList.pending, (state, action) => {
        state.auditLoading = true;
      })
      .addCase(getAuditList.fulfilled, (state, action) => {
        state.getAuditListData = action.payload;
        state.auditLoading = false;
      })
      .addCase(getAuditList.rejected, (state, action) => {
        state.auditLoading = false;
        state.getAuditListData = [];
        state.error = "Something went wrong";
      })

      .addCase(getAuditLogCount.pending, (state, action) => {
        state.auditcountLoading = true;
      })
      .addCase(getAuditLogCount.fulfilled, (state, action) => {
        state.auditCount = action.payload;
        state.auditcountLoading = false;
      })
      .addCase(getAuditLogCount.rejected, (state, action) => {
        state.auditcountLoading = false;
        state.auditCount = [];
        state.error = "Something went wrong";
      })
  },
});

export const { AuditLogSliceReset } =
  AuditLogSlice.actions;
export default AuditLogSlice.reducer;
